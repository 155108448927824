import { Injectable } from '@angular/core';
import { AlertController, LoadingController } from '@ionic/angular';
import { BehaviorSubject, lastValueFrom, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Logger } from '../../common/provider/logger.service';
import { PreferenceService } from '../../common/provider/preference.service';
import { BookingError } from '../Errors';
import { BookingController } from '../api/controller/booking.controller';
import { SuiteAvailabilityDaysRequest } from '../api/model/suite-availability-days-request';
import { SuiteAvailabilityDaysResponse } from '../api/model/suite-availability-days-response';
import { Upgrade } from '../api/model/upgrade.model';
import { ConfigService } from './config.service';
import { SuiteAvailabilityTimesRequest } from '../api/model/suite-availability-times-request';
import { SuiteAvailabilityRequest } from '../api/model/suite-availability-request';

type FilterObject = {
  blacklist_values?: string[];
  is_required: boolean;
  default_value: string | string[];
  input_type: string;
  name: string;
  items: Array<{ value: string | string[]; label: string; disabled: boolean }>;
  options: Array<{ value: string; title: string }>;
};

type NoticeObject = {
  name: string;
  value: Array<{
    is_active: boolean;
    notice_key: string;
    notice_value: string;
    outlet_id: number;
  }>;
};

type CalculatedPrices = {
  preStornoPrice: number;
  preVoucherPrice: number;
  preLoyaltyPrice: number;
  maxLoyaltyPointsSpendable: number;
  loyaltyDeduction: number;
  totalPrice: number;
};

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  public availabilityFilters: FilterObject[];
  public notices: Array<NoticeObject>;
  public availabilityDays: object;
  public availablePackages: Array<{
    package_id: number;
    price: number;
    name: string;
    inclusions: Array<{ name: string }>;
  }>;
  public availableUpgrades: Array<Upgrade>;
  public availableLocations: Array<{
    outlet_id;
    suite_types;
    outlet_name: string;
  }>;
  public flexStorno: Array<{
    flex_id: number;
    name: string;
    description: string;
    price_in_percent: number;
    image: string;
  }>;
  public bookingData;
  public bookingFinalData;
  private selectedPayment;
  private log: Logger;

  public upgrades: Array<{
    upgradeId: number;
    upgradeSelections: Array<number>;
    selected: boolean;
  }>;

  loyaltyPointValue: number;

  private _dataChanged = new BehaviorSubject<boolean>(false);
  readonly dataChanged = this._dataChanged.asObservable();

  private _flexPrice = new BehaviorSubject<number>(0);
  readonly flexPrice = this._flexPrice.asObservable();

  private _loyaltyPointsRewardedForBooking = new BehaviorSubject<any>({});
  readonly loyaltyPointsRewardedForBooking$ =
    this._loyaltyPointsRewardedForBooking.asObservable();

  private _calculatedPrices = new BehaviorSubject<CalculatedPrices>({
    preStornoPrice: 0,
    preVoucherPrice: 0,
    preLoyaltyPrice: 0,
    maxLoyaltyPointsSpendable: 0,
    loyaltyDeduction: 0,
    totalPrice: 0,
  });
  readonly calculatedPrices$ = this._calculatedPrices.asObservable();

  constructor(
    private preferences: PreferenceService,
    private bookingController: BookingController,
    private loadingController: LoadingController,
    private alertController: AlertController,
    private configService: ConfigService,
    private logger: Logger,
  ) {
    this.log = this.logger.createLogger('BookingService');
  }

  public async init(): Promise<void> {
    this.log.debug('init');

    this.bookingData = (await this.preferences.getBookingData()) as {
      outlet_ids: any;
      paymentMethod: string;
    };

    try {
      const filters =
        (await this.bookingController.getAvailabilityFilters()) as Array<any>;
      this.availabilityFilters = filters.filter(f => f.name != 'notice');
      this.notices = filters.find(f => f.name == 'notice').value;
      this.availableUpgrades = await this.bookingController.getUpgrades(
        this.bookingData?.date,
      );
      this.availablePackages = await this.bookingController.getPackages();
      this.flexStorno = await this.bookingController.getFlex();
      this.configService.mergeConfig({ bookingDisabled: false });
      this.configService.config$.subscribe(config => {
        this.loyaltyPointValue = config.loyalty.pointValue;
      });
    } catch (e) {
      this.log.error(
        'Could not load necessary booking data, disabling booking',
      );
      this.configService.mergeConfig({ bookingDisabled: true });
    }

    this.availableLocations = (await this.preferences.getLocations()) as Array<{
      outlet_id;
      suite_types;
      outlet_name: string;
    }>;

    this._flexPrice.next(this.getStornoPrice());
  }

  public async loadBookingData() {
    this.bookingData = await this.preferences.getBookingData();
  }

  public async getPaymentTypes() {
    return this.bookingController.getPaymentTypes(this.getSalesBookingData());
  }

  async getAvailabilityDays(
    params: SuiteAvailabilityDaysRequest,
  ): Promise<SuiteAvailabilityDaysResponse> {
    return lastValueFrom(
      this.bookingController.getSuiteAvailabilityDays(params),
    );
  }

  async getAvailabilityTimes(params: SuiteAvailabilityTimesRequest) {
    return lastValueFrom(
      this.bookingController.getSuiteAvailabilityTimes(params),
    );
  }

  // public async getAvailability(params) {
  //   return this.bookingController.getAvailabilityDays(params) as Promise<{
  //     availability_days: any;
  //     availability_day_legends: any;
  //   }>;
  // }

  // public async getAvailabilityTimes(params) {
  //   return this.bookingController.getAvailabilityTimes(params);
  // }

  resetBookingData() {
    this.bookingData = [];
  }

  async getLoyaltyPointReward() {
    const data = this.getSalesBookingData();
    this.bookingController.getLoyaltyPointReward(data).subscribe(res => {
      this.preferences.setLoyaltyPointsRewardedForLastBooking(res.totalPoints);
      this._loyaltyPointsRewardedForBooking.next(res);
    });
  }

  getBookingConfirmationData() {
    const confirmationData = {
      ...this.getSalesBookingData(),
      customer_firstname: this.bookingData.user.firstname,
      customer_lastname: this.bookingData.user.lastname,
      customer_city: this.bookingData.user.city,
      customer_postcode: this.bookingData.user.postcode,
      customer_salutation: this.bookingData.user.salutation,
      customer_street: this.bookingData.user.street,
      customer_house_number: this.bookingData.user.house_number,
      customer_date_of_birth: this.bookingData.user.date_of_birth,
      customer_telephone: this.bookingData.user.telephone,
    };

    if (this.wantsToBeLoyaltyMember) {
      Object.assign(confirmationData, {
        is_loyalty_member: +this.wantsToBeLoyaltyMember,
      });
    }

    return confirmationData;
  }

  public async confirmBooking() {
    return this.bookingController.confirmBooking(
      this.getBookingConfirmationData(),
    );
  }

  public async setBookingData(data) {
    this.bookingData = data;
    this.bookingData.upgrades = [];
    this.preferences.setBookingData(data);
    this.availableUpgrades = await this.bookingController.getUpgrades(
      this.bookingData.date,
    );
    await this.updateLocations();
    this._dataChanged.next(true);
    this.log.debug('booking data set', this.bookingData);
  }

  public checkPayment(payment_id) {
    return this.bookingController.checkPayment(payment_id);
  }

  public async updateLocations() {
    try {
      const {
        day_time,
        duration,
        capacity,
        date,
        timeslots_from_time,
        timeslots_to_time,
        outlet_ids,
      } = this.bookingData;

      const result = await this.bookingController.getSuiteAvailability({
        day_time,
        duration,
        capacity,
        date,
        from_time: timeslots_from_time,
        to_time: timeslots_to_time,
        outlet_ids,
      });

      if (result.availability_date != this.bookingData.date) {
        this.bookingData.date = result.availability_date;
        this.preferences.setBookingData(this.bookingData);
      }

      this.availableLocations = result.availabilities;
    } catch (e) {
      this.availableLocations = [];
    } finally {
      this.preferences.setLocations(this.availableLocations);
    }
  }

  public async addBookingData(data) {
    if (this.bookingData.increment_id) {
      Object.keys(data).some(key => {
        if ('paymentMethod' === key) {
          return false;
        }
        if (
          this.bookingData[key] &&
          JSON.stringify(this.bookingData[key]) !== JSON.stringify(data[key])
        ) {
          this.removeIncrementId();
          return true;
        }
      });
    }

    const checkKeys = ['suiteId', 'outletId'];
    Object.keys(data).some(key => {
      if (!checkKeys.find(el => el == key)) {
        return false;
      }
      if (this.bookingData[key] === data[key]) {
        return false;
      }
      this._dataChanged.next(true);
      return true;
    });

    this.bookingData = Object.assign(this.bookingData, data);
    this.preferences.setBookingData(this.bookingData);

    this.log.debug('booking data updated', this.bookingData);

    this._flexPrice.next(this.getStornoPrice());
  }

  public setUpgrades(
    upgrades: Array<{
      upgradeId: number;
      upgradeSelection: Array<{ entityId: number; quantity: number }>;
    }>,
  ) {
    this.bookingData.upgrades = upgrades;
    this.removeIncrementId();
    this.preferences.setBookingData(this.bookingData);
    this.log.debug('booking data updated', this.bookingData);
    this._flexPrice.next(this.getStornoPrice());
  }

  public addUpgrade(
    upgradeId: number,
    upgradeSelection: Array<{ value: number; label: string; quantity: number }>,
  ) {
    if (!this.bookingData.upgrades) {
      this.bookingData.upgrades = [];
    }
    const newUpgrades = this.bookingData.upgrades.filter(
      upgrade => upgrade.upgradeId !== upgradeId,
    );
    newUpgrades.push({ upgradeId, selections: upgradeSelection });
    this.bookingData.upgrades = newUpgrades;
    this.removeIncrementId();
    this.preferences.setBookingData(this.bookingData);
    this.log.debug('booking data updated', this.bookingData);
    this._flexPrice.next(this.getStornoPrice());
  }

  public removeUpgrade(upgradeId: number) {
    const newUpgrades = this.bookingData.upgrades.filter(
      upgrade => upgrade.upgradeId !== upgradeId,
    );
    this.bookingData.upgrades = newUpgrades;
    this.removeIncrementId();
    this.preferences.setBookingData(this.bookingData);
    this.log.debug('booking data updated', this.bookingData);
    this._flexPrice.next(this.getStornoPrice());
  }

  public removeAllUpgrades() {
    this.bookingData.upgrades = [];
    this.removeIncrementId();
    this.preferences.setBookingData(this.bookingData);
    this.log.debug('booking data updated', this.bookingData);
  }

  public changeUpgrade(
    upgradeId: number,
    upgradeSelection: Array<{ entityId: number; quantity: number }>,
  ) {
    const newUpgrades = this.bookingData.upgrades.map(upgrade => {
      if (upgrade.upgradeId === upgradeId) {
        return { upgradeId, selections: upgradeSelection };
      }
      return upgrade;
    });
    this.bookingData.upgrades = newUpgrades;
    this.preferences.setBookingData(this.bookingData);
    this.log.debug('booking data updated', this.bookingData);
  }

  public removeIncrementId() {
    if (this.bookingData.increment_id) {
      delete this.bookingData.increment_id;
    }
  }

  public setSelectedPayment(paymentType) {
    this.selectedPayment = paymentType;
  }

  public getSuiteAvailability() {
    return (
      (
        this.availableLocations?.find(
          ({ outlet_id }) => outlet_id === this.bookingData.outletId,
        ) || {}
      )?.suite_types || []
    );
  }

  async getAvailableOutletsBySuiteType(
    suiteTypeId: number,
    params: SuiteAvailabilityRequest,
  ) {
    const res = await lastValueFrom(
      this.bookingController.getOutletAvailability(params),
    );
    return res.availabilities.find(av => av.suite_type_id === suiteTypeId)
      .outlets;
  }

  public getOutletAvailability() {
    return this.availableLocations || [];
  }

  public async validateVouchers(
    voucherCodes: string[],
    respectConfiguration: boolean = false,
  ): Promise<any[]> {
    const config = respectConfiguration
      ? { respect_configuration: 1, ...this.getBookingConfirmationData() }
      : {};
    const res = await this.bookingController.validateVouchers(
      voucherCodes,
      config,
    );

    const hasFraudulentVoucher = res.vouchers.find(v => v.status === 'fraud');

    if (hasFraudulentVoucher) {
      const alert = await this.alertController.create({
        cssClass: 'myw-alert',
        header: 'Achtung',
        message:
          'Aufgrund betrügerischer Handlung deaktivierter Gutscheincode. Bitte wende Dich an den Wiederverkäufer oder Verschenker des Gutscheins.',
        buttons: ['OK'],
      });

      await alert.present();
    }

    return res.vouchers;
  }

  public async revalidateVouchers() {
    if (!this.bookingData.vouchers) {
      this.bookingData.vouchers = [];
    }
    const voucherCodes = this.bookingData.vouchers.map(
      voucher => voucher.voucher_code,
    );
    if (voucherCodes.length > 0) {
      const validatedVouchers = await this.validateVouchers(voucherCodes, true);
      this.bookingData.vouchers = validatedVouchers.filter(
        voucher => voucher.status == 'valid',
      );
    }
    this.preferences.setBookingData(this.bookingData);
  }

  public async addVoucher(newVoucherCode: string): Promise<boolean> {
    if (!this.bookingData.vouchers) {
      this.bookingData.vouchers = [];
    }

    const validatedVoucherCodes = this.bookingData.vouchers.map(
      voucher => voucher.voucher_code,
    );

    if (validatedVoucherCodes.includes(newVoucherCode)) {
      return false;
    }

    const voucherCodes = [...validatedVoucherCodes, newVoucherCode];

    if (voucherCodes.length === 0) {
      this.bookingData.vouchers = [];
      this.preferences.setBookingData(this.bookingData);
      return true;
    }

    const validatedVouchers = await this.validateVouchers(voucherCodes, true);

    const newVoucherValid =
      validatedVouchers.find(v => {
        return v.voucher_code === newVoucherCode;
      }).status === 'valid';

    const newValidatedVouchers = validatedVouchers.filter(
      voucher => voucher.status === 'valid',
    );

    this.bookingData.vouchers = newValidatedVouchers;
    this.preferences.setBookingData(this.bookingData);

    return newVoucherValid;
  }

  public async addVouchers(_newVoucherCodes: string[]): Promise<boolean> {
    if (!this.bookingData.vouchers) {
      this.bookingData.vouchers = [];
    }

    const validatedVoucherCodes = this.bookingData.vouchers.map(
      voucher => voucher.voucher_code,
    );

    const newVoucherCodes = _newVoucherCodes.filter(
      code => !validatedVoucherCodes.includes(code),
    );

    const voucherCodes = [...validatedVoucherCodes, ...newVoucherCodes];

    if (voucherCodes.length === 0) {
      this.bookingData.vouchers = [];
      this.preferences.setBookingData(this.bookingData);
      return true;
    }

    const validatedVouchers = await this.validateVouchers(voucherCodes, true);

    const newValidatedVouchers = validatedVouchers.filter(
      voucher => voucher.status === 'valid',
    );

    this.bookingData.vouchers = newValidatedVouchers;
    this.preferences.setBookingData(this.bookingData);
    return true;
  }

  public async removeVoucher(index) {
    this.bookingData.vouchers = this.bookingData.vouchers.filter(
      (_, i) => i != index,
    );
    this.preferences.setBookingData(this.bookingData);
    this.revalidateVouchers();
  }

  public async removeVouchers(voucherCodes: string[]) {
    if (voucherCodes.length === 0) {
      return;
    }
    this.bookingData.vouchers = this.bookingData.vouchers.filter(
      voucher => !voucherCodes.includes(voucher.voucher_code),
    );
    this.revalidateVouchers();
  }

  setLoyaltyPointsToRedeem(points: number) {
    this.bookingData.loyaltyPointsToRedeem = points;
    this.preferences.setBookingData(this.bookingData);
  }

  public async loadSelectedPayment() {
    if (!this.selectedPayment) {
      const paymentTypes = await this.getPaymentTypes();

      // select current Payment if available, else get the default
      this.selectedPayment =
        paymentTypes.find(
          ({ type }) =>
            this.bookingData.paymentMethod &&
            this.bookingData.paymentMethod === type,
        ) ||
        paymentTypes.find(({ is_default }) => is_default) ||
        paymentTypes[0];
    }
  }

  public getSelectedPayment() {
    return this.selectedPayment || { name: 'none' };
  }

  public async initiatePayment() {
    const paymentData = await this.bookingController.initiatePayment({
      ...this.getBookingConfirmationData(),
      // in live und dev unterschiedlich --.--
      selected_payment_method: this.getSelectedPayment().type,
      payment_method: this.getSelectedPayment().type,
    });

    // save increment_id in case the payment is cancelled and needs to be started again
    await this.addBookingData({ increment_id: paymentData.increment_id });

    return paymentData;
  }

  getSuiteByOutletAndType(outletId: number, suiteTypeId: number) {
    return this.availableLocations
      .find(({ outlet_id }) => outlet_id === outletId)
      ?.suite_types?.find(({ suite_type_id }) => suite_type_id === suiteTypeId);
  }

  public getBookingDetails() {
    try {
      const location = this.getOutletAvailability().find(
        ({ outlet_id }) => outlet_id === this.bookingData.outletId,
      );
      const suite = this.getSuiteAvailability().find(
        ({ suite_type_id }) => suite_type_id === this.bookingData.suiteId,
      );

      const bookingDay = this.bookingData.date.split('-');
      const bookingDate = new Date(
        bookingDay[0],
        bookingDay[1] - 1,
        bookingDay[2],
      );

      const capacity = this.availabilityFilters
        .find(({ name }) => name === 'capacity')
        .options.find(({ value }) => value == this.bookingData.capacity).title;

      const selectedPackage = this.availablePackages.find(
        ({ package_id }) => package_id == this.bookingData.packageId,
      );
      const selectedUpgrade = this.availableUpgrades.find(
        ({ upgradeId }) => upgradeId === this.bookingData.upgradeId,
      );

      return {
        suite: {
          id: suite.suite_type_id,
          title: `${suite.suite_type} Suite`,
          price: suite.minimum_price,
          duration: `${+this.bookingData.duration.split(':')[0]} ${
            +this.bookingData.duration.split(':')[0] > 1 ? 'Stunden' : 'Stunde'
          }`,
          capacity,
          date: bookingDate,
          time: `${this.bookingData.from_time} - ${this.bookingData.to_time} Uhr`,
          location: location.outlet_name,
          image: suite.outlet_specific_image,
        },
        package: {
          isEmpty: !selectedPackage.package_id,
          title: `${selectedPackage.name} Paket`,
          price: +selectedPackage.price || 0,
          features: selectedPackage.inclusions.map(({ name }) => name),
        },
        usePackage: this.bookingData.usePackage,
        upgrades: this.bookingData.upgrades || [],
        storno: {
          title: this.flexStorno[0].name,
          description: this.flexStorno[0].description,
        },
        useStorno: this.bookingData.useStorno || null,
        user: {
          name: `${this.bookingData.user.firstname} ${this.bookingData.user.lastname}`,
          email: this.bookingData.user.email,
          city: this.bookingData.user.city,
          telephone: this.bookingData.user.telephone,
          date_of_birth: this.bookingData.user.date_of_birth,
        },
        companions: [
          this.bookingData.companion_1,
          this.bookingData.companion_2,
          this.bookingData.companion_3,
        ]
          .filter(el => el !== undefined)
          .map(el => {
            return {
              salutation: el.salutation,
              firstname: el.firstname,
              lastname: el.lastname,
              date_of_birth: el.date_of_birth,
              email: el.email,
              surprise: el.surprise,
            };
          }),
        vouchers: this.bookingData.vouchers || [],
        paymentMethod: this.bookingData.paymentMethod || null,
        loyaltyPointsToRedeem: this.bookingData.loyaltyPointsToRedeem || 0,
        finalPrice: this.bookingData?.finalPrice || 0,
      };
    } catch (e) {
      throw new BookingError('Invalid bookingData');
    }
  }

  public getFinalBookingDetails() {
    const data = this.getBookingDetails();
    if (!this.bookingData.usePackage) {
      data.package = {
        isEmpty: true,
        title: 'Spa-Paket',
        price: null,
        features: [],
      };
    }
    return data;
  }

  getAfterPaymentDetails() {
    return {
      suiteTypeId: this.bookingData.suiteId,
      date: this.bookingData.date,
      outletId: this.bookingData.outletId,
    };
  }

  public getUser() {
    return this.bookingData.user;
  }

  public getCompanionById(id: number) {
    return this.bookingData[`companion_${id}`];
  }

  public getSalesBookingData() {
    return {
      ...this.getCompanionData(),
      duration: this.bookingData.duration,
      total_guests: +this.bookingData.capacity,
      suite_type_id: this.bookingData.suiteId,
      date: this.bookingData.date,
      from_time: this.bookingData.from_time,
      to_time: this.bookingData.to_time,
      outlet_id: this.bookingData.outletId,
      package_id: this.bookingData.usePackage
        ? this.bookingData.packageId
        : undefined,
      upgrades: this.bookingData.upgrades?.map(upgrade => ({
        upgrade_id: upgrade.upgradeId,
        options: upgrade.selections.map(selection => ({
          entity_id: selection.entityId,
          qty: selection.quantity || 1,
        })),
      })),
      flex_id: this.bookingData.useStorno
        ? this.bookingData.flex_id
        : undefined,
      entity_type: 'sales_booking',
      customer_email: this.bookingData.user.email,
      voucher_codes: (this.bookingData.vouchers || []).map(
        ({ voucher_code }) => voucher_code,
      ),
      respect_configuration: true,
      booked_from: 1,
      increment_id: this.bookingData.increment_id,
      return_url: this.bookingData.return_url,
      reservation_id: !this.bookingData.increment_id
        ? this.bookingData.reservation_id
        : undefined,
      loyalty_points: this.bookingData.loyaltyPointsToRedeem || 0,
    };
  }

  public getCompanionData() {
    let friends = {};

    for (let index = 1; index <= 3; index++) {
      if (this.bookingData[`companion_${index}`]) {
        Object.assign(friends, {
          [`friend_${index}_salutation`]:
            this.bookingData[`companion_${index}`].salutation,
          [`friend_${index}_firstname`]:
            this.bookingData[`companion_${index}`].firstname,
          [`friend_${index}_lastname`]:
            this.bookingData[`companion_${index}`].lastname,
          [`friend_${index}_email`]:
            this.bookingData[`companion_${index}`].email,
          [`friend_${index}_date_of_birth`]:
            this.bookingData[`companion_${index}`].date_of_birth,
          [`friend_${index}_do_not_notify`]:
            this.bookingData[`companion_${index}`].surprise,
        });
      }
    }

    return friends;
  }

  public async getPaypalBooking() {
    return this.bookingController.getPaypalPayment(this.getSalesBookingData());
  }

  public getOtherEmails(id: number): Array<string> {
    return [
      this.bookingData.user.email.toLowerCase(),
      this.bookingData.companion_1?.email.toLowerCase(),
      this.bookingData.companion_2?.email.toLowerCase(),
      this.bookingData.companion_3?.email.toLowerCase(),
    ].filter((el, i) => i != id);
  }

  async presentAdvertiserFeedback(): Promise<void> {
    const hasBeenShown =
      await this.preferences.getAdvertiserFeedbackHasBeenShown();

    if (hasBeenShown) {
      return;
    }

    this.preferences.setAdvertiserFeedbackHasBeenShown(true);

    const options = [
      'Radio',
      'Social Media',
      'Google',
      'Freunde/Bekannte',
      'Newsletter',
      'Filiale',
      'Website',
    ].sort(() => Math.random() - 0.5);

    const alert = await this.alertController.create({
      cssClass: ['myw-referrer-dialog', 'myw-alert-with-close'],
      header: 'Wie bist Du auf uns aufmerksam geworden?',
      inputs: options.map(o => ({ label: o, type: 'radio', value: o })),
      buttons: [
        {
          text: 'Absenden',
          role: 'confirm',
          handler: answer => {
            if (answer === undefined) {
              return false;
            }
            this.bookingController
              .postAdvertisingFeedback(answer, this.bookingData.increment_id)
              .pipe(catchError(_ => of()))
              .subscribe();
          },
        },
        {
          text: undefined,
          role: 'cancel',
          cssClass: 'close-button',
        },
      ],
    });

    await alert.present();

    const { role, data } = await alert.onWillDismiss();
    if (role !== 'confirm') {
      return;
    }
    const alert2 = await this.alertController.create({
      cssClass: ['myw-referrer-gratification', 'myw-alert-with-close'],
      header: 'Vielen dank für deine teilnahme!',
      subHeader: ' ',
      buttons: [
        'Schließen',
        {
          text: undefined,
          role: 'cancel',
          cssClass: 'close-button',
        },
      ],
    });

    await alert2.present();
    await alert2.onDidDismiss();
    return;
  }

  // Section: Price Calculation

  calculatePrices() {
    const {
      suite,
      package: pkg,
      upgrades,
      vouchers,
    } = this.getBookingDetails();

    const preStornoPrice =
      suite.price + (pkg ? pkg.price : 0) + this.getUpgradesPrice();

    const preVoucherPrice =
      preStornoPrice +
      (this.bookingData.useStorno
        ? preStornoPrice * (+this.flexStorno[0].price_in_percent / 100)
        : 0);

    const preLoyaltyPrice = Number(
      (
        preVoucherPrice -
        vouchers.reduce(
          (total, { deduction_amount }) =>
            total + parseFloat(deduction_amount || 0),
          0,
        )
      ).toFixed(2),
    );

    const maxLoyaltyPointsSpendable = Math.floor(
      preLoyaltyPrice / this.loyaltyPointValue,
    );

    this.bookingData.loyaltyPointsToRedeem = Math.min(
      this.bookingData.loyaltyPointsToRedeem || 0,
      maxLoyaltyPointsSpendable,
    );

    const loyaltyDeduction =
      (this.bookingData.loyaltyPointsToRedeem || 0) * this.loyaltyPointValue;

    const totalPrice = preLoyaltyPrice - loyaltyDeduction;

    this._calculatedPrices.next({
      preStornoPrice,
      preVoucherPrice,
      preLoyaltyPrice,
      maxLoyaltyPointsSpendable,
      loyaltyDeduction,
      totalPrice,
    });
  }

  public getStornoPrice(usePackage = true) {
    const selectedSuite = this.getSuiteAvailability().find(
      ({ suite_type_id }) => suite_type_id === this.bookingData.suiteId,
    );
    if (!selectedSuite) {
      return 0;
    }
    const selectedPackage = this.availablePackages.find(
      ({ package_id }) => package_id == this.bookingData.packageId,
    );

    const suitePrice = selectedSuite.minimum_price;
    const packagePrice = usePackage ? +selectedPackage?.price : 0;
    const upgradesPrice = this.getUpgradesPrice() || 0;

    const price = suitePrice + packagePrice + upgradesPrice;
    return (
      price *
      ((this.flexStorno[0]?.price_in_percent
        ? +this.flexStorno[0]?.price_in_percent
        : 0) /
        100)
    );
  }

  public getUpgradesPrice(): number {
    if (!this.availableUpgrades) {
      return 0;
    }

    const upgradesPrice = this.bookingData.upgrades?.reduce(
      (previousValue, currentValue) => {
        const upgradePrice = +this.availableUpgrades.find(
          upgrade => upgrade?.upgradeId === currentValue?.upgradeId,
        )?.price;
        return previousValue + upgradePrice;
      },
      0,
    );
    return upgradesPrice || 0;
  }

  preCheckin(bookingId: number) {
    return lastValueFrom(
      this.bookingController.flagBookingAsBelated(bookingId),
    );
  }

  set wantsToBeLoyaltyMember(value: boolean) {
    this.bookingData.is_loyalty_member = value;
  }

  get wantsToBeLoyaltyMember(): boolean {
    return this.bookingData.is_loyalty_member;
  }

  getSuiteImage(suiteTypeId: number) {
    const { outlet_specific_image: image } = this.getSuiteAvailability().find(
      ({ suite_type_id }) => suite_type_id === suiteTypeId,
    );

    return image;
  }

  public destroy() {
    this.log.debug('destroy');
    this.bookingData = null;
    this.bookingFinalData = null;
    this.selectedPayment = null;
  }
}
