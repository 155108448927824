import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoyaltyEvent } from 'src/app/core/api/model/loyaltyEvent.model';

@Component({
  selector: 'app-loyalty-deal-details',
  templateUrl: './loyalty-deal-details.component.html',
  styleUrls: ['./loyalty-deal-details.component.scss'],
})
export class LoyaltyDealDetailsComponent {
  @Input() event: LoyaltyEvent;

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }
}
