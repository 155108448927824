// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  padding: 45px 30px 30px;
}
ion-card .modal-header {
  margin-top: 30px;
  margin-bottom: 24px;
  font-size: 22px;
  letter-spacing: 0.2em;
  line-height: 30px;
}
ion-card .modal-text {
  font-size: 17px;
  line-height: 29px;
}
ion-card .modal-text > b {
  font-family: "Cera Pro Medium";
}
ion-card ion-item {
  margin-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/cancellation-confirmation-modal/cancellation-confirmation-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;AACJ;AACI;EACI,gBAAA;EACA,mBAAA;EACA,eAAA;EACA,qBAAA;EACA,iBAAA;AACR;AAEI;EACI,eAAA;EACA,iBAAA;AAAR;AAEQ;EACI,8BAAA;AAAZ;AAII;EACI,mBAAA;AAFR","sourcesContent":["ion-card {\n    padding: 45px 30px 30px;\n\n    .modal-header {\n        margin-top: 30px;\n        margin-bottom: 24px;\n        font-size: 22px;\n        letter-spacing: 0.2em;\n        line-height: 30px;\n    }\n\n    .modal-text {\n        font-size: 17px;\n        line-height: 29px;\n\n        >b {\n            font-family: \"Cera Pro Medium\";\n        }\n    }\n\n    ion-item {\n        margin-bottom: 15px;\n    }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
