import { Component, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { Router } from '@angular/router';

@Component({
  selector: 'app-login-popover',
  templateUrl: './login-popover.component.html',
  styleUrls: ['./login-popover.component.scss'],
})
export class LoginPopoverComponent {
  private router = inject(Router);
  private modalCtrl = inject(ModalController);

  constructor() {}

  async dismiss() {
    await this.modalCtrl.dismiss();
  }

  async onLoginSuccess() {
    await this.dismiss();
    await this.router.navigate(['/', 'user', 'profile']);
  }

  async navigateRegister() {
    await this.dismiss();
    await this.router.navigate(['/', 'user', 'register']);
  }
}
