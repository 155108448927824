import { Injectable, inject } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { environment } from 'src/environments/environment';
import { parse } from 'tldts';
import { Md5 } from 'ts-md5';
import { Buffer } from 'buffer';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private cookieService = inject(CookieService);
  private domain: string;

  constructor() {
    this.init();
  }

  async init() {
    const parsedUrl = parse(window.location.href);
    this.domain = parsedUrl.domain;
  }

  setToken(token: string): void {
    const date = new Date();
    const md5Date = Md5.hashStr(date.toISOString());
    const encryptedToken = Buffer.from(
      md5Date.substring(0, 20) + token + md5Date.substring(0, 15),
    ).toString('base64');
    this.cookieService.set('universal_login', encryptedToken, {
      expires: 7,
      domain: this.domain,
      secure: environment.universalLoginCookieSecure,
    });
  }

  getToken(): string {
    const encryptedToken = this.cookieService.get('universal_login');
    const extendedToken = Buffer.from(encryptedToken, 'base64').toString();
    const token = extendedToken.substring(20, extendedToken.length - 15);
    return token;
  }

  deleteToken(): void {
    this.cookieService.delete('universal_login', '/', this.domain);
  }
}
