import { Component, inject } from '@angular/core';
import { AlertController, ModalController } from '@ionic/angular';
import { CustomerService } from 'src/app/core/shared/customer.service';

@Component({
  templateUrl: './loyalty-request-resend.component.html',
  styleUrls: ['./loyalty-request-resend.component.scss'],
})
export class LoyaltyRequestResendComponent {
  private modalCtrl = inject(ModalController);
  private alertCtrl = inject(AlertController);
  private customerService = inject(CustomerService);

  sendMail() {
    this.customerService.resendLoyaltyMail().subscribe({
      next: async () => {
        this.modalCtrl.dismiss(null, 'success');
        const alert = await this.alertCtrl.create({
          cssClass: 'myw-alert',
          header: 'Hinweis',
          message:
            'Wir haben Dir so eben einen neuen Link an Deine E-Mail-Adresse gesendet.',
          buttons: ['Alles klar!'],
        });
        alert.present();
      },
      error: async () => {
        const alert = await this.alertCtrl.create({
          cssClass: 'myw-alert',
          header: 'Fehler',
          message:
            'Leider ist ein technischer Fehler aufgetreten. Versuch es bitte erneut oder melde Dich bei unserem Kundenservice.',
          buttons: [
            { text: 'Ok', role: 'destructive' },
            {
              text: 'Erneut versuchen',
              role: 'resend',
              handler: () => {
                this.sendMail();
                alert.dismiss();
              },
            },
          ],
        });
        alert.present();
      },
    });
  }

  dismiss() {
    this.modalCtrl.dismiss(null, 'cancel');
  }
}
