import { Injectable } from '@angular/core';
import { Logger } from 'src/app/common/provider/logger.service';
import { FeedbackController } from '../api/controller/feedback.controller';
import { Question } from '../api/model/question.model';
import {
  FeedbackAnswersResponse,
  GoogleReviewRequest,
} from '../api/model/feedback.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackService {
  private log: Logger;
  private token: string;
  public questions: Array<Question>;
  public answers: any;

  constructor(
    private logger: Logger,
    private feedbackController: FeedbackController,
  ) {
    this.log = this.logger.createLogger('FeedbackService');
  }

  public async getFeedbackQuestions(token: string): Promise<void | string> {
    this.token = token;
    try {
      this.questions = await this.feedbackController.getFeedbackQuestions(
        token,
      );
    } catch (error) {
      return error.error.messageCode;
    }
  }

  async submitFeedback(data): Promise<FeedbackAnswersResponse> {
    const flattendData = this.flattenObject(data);
    return this.feedbackController.submitFeedback(this.token, flattendData);
  }

  public sendFeedback(feedbackBody): Promise<any> {
    return this.feedbackController.sendFeedback(feedbackBody);
  }

  public flattenObject(obj) {
    const flattened = {};
    Object.keys(obj).forEach(key => {
      const value = obj[key];
      const t = typeof value;
      if (
        typeof value === 'object' &&
        value !== null &&
        !Array.isArray(value)
      ) {
        Object.assign(flattened, this.flattenObject(value));
      } else {
        flattened[key] = value;
      }
    });

    return flattened;
  }

  async submitGoogleReviewID(data: GoogleReviewRequest): Promise<any> {
    return this.feedbackController.googleReview(data);
  }
}
