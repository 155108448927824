import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
  inject,
} from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';

import { Config } from 'src/app/config/config';
import { Customer } from 'src/app/core/api/model/customer.model';
import { FormValidators } from '../../../common/form-validators';
import { DatePipe } from '@angular/common';
import { LINKS } from 'src/app/core/constants/links';

@Component({
  selector: 'app-register-form',
  templateUrl: './form.component.html',
  styleUrls: ['./form.component.scss'],
})
export class RegisterFormComponent implements OnInit, OnChanges {
  /**
   * Used for data-binding in the template.
   */
  public termsAndConditionsControl: AbstractControl;
  public salutationOptions = [
    { value: 'Herr', label: 'Herr' },
    { value: 'Frau', label: 'Frau' },
    { value: 'Divers', label: 'Divers' },
  ];
  @Output() dataChanged = new EventEmitter<Partial<Customer & NewPassword>>();
  @Input() emailErrors: Record<string, boolean>;
  @ViewChild('optionalValues', { static: true })
  optionalValues!: ElementRef<HTMLDivElement>;

  minDate = new Date(1900, 0, 1);
  maxDate = new Date();

  private fb = inject(FormBuilder);
  private date = inject(DatePipe);

  LINKS = LINKS;

  readonly form = this.fb.group({
    salutation: ['', Validators.required],
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    email: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(Config.REGEX_EMAIL),
      ]),
    ],
    date_of_birth: [
      '',
      [Validators.required, Validators.pattern(/^\d{2}\.\d{2}\.\d{4}$/)],
    ],
    telephone: [{ value: '', disabled: true }],
    street: [{ value: '', disabled: true }],
    house_number: [{ value: '', disabled: true }],
    city: [{ value: '', disabled: true }],
    postcode: [{ value: '', disabled: true }],
    password: ['', Validators.required],
    password_repeat: ['', Validators.required],
    is_subscribed: [false],
    is_loyalty_member: [false],
    terms_and_conditions: [false, Validators.requiredTrue],
  });

  constructor(private analytics: AngularFireAnalytics) {
    const password1Control: AbstractControl = this.form.get('password');
    const password2Control: AbstractControl = this.form.get('password_repeat');
    password1Control.setValidators(
      Validators.compose([
        Validators.required,
        Validators.minLength(6),
        FormValidators.getEqualToValidatorFn(password2Control),
      ]),
    );
    password2Control.setValidators(
      Validators.compose([
        Validators.required,
        Validators.minLength(6),
        FormValidators.getEqualToValidatorFn(password1Control),
      ]),
    );
  }

  ngOnInit(): void {
    this.maxDate.setFullYear(this.maxDate.getFullYear() - 18);
    this.analytics.logEvent('user_registration_open');
    this.form
      .get('is_loyalty_member')
      .valueChanges.subscribe((value: boolean) => {
        this.updateFriendValidation(value);

        if (!value) return;

        setTimeout(() => {
          this.optionalValues.nativeElement.scrollIntoView({
            behavior: 'smooth',
            block: 'start',
            inline: 'center',
          });
        }, 100);
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.get('email').setErrors(this.emailErrors);
  }

  public updateFriendValidation(value: boolean) {
    const controlNames = [
      'street',
      'house_number',
      'city',
      'postcode',
      'telephone',
    ];

    if (value) {
      controlNames.forEach(controlName => {
        this.addValidators(controlName, [Validators.required]);
      });
    } else {
      controlNames.forEach(controlName => {
        this.removeValidators(controlName);
      });
    }
  }

  addValidators(formControlName: string, validators: any[]) {
    const control = this.form.get(formControlName);
    control.setValidators(validators);
    control.updateValueAndValidity();
    control.enable();
  }

  removeValidators(formControlName: string) {
    const control = this.form.get(formControlName);
    control.clearValidators();
    control.updateValueAndValidity();
    control.disable();
  }

  onSubmit() {
    if (this.form.invalid) return;

    const formatedDate = this.form.value.date_of_birth
      .split('.')
      .reverse()
      .join('-');

    const userData: Partial<Customer & NewPassword> = {
      salutation: this.form.value.salutation,
      firstname: this.form.value.firstname,
      lastname: this.form.value.lastname,
      date_of_birth: formatedDate,
      email: this.form.value.email,
      telephone: this.form.value.telephone,
      street: this.form.value.street,
      house_number: this.form.value.house_number,
      postcode: this.form.value.postcode,
      city: this.form.value.city,
      password: this.form.value.password,
      is_subscribed: this.form.value.is_subscribed ? 1 : null,
      is_loyalty_member: this.form.value.is_loyalty_member ? 1 : null,
    };

    Object.keys(userData).forEach(key => {
      if (
        userData[key] === '' ||
        userData[key] === null ||
        userData[key] === undefined
      ) {
        delete userData[key];
      }
    });

    this.dataChanged.emit(userData);
  }
}

export type NewPassword = { password: string; password_repeat: string };
