// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.explainer {
  margin-block-end: 28px;
}

.point-list {
  display: flex;
  gap: 12px;
}
.point-list__header {
  margin-block-end: 12px;
  font-family: "Cera Pro Medium";
  letter-spacing: 0.02em;
}
.point-list__item {
  display: grid;
  place-items: center;
  border-radius: 50%;
  height: 32px;
  width: 32px;
  background-color: var(--myw-color-boho-2);
}
.point-list__item ion-icon {
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/loyalty/bonus-hours/bonus-hours.component.scss"],"names":[],"mappings":"AAAA;EACE,sBAAA;AACF;;AAEA;EACE,aAAA;EACA,SAAA;AACF;AACE;EACE,sBAAA;EAEA,8BAAA;EACA,sBAAA;AAAJ;AAGE;EACE,aAAA;EACA,mBAAA;EAEA,kBAAA;EACA,YAAA;EACA,WAAA;EAEA,yCAAA;AAHJ;AAKI;EACE,eAAA;AAHN","sourcesContent":[".explainer {\n  margin-block-end: 28px;\n}\n\n.point-list {\n  display: flex;\n  gap: 12px;\n\n  &__header {\n    margin-block-end: 12px;\n\n    font-family: 'Cera Pro Medium';\n    letter-spacing: 0.02em;\n  }\n\n  &__item {\n    display: grid;\n    place-items: center;\n\n    border-radius: 50%;\n    height: 32px;\n    width: 32px;\n\n    background-color: var(--myw-color-boho-2);\n\n    ion-icon {\n      font-size: 20px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
