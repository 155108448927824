import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: StarRatingComponent
    }
  ]
})
export class StarRatingComponent implements ControlValueAccessor {
  public rating: number;
  public ratingCount: number = 5;
  public ratingArray: Array<boolean>;

  onChange = (rating: number) => {};

  onTouched = () => {};

  touched = false;

  disabled = false;

  constructor() {
    this.ratingArray = new Array<boolean>(this.ratingCount).fill(false);
  }

  changeRating(i: number) {
    this.markAsTouched();
    this.rating = i + 1;
    this.onChange(this.rating);
  }

  writeValue(rating: number): void {
    this.rating = rating;
  }
  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }
  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }
  setDisabledState?(disabled: boolean): void {
    this.disabled = disabled;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }
}
