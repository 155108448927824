// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-icon {
  font-size: 32px;
  color: var(--myw-color-danger);
}

h2 {
  font-family: "Cera Pro";
  font-weight: 700;
  font-size: 22px;
  color: var(--myw-color-danger);
}

p {
  font-family: "Cera Pro";
}`, "",{"version":3,"sources":["webpack://./src/app/components/cancellation-preconfirmation-modal/cancellation-preconfirmation-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,8BAAA;AACF;;AAEA;EACE,uBAAA;EACA,gBAAA;EACA,eAAA;EACA,8BAAA;AACF;;AAEA;EACE,uBAAA;AACF","sourcesContent":["ion-icon {\n  font-size: 32px;\n  color: var(--myw-color-danger);\n}\n\nh2 {\n  font-family: 'Cera Pro';\n  font-weight: 700;\n  font-size: 22px;\n  color: var(--myw-color-danger);\n}\n\np {\n  font-family: 'Cera Pro';\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
