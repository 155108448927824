// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.back {
  margin: 0 0 24px 0;
  display: flex;
  align-items: center;
  font-family: "Cera Pro Medium";
  font-size: 15px;
  letter-spacing: 0.2em;
  text-transform: uppercase;
  text-decoration: none;
}
.back ion-icon {
  margin: 0 13px 0 0;
  font-size: 13px;
}
.back span {
  padding: 2px 0 0 0;
}

.cancel-success-wrapper {
  text-align: center;
}
.cancel-success-wrapper ion-icon {
  font-size: 35px;
}
.cancel-success-wrapper h2 {
  font-family: "Cera Pro Medium";
  font-size: 35px;
  font-weight: normal;
  letter-spacing: 0.02em;
}
.cancel-success-wrapper h3 {
  margin: 30px 0 15px 0;
  font-family: "Cera Pro Medium";
  font-size: 20px;
  letter-spacing: 0.02em;
}
.cancel-success-wrapper p {
  font-family: "Cera Pro Regular";
  font-size: 17px;
  line-height: 1.5em;
  letter-spacing: 0.02em;
}`, "",{"version":3,"sources":["webpack://./src/app/pages/user/profile/cancellation-success/cancellation-success.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,qBAAA;EACA,yBAAA;EACA,qBAAA;AACF;AAAE;EACE,kBAAA;EACA,eAAA;AAEJ;AAAE;EACE,kBAAA;AAEJ;;AAEA;EACE,kBAAA;AACF;AAAE;EACE,eAAA;AAEJ;AACE;EACE,8BAAA;EACA,eAAA;EACA,mBAAA;EACA,sBAAA;AACJ;AAEE;EACE,qBAAA;EACA,8BAAA;EACA,eAAA;EACA,sBAAA;AAAJ;AAGE;EACE,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,sBAAA;AADJ","sourcesContent":[".back {\n  margin: 0 0 24px 0;\n  display: flex;\n  align-items: center;\n  font-family: 'Cera Pro Medium';\n  font-size: 15px;\n  letter-spacing: 0.2em;\n  text-transform: uppercase;\n  text-decoration: none;\n  ion-icon {\n    margin: 0 13px 0 0;\n    font-size: 13px;\n  }\n  span {\n    padding: 2px 0 0 0;\n  }\n}\n\n.cancel-success-wrapper {\n  text-align: center;\n  ion-icon {\n    font-size: 35px;\n  }\n\n  h2 {\n    font-family: 'Cera Pro Medium';\n    font-size: 35px;\n    font-weight: normal;\n    letter-spacing: 00.02em;\n  }\n\n  h3 {\n    margin: 30px 0 15px 0;\n    font-family: 'Cera Pro Medium';\n    font-size: 20px;\n    letter-spacing: 00.02em;\n  }\n\n  p {\n    font-family: 'Cera Pro Regular';\n    font-size: 17px;\n    line-height: 1.5em;\n    letter-spacing: 0.02em;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
