import { Component, Input, OnInit, inject } from '@angular/core';
import { FormGroup, FormGroupDirective } from '@angular/forms';

@Component({
  selector: 'app-password-input',
  templateUrl: './password-input.component.html',
  styleUrls: ['./password-input.component.scss'],
})
export class PasswordInputComponent implements OnInit {
  @Input() label: string = '';
  @Input() controlName: string;
  @Input() autoComplete: string = 'off';

  inputType = 'password';
  form: FormGroup;

  private rootFormGroup = inject(FormGroupDirective);

  constructor() {}

  ngOnInit(): void {
    this.form = this.rootFormGroup.form as FormGroup;
  }

  toggleInputType() {
    this.inputType = this.inputType === 'password' ? 'text' : 'password';
  }
}
