import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { MaskitoModule } from '@maskito/angular';
import { LottieModule } from 'ngx-lottie';
import { NgxPaginationModule } from 'ngx-pagination';
import { CloseButtonComponent } from 'src/app/components/close-button/close-button.component';
import { SwiperModule } from 'swiper/angular';
import { CancellationSuccessComponent } from '../pages/user/profile/cancellation-success/cancellation-success.component';
import { CancellationComponent } from '../pages/user/profile/cancellation/cancellation.component';
import { AccordionComponent } from './accordion/accordion.component';
import { AlertModalComponent } from './alert-modal/alert-modal.component';
import { BookingFeedbackComponent } from './booking-feedback/booking-feedback.component';
import { BookingHeaderComponent } from './booking-header/booking-header.component';
import { CancellationConfirmationModalComponent } from './cancellation-confirmation-modal/cancellation-confirmation-modal.component';
import { ChangePasswordFormComponent } from './change-password-form/change-password-form.component';
import { DateInputComponent } from './date-input/date-input.component';
import { ExpandableComponent } from './expandable/expandable.component';
import { BonusHoursComponent } from './loyalty/bonus-hours/bonus-hours.component';
import { LoyaltyDealDetailsComponent } from './loyalty/loyalty-deal-details/loyalty-deal-details.component';
import { LoyaltyDealsComponent } from './loyalty/loyalty-deals/loyalty-deals.component';
import { LoyaltyInfoModalComponent } from './loyalty/loyalty-info-modal/loyalty-info-modal.component';
import { LoyaltyRequestModalComponent } from './loyalty/loyalty-request-modal/loyalty-request-modal.component';
import { LoyaltyStatusComponent } from './loyalty/loyalty-status/loyalty-status.component';
import { LoyaltySuccessSignupModalComponent } from './loyalty/loyalty-success-signup-modal/loyalty-success-signup-modal.component';
import { NoFriendOverviewComponent } from './loyalty/no-friend-overview/no-friend-overview.component';
import { PointHistoryComponent } from './loyalty/point-history/point-history.component';
import { QuickInfoComponent } from './loyalty/quick-info/quick-info.component';
import { RedeemPointsModalComponent } from './loyalty/redeem-points-modal/redeem-points-modal.component';
import { RedeemPointsComponent } from './loyalty/redeem-points/redeem-points.component';
import { MyDataFormComponent } from './my-data-form/my-data-form.component';
import { NewsletterManagerComponent } from './newsletter-manager/newsletter-manager.component';
import { PasswordInputComponent } from './password-input/password-input.component';
import { PaymentMethodSelectorComponent } from './payment-method-selector/payment-method-selector.component';
import { PasswordResetComponent } from './register/forgotpw/forgotpw.component';
import { RegisterFormComponent } from './register/form/form.component';
import {
  FormatTimePipe,
  ReservationFloaterComponent,
} from './reservation-floater/reservation-floater.component';
import { StarRatingComponent } from './star-rating/star-rating.component';
import { UpgradeRadioGroupComponent } from './upgrade-radio-group/upgrade-radio-group.component';
import { VoucherRedeemComponent } from './voucher-redeem/voucher-redeem.component';
import { VouchersModalComponent } from './vouchers-modal/vouchers-modal.component';
import { LoyaltyRequestResendComponent } from './loyalty/loyalty-request-resend/loyalty-request-resend.component';
import { SuccessModalComponent } from './success-modal/success-modal.component';
import { CancellationPreconfirmationModalComponent } from './cancellation-preconfirmation-modal/cancellation-preconfirmation-modal.component';

@NgModule({
  declarations: [
    CancellationComponent,
    CancellationSuccessComponent,
    BookingHeaderComponent,
    PasswordResetComponent,
    CloseButtonComponent,
    ReservationFloaterComponent,
    FormatTimePipe,
    AlertModalComponent,
    BookingFeedbackComponent,
    StarRatingComponent,
    VoucherRedeemComponent,
    VouchersModalComponent,
    PaymentMethodSelectorComponent,
    CancellationConfirmationModalComponent,
    ExpandableComponent,
    UpgradeRadioGroupComponent,
    LoyaltyStatusComponent,
    PointHistoryComponent,
    QuickInfoComponent,
    RedeemPointsModalComponent,
    RedeemPointsComponent,
    AccordionComponent,
    LoyaltyInfoModalComponent,
    LoyaltyDealsComponent,
    LoyaltyDealDetailsComponent,
    MyDataFormComponent,
    PasswordInputComponent,
    ChangePasswordFormComponent,
    NewsletterManagerComponent,
    DateInputComponent,
    RegisterFormComponent,
    BonusHoursComponent,
    LoyaltyRequestModalComponent,
    LoyaltySuccessSignupModalComponent,
    NoFriendOverviewComponent,
    LoyaltyRequestResendComponent,
    SuccessModalComponent,
    CancellationPreconfirmationModalComponent,
  ],
  imports: [
    CommonModule,
    IonicModule,
    FormsModule,
    ReactiveFormsModule,
    LottieModule,
    RouterModule,
    NgxPaginationModule,
    SwiperModule,
    MaskitoModule,
  ],
  providers: [],
  exports: [
    CommonModule,
    BookingHeaderComponent,
    PasswordResetComponent,
    CloseButtonComponent,
    ReservationFloaterComponent,
    AlertModalComponent,
    BookingFeedbackComponent,
    StarRatingComponent,
    VoucherRedeemComponent,
    VouchersModalComponent,
    PaymentMethodSelectorComponent,
    CancellationConfirmationModalComponent,
    ExpandableComponent,
    UpgradeRadioGroupComponent,
    LoyaltyStatusComponent,
    PointHistoryComponent,
    QuickInfoComponent,
    RedeemPointsModalComponent,
    RedeemPointsComponent,
    AccordionComponent,
    LoyaltyInfoModalComponent,
    LoyaltyDealsComponent,
    LoyaltyDealDetailsComponent,
    MyDataFormComponent,
    PasswordInputComponent,
    ChangePasswordFormComponent,
    NewsletterManagerComponent,
    DateInputComponent,
    RegisterFormComponent,
    BonusHoursComponent,
    NoFriendOverviewComponent,
  ],
})
export class MyComponentsModule {}
