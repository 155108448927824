import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { LINKS } from 'src/app/core/constants/links';

@Component({
  selector: 'app-newsletter-manager',
  templateUrl: './newsletter-manager.component.html',
  styleUrls: ['./newsletter-manager.component.scss'],
})
export class NewsletterManagerComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() isModifingNewsletter: boolean = false;
  @Input() isSubscribed: boolean = false;
  @Input() isFriend: boolean = null;
  @Output() subscriptionsChanged = new EventEmitter<{
    is_subscribed: boolean;
    is_loyalty_member?: boolean;
  }>();

  LINKS = LINKS;

  hasChanged = false;

  private fb = inject(FormBuilder);
  readonly form: FormGroup = this.fb.group({
    newsletter: [false],
    friend: [false, { disabled: true }],
  });

  private subscription: Subscription;

  constructor() {}

  ngOnInit(): void {
    this.form.patchValue({
      newsletter: this.isSubscribed,
      friend: this.isFriend,
    });
    if (this.isFriend) this.form.get('friend').enable();

    const initalFormValue = this.form.value;

    this.subscription = this.form.valueChanges.subscribe(() => {
      this.hasChanged = Object.keys(this.form.value).some(
        key => this.form.value[key] !== initalFormValue[key],
      );
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (
      changes.isModifingNewsletter &&
      !changes.isModifingNewsletter.firstChange
    ) {
      this.form.reset({ newsletter: this.isSubscribed, friend: this.isFriend });
    }

    if (changes.isFriend && !changes.isFriend.firstChange)
      this.isFriend
        ? this.form.get('friend').enable()
        : this.form.get('friend').disable();
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  changeNewsletterSubscriptions() {
    if (!this.hasChanged) return;

    const { newsletter, friend } = this.form.getRawValue();

    this.subscriptionsChanged.emit(
      friend === null
        ? { is_subscribed: newsletter }
        : { is_subscribed: newsletter, is_loyalty_member: friend },
    );
  }
}
