// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-content {
  --background: transparent;
}

ion-card {
  position: relative;
  padding: 48px 32px 32px;
}
ion-card .close-button {
  position: absolute;
  top: 32px;
  right: 24px;
  font-size: 32px;
  cursor: pointer;
}

.header-icon {
  font-size: 35px;
}

p {
  font-family: "Cera Pro Regular";
  font-size: 17px;
  line-height: 29px;
}`, "",{"version":3,"sources":["webpack://./src/app/features/user-menu/components/login-popover/login-popover.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;AACF;;AAEA;EACE,kBAAA;EACA,uBAAA;AACF;AACE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EAEA,eAAA;EAEA,eAAA;AADJ;;AAKA;EACE,eAAA;AAFF;;AAKA;EACE,+BAAA;EACA,eAAA;EACA,iBAAA;AAFF","sourcesContent":["ion-content {\n  --background: transparent;\n}\n\nion-card {\n  position: relative;\n  padding: 48px 32px 32px;\n\n  .close-button {\n    position: absolute;\n    top: 32px;\n    right: 24px;\n\n    font-size: 32px;\n\n    cursor: pointer;\n  }\n}\n\n.header-icon {\n  font-size: 35px;\n}\n\np {\n  font-family: 'Cera Pro Regular';\n  font-size: 17px;\n  line-height: 29px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
