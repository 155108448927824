import { Location } from '@angular/common';
import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, take, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import {
  PaymentMethod,
  PaymentMethodAdapter,
} from '../../../../core/api/model/payment-method.model';
import {
  PreconfigurationOptions,
  PreconfigurationOptionsAdapter,
  SpaPackage,
} from './preconfiguration-options.model';
import { ADD_AUTHORIZATION_HEADER } from '../../../../core/interceptors/jwt.interceptor';
import { VoucherService } from '../../../../core/shared/voucher.service';

export interface PreConfig {
  booking_id: number | string;
  ambience?: any;
  sauna_infusion?: any;
  sauna_temperature?: any;
  upgrades?: Array<any>;
  spa_package?: SpaPackage;
  pool_filling_by_customer?: boolean;
}

@Injectable()
export class PreconfigurationService {
  private _preconfigurationSettings$ = new BehaviorSubject<PreConfig>(null);
  public readonly preconfigurationSettings$ =
    this._preconfigurationSettings$.asObservable();

  private reloadOptions$ = new BehaviorSubject<void>(undefined);

  bookingId: number | string;

  private _vouchers$ = new BehaviorSubject<
    Array<{ code: string; value: number }>
  >([]);
  readonly vouchers$ = this._vouchers$.asObservable();
  voucherCodes: Array<string> = [];

  readonly subtotal$ = this._preconfigurationSettings$.pipe(
    map(preconfig => {
      this._subtotal = preconfig?.spa_package?.price || 0;
      return preconfig?.spa_package?.price || 0;
    }),
  );
  private _subtotal = 0;
  total$ = combineLatest([this.preconfigurationSettings$, this.vouchers$]).pipe(
    map(([preconfig, vouchers]) => {
      this._total =
        (preconfig?.spa_package?.price || 0) -
        vouchers.reduce((acc, voucher) => acc + voucher.value, 0);
      return (
        (preconfig?.spa_package?.price || 0) -
        vouchers.reduce((acc, voucher) => acc + voucher.value, 0)
      );
    }),
  );
  private _total = 0;

  private _vouchers: Array<{ code: string; value: number }> = [];

  get vouchers() {
    return this._vouchers;
  }

  get total() {
    return this._total;
  }

  constructor(
    private location: Location,
    private http: HttpClient,
    private voucherService: VoucherService,
    private paymentMethodAdapter: PaymentMethodAdapter,
    private preconfigurationOptionsAdapter: PreconfigurationOptionsAdapter,
  ) {
    this._preconfigurationSettings$.next(this.location.getState() as PreConfig);

    this.location.subscribe(event => {
      this._preconfigurationSettings$.next(event.state);
    });
  }

  submitPreconfiguration(): Observable<any> {
    return this.preconfigurationSettings$.pipe(
      take(1),
      switchMap(preconfig => {
        const upgrades = preconfig.upgrades
          .map(upgrade => {
            return {
              upgrade_id: upgrade.upgrade_id,
              options: upgrade.options.map(option => {
                return {
                  entity_id: option.entity_id,
                  // qty: option.qty,
                };
              }),
            };
          })
          .filter(upgrade => upgrade.options.length > 0);

        const data: PreConfig = {
          booking_id: preconfig.booking_id,
          ambience: preconfig.ambience?.id,
          sauna_temperature: preconfig.sauna_temperature,
          sauna_infusion: preconfig.sauna_infusion?.id,
          upgrades: upgrades || [],
          pool_filling_by_customer: preconfig.pool_filling_by_customer,
        };
        return this.postPreconfiguration(data);
      }),
    );
  }

  setPreconfigurations(preconfig: PreConfig) {
    this.updatePreconfigurations(preconfig);
  }

  setAmbience(ambience: number) {
    const newPreconfig = { ...this._preconfigurationSettings$.value, ambience };

    this.updatePreconfigurations(newPreconfig);
  }

  setSaunaInfusion(sauna_infusion: number) {
    const newPreconfig = {
      ...this._preconfigurationSettings$.value,
      sauna_infusion,
    };
    this.updatePreconfigurations(newPreconfig);
  }

  setSaunaTemperature(sauna_temperature: number) {
    const newPreconfig = {
      ...this._preconfigurationSettings$.value,
      sauna_temperature,
    };

    this.updatePreconfigurations(newPreconfig);
  }

  setSpaPackage(spa_package: SpaPackage) {
    const newPreconfig = {
      ...this._preconfigurationSettings$.value,
      spa_package: spa_package,
    };

    this.updatePreconfigurations(newPreconfig);
  }

  setUpgrades(eventData) {
    const upgradeIndex =
      this._preconfigurationSettings$.value.upgrades.findIndex(
        upgrade => upgrade.upgrade_id === eventData.upgradeId,
      );
    let newUpgrades = this._preconfigurationSettings$.value.upgrades;
    if (upgradeIndex < 0) {
      const newLength = newUpgrades.push({
        upgrade_id: eventData.upgradeId,
        options: [],
      });
      newUpgrades[newLength - 1].options[eventData.index] = {
        entity_id: eventData.value,
        label: eventData.label,
        qty: 1,
      };
    } else {
      newUpgrades[upgradeIndex].options[eventData.index] = {
        entity_id: eventData.value,
        label: eventData.label,
        qty: 1,
      };
    }

    const newPreconfig = {
      ...this._preconfigurationSettings$.value,
      booking_id: this.bookingId,
      upgrades: newUpgrades,
    };

    this.updatePreconfigurations(newPreconfig);
  }

  updatePreconfigurations(preconfig: PreConfig) {
    this.location.replaceState(
      this.location.path().split('?')[0],
      this.location.path().split('?')[1],
      preconfig,
    );
    this._preconfigurationSettings$.next(preconfig);
  }

  // Api calls
  getPreconfigurationData(
    bookingId: number | string,
  ): Observable<PreconfigurationOptions> {
    return this.reloadOptions$.pipe(
      switchMap(() => this.fetchPreconfigurationData(bookingId)),
      shareReplay(1),
    );
  }

  fetchPreconfigurationData(bookingId: number | string) {
    return this.http
      .get(`${environment.apiUrl}/preconfiguration-options/${bookingId}`)
      .pipe(
        map(data => this.preconfigurationOptionsAdapter.adapt(data)),
        tap(async data => {
          this.bookingId = bookingId;
        }),
      );
  }

  refreshPreconfigurationData() {
    this.reloadOptions$.next();
  }

  postPreconfiguration(data: PreConfig): Observable<any> {
    return this.http.post(`${environment.apiUrl}/preconfiguration`, data);
  }

  getPreconfigurationPaymentMethods(): Observable<Array<PaymentMethod>> {
    return this.http
      .post(`${environment.apiUrl}/preconfiguration/paymentMethods`, {})
      .pipe(
        map((data: any[]) =>
          data.map(item => this.paymentMethodAdapter.adapt(item)),
        ),
      );
  }

  getPaymentToken(
    booking_id: string | number,
    spa_package: number,
  ): Observable<any> {
    return this.http.post(`${environment.apiUrl}/preconfiguration-data`, {
      booking_id,
      package: spa_package,
    });
  }

  initiatePayment(
    token: string,
    data: PreconfigurationInitiatePaymentRequest,
  ): Observable<any> {
    return this.http.post(
      `${environment.apiUrl}/preconfiguration/${token}/initiatePayment`,
      data,
      { context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true) },
    );
  }

  // Voucher stuff
  async addVoucher(code: string) {
    let currentTotal = this._total;

    if (currentTotal <= 0) {
      return;
    }
    try {
      const result = await this.voucherService.getVoucherData(
        code,
        currentTotal,
      );
      if (result) {
        this.updateVouchers([
          ...this.vouchers,
          { code: code, value: result.messageBookedAmount },
        ]);
      }
    } catch (error) {
      this.handleVoucherError(error);
    }
  }

  removeVoucher(code: string) {
    const filteredVouchers = this._vouchers.filter(
      voucher => voucher.code !== code,
    );
    this.updateVouchers(filteredVouchers);
    this.revalidateVouchers();
  }

  async addVouchers(codes: Array<string>) {
    let currentTotal = this._total;
    for (const code of codes) {
      if (currentTotal <= 0) {
        return;
      }
      try {
        const result = await this.voucherService.getVoucherData(
          code,
          currentTotal,
        );

        if (result) {
          currentTotal -= result.messageBookedAmount;
          this.updateVouchers([
            ...this.vouchers,
            { code: code, value: result.messageBookedAmount },
          ]);
        }
      } catch (error) {
        console.error(error);
      }
    }
  }

  removeVouchers(codes: Array<string>) {
    this._vouchers$.next(
      this.vouchers.filter(voucher => !codes.includes(voucher.code)),
    );
    this.revalidateVouchers();
  }

  resetVouchers() {
    this.updateVouchers([]);
  }

  async revalidateVouchers() {
    let currentTotal = this._subtotal;
    let updatedVouchers = [];
    for (const voucher of this._vouchers) {
      try {
        const result = await this.voucherService.getVoucherData(
          voucher.code,
          currentTotal,
        );

        if (result) {
          currentTotal -= result.messageBookedAmount;
          updatedVouchers.push({
            code: voucher.code,
            value: result.messageBookedAmount,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }

    this.updateVouchers(updatedVouchers);
  }

  private updateVouchers(vouchers: Array<{ code: string; value: number }>) {
    this._vouchers = vouchers;
    this._vouchers$.next(vouchers);
  }

  handleVoucherError(error: any) {
    console.error(error);
  }
}

type PreconfigurationInitiatePaymentRequest = {
  salesBooking: any;
  preconfigurationPrice?: number;
  return_url?: string;
  selected_payment_method: string;
  vouchers?: Array<string>;
  preconfig_data: {
    package: number;
  };
};
