import { Component, OnInit, inject } from '@angular/core';
import {
  FormBuilder,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { BookingService } from 'src/app/core/shared/booking.service';
import { FeedbackService } from 'src/app/core/shared/feedback.service';

@Component({
  templateUrl: './booking-feedback.component.html',
  styleUrls: ['./booking-feedback.component.scss'],
})
export class BookingFeedbackComponent {
  private modalCtrl = inject(ModalController);
  private bookingService = inject(BookingService);
  private feedbackService = inject(FeedbackService);
  private fb = inject(FormBuilder);

  form = this.fb.group({
    star_rating: [
      0,
      Validators.compose([
        Validators.required,
        Validators.min(1),
        Validators.max(5),
      ]),
    ],
    feedback_text: [''],
  });

  showExtras: boolean = false;

  constructor() {}

  async submit() {
    const data = {
      ...this.form.getRawValue(),
      booking_increment_id: this.bookingService.bookingData.increment_id,
      device: window.navigator.userAgent,
    };
    this.feedbackService.sendFeedback(data);
    this.dismiss();
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
