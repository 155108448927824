import { Adapter } from '../common/adapter.interface';
import { Injectable } from '@angular/core';

export class LoyaltyTransaction {
    constructor(
      public id: string,
      public type: 'rewarded' | 'redeemed',
      public points: string,
      public date: Date
    ) {}
  }
  
  @Injectable({
    providedIn: 'root'
  })
  export class LoyaltyTransactionAdapter implements Adapter<LoyaltyTransaction> {
    adapt(item: any): LoyaltyTransaction {
      return new LoyaltyTransaction(
        item.transactionNumber,
        item.type,
        item.points,
        new Date(item.date)
      );
    }
  }
  