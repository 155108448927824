import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class VoucherService {
  private http = inject(HttpClient);
  private paymentBaseUrl = environment.paymentApiUrl;

  getVoucherData(code: string, balance: number) {
    return firstValueFrom(
      this.http.put<any>(`${this.paymentBaseUrl}/vouchers/${code}/validate`, {
        balance: balance,
      }),
    );
  }
}
