import { Adapter } from '../common/adapter.interface';
import { Injectable } from '@angular/core';

export class LoyaltyEvent {
  constructor(
    public id: string,
    public name: string,
    public rewardText: string,
    public iconUrl: string,
    public text1: string,
    public text2: string,
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class LoyaltyEventAdapter implements Adapter<LoyaltyEvent> {
  adapt(item: any): LoyaltyEvent {
    return new LoyaltyEvent(
        item.programId,
        item.name,
        item.earningText,
        item.icon,
        item.text_1,
        item.text_2,
    );
  }
}
