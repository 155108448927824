import { Injectable, inject } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { AlertController, ModalController } from '@ionic/angular';
import { BehaviorSubject, Observable, timer } from 'rxjs';
import { filter, map, take, takeWhile } from 'rxjs/operators';
import { Logger } from 'src/app/common/provider/logger.service';
import { PreferenceService } from 'src/app/common/provider/preference.service';
import { AlertModalComponent } from 'src/app/components/alert-modal/alert-modal.component';
import { environment } from 'src/environments/environment';
import { BookingController } from '../api/controller/booking.controller';
import { BookingService } from './booking.service';

@Injectable({
  providedIn: 'root',
})
export class BookingReservationService {
  private extensionAlertTime = 60;
  private isChecking = false;
  private log: Logger;
  private extensionAlert: HTMLIonModalElement;

  public reservationData: {
    reservation_id: number;
    reserved_until: string;
    times_extended: number;
    max_times_extended: number;
    minutes_extended: number;
    minutes_reserved: number;
    outlet_id: number;
    suite_type_id: number;
    hasInitiatedPayment: boolean;
  };

  private isDev: boolean = false;

  counter: number;
  countDown$: Observable<number>;
  hasReservation$: BehaviorSubject<boolean>;
  isInBooking: boolean;

  private alertCtrl = inject(AlertController);

  constructor(
    private router: Router,
    private modalController: ModalController,
    private logger: Logger,
    private bookingService: BookingService,
    private bookingController: BookingController,
    private preferences: PreferenceService,
  ) {
    this.log = this.logger.createLogger('BookingReservationService');
  }

  public async init() {
    this.log.debug('init');

    this.hasReservation$ = new BehaviorSubject<boolean>(false);
    this.reservationData = await this.preferences.getReservationData();

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        if (
          (e.url.includes('/user') && e.url !== '/booking/user') ||
          e.url.includes('/booking/done')
        ) {
          this.isInBooking = false;
          this.resetReservation(false);
        } else {
          this.isInBooking = true;
          if (e.id !== 1) {
            return;
          }
          if (
            this.reservationData &&
            !this.reservationData.hasInitiatedPayment
          ) {
            this.checkSuiteBlocking(true);
          }
          if (
            this.reservationData &&
            this.reservationData.hasInitiatedPayment
          ) {
            this.setCounter();
            this.makeCountDown();
          }
        }
      });

    this.bookingService.dataChanged.subscribe(res => {
      if (res && this.reservationData) {
        this.resetReservation(false);
      }
    });
  }

  public async blockSuite() {
    const data = {
      outlet_id: this.bookingService.bookingData.outletId,
      suite_type_id: this.bookingService.bookingData.suiteId,
      booking_date: this.bookingService.bookingData.date,
      slot_from_time_start: this.bookingService.bookingData.from_time + ':00',
      slot_to_time_start: this.bookingService.bookingData.to_time + ':00',
      hours_booked: +this.bookingService.bookingData.duration.split(':')[0],
      persons_booked: +this.bookingService.bookingData.capacity,
      reservation_id:
        this.reservationData?.reservation_id ||
        this.bookingService.bookingData.reservation_id,
    };
    try {
      this.reservationData = await this.bookingController.blockSuite(data);
      this.reservationData.suite_type_id = data.suite_type_id;
      this.reservationData.outlet_id = data.outlet_id;

      this.bookingService.addBookingData({
        reservation_id: this.reservationData.reservation_id,
      });
      this.preferences.setReservationData(this.reservationData);
      if (!this.isDev) {
        this.setCounter();
      } else {
        this.counter = 90;
      }

      this.makeCountDown();
      return true;
    } catch {
      this.hasReservation$.next(false);
      return false;
    }
  }

  async releaseSuite(): Promise<string> {
    if (this.hasReservation$.getValue() === false) return;
    const alert = await this.alertCtrl.create({
      cssClass: 'myw-alert',
      header: 'Achtung',
      message: 'Möchtest Du die Reservierung wirklich abbrechen?',
      buttons: [
        {
          text: 'Ja',
          handler: async () => {
            try {
              await this.bookingController.releaseSuite(
                this.reservationData.reservation_id,
              );
            } catch (error) {
              this.log.error(error);
            } finally {
              this.resetReservation(false);
            }
          },
          role: 'confirm',
        },
        {
          text: 'Nein',
          role: 'cancel',
        },
      ],
    });

    await alert.present();
    const { role } = await alert.onDidDismiss();
    return role;
  }

  public async checkSuiteBlocking(makeObservable: boolean = false) {
    try {
      this.reservationData = await this.bookingController.checkSuiteBlocking(
        this.reservationData.reservation_id,
      );
      if (!this.isDev) {
        this.setCounter();
      }
      if (!this.counter) {
        this.counter = 90;
      }
      if (makeObservable) {
        this.makeCountDown();
      }
    } catch {
      this.resetReservation();
      if (this.isInBooking) {
        this.router.navigate(['booking']);
      }
    }
  }

  public async extendSuiteBlocking() {
    try {
      this.reservationData = await this.bookingController.extendSuiteBlocking(
        this.reservationData.reservation_id,
      );
      if (!this.isDev) {
        this.setCounter();
      } else {
        this.counter = 120;
      }
    } catch {
      this.resetReservation();
      if (this.isInBooking) {
        this.router.navigate(['booking']);
      }
    }
  }

  async askForExtension() {
    this.extensionAlert = await this.modalController.create({
      component: AlertModalComponent,
      cssClass: 'myw-alert-modal auto-height',
      componentProps: {
        iconSrc: 'assets/icons/uhr.svg',
        title: 'Achtung',
        message:
          'Bitte beachte, dass Deine Reservierung gleich endet. Brauchst Du noch mehr Zeit?',
        btnPrimary: {
          text: 'Ja',
          handler: () => {
            this.extendSuiteBlocking();
            this.reservationExtendedAlert();
            this.extensionAlert.dismiss();
          },
        },
        btnSecondary: {
          text: 'Buchung abbrechen',
          handler: () => {
            this.resetReservation(false);
            if (this.isInBooking) {
              this.router.navigate(['booking']);
            }
            this.reservationCanceledAlert();
          },
        },
      },
    });

    this.extensionAlert.present();
  }

  async reservationExtendedAlert() {
    const extendedAlert = await this.modalController.create({
      component: AlertModalComponent,
      cssClass: 'myw-alert-modal auto-height',
      componentProps: {
        iconSrc: 'assets/icons/uhr.svg',
        title: 'Alles klar!',
        message:
          'Du hast 5 Minuten mehr Zeit, Deine Reservierung abzuschließen.',
        btnSecondary: {
          text: 'Schliessen',
          handler: () => {
            extendedAlert.dismiss();
          },
        },
      },
    });

    extendedAlert.present();
  }

  async reservationCanceledAlert() {
    const cancelAlert = await this.modalController.create({
      component: AlertModalComponent,
      cssClass: 'myw-alert-modal auto-height',
      componentProps: {
        iconSrc: 'assets/icons/uhr.svg',
        title: 'Alles klar,',
        message: 'dann geben wir Deine Auswahl wieder zur Buchung frei.',
        btnSecondary: {
          text: 'Schliessen',
          handler: () => {
            cancelAlert.dismiss();
          },
        },
      },
    });

    cancelAlert.present();
  }

  async reservationEnded() {
    const reservationEndedAlert = await this.modalController.create({
      component: AlertModalComponent,
      cssClass: 'myw-alert-modal auto-height',
      componentProps: {
        iconSrc: 'assets/icons/uhr.svg',
        title: 'Reservierung abgelaufen',
        message:
          'Deine Reservierungszeit ist leider abgelaufen, Du kannst jetzt erneut buchen.',
        btnSecondary: {
          text: 'Schliessen',
          handler: () => {
            reservationEndedAlert.dismiss();
          },
        },
      },
    });

    reservationEndedAlert.present();
  }

  makeCountDown() {
    this.countDown$ = timer(0, 1000).pipe(
      takeWhile(() => this.counter >= 0),
      map(() => {
        if (!this.reservationData?.reservation_id) {
          return;
        }
        this.counter--;
        if (this.reservationData.hasInitiatedPayment && this.counter > 0) {
          return this.counter;
        }
        if (this.counter === 0) {
          this.resetReservation();
          if (this.isInBooking) {
            this.router.navigate(['booking']);
          }
        } else if (
          this.counter === this.extensionAlertTime &&
          this.reservationData.times_extended <
            this.reservationData.max_times_extended
        ) {
          this.askForExtension();
        } else if (this.counter % 60 === 0 && !this.isChecking) {
          this.isChecking = true;
          this.checkSuiteBlocking().then(() => (this.isChecking = false));
        }
        return this.counter;
      }),
    );
    this.hasReservation$.next(true);
  }

  async onInitPayment() {
    if (this.reservationData.hasInitiatedPayment) {
      return;
    }
    this.reservationData.hasInitiatedPayment = true;
    const date = new Date();
    date.setMinutes(date.getMinutes() + 15);
    this.reservationData.reserved_until = date.toISOString();
    await this.preferences.setReservationData(this.reservationData);
    this.setCounter();
    this.hasReservation$.next(true);
  }

  resetReservation(showAlert: boolean = true) {
    this.hasReservation$.next(false);
    this.preferences.setReservationHasBeenShown(false);
    this.reservationData = null;
    this.preferences.deleteReservationData();
    if (this.extensionAlert) {
      this.extensionAlert.dismiss();
    }
    if (showAlert) {
      this.reservationEnded();
    }
  }

  setCounter() {
    const startTime = new Date().getTime();
    const endTime = new Date(
      this.reservationData.reserved_until.replace(' ', 'T'),
    ).getTime();
    this.counter = Math.floor((endTime - startTime) / 1000);
  }
}
