import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'app-bonus-hours',
  templateUrl: './bonus-hours.component.html',
  styleUrls: ['./bonus-hours.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BonusHoursComponent {
  @Input() bonusHours: number;

  constructor() {}
}
