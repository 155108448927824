import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { LoadingController, ModalController } from '@ionic/angular';
import { BookingService } from 'src/app/core/shared/booking.service';
import { CustomerService } from 'src/app/core/shared/customer.service';
import { ExtensionService } from 'src/app/core/shared/extension.service';
import { VouchersModalComponent } from '../vouchers-modal/vouchers-modal.component';
import { PreconfigurationService } from 'src/app/pages/user/profile/preconfiguration/preconfiguration.service';

@Component({
  selector: 'app-voucher-redeem',
  templateUrl: './voucher-redeem.component.html',
  styleUrls: ['./voucher-redeem.component.scss'],
})
export class VoucherRedeemComponent implements OnInit {
  @Output() newVoucherCode = new EventEmitter<string>();
  @Output() modalClosed = new EventEmitter<boolean>();
  @Input() validationService:
    | BookingService
    | ExtensionService
    | PreconfigurationService;
  @Input() blockedVoucherTypes: Array<string> = [];

  showContent: boolean = false;
  voucherCodeForm: UntypedFormGroup;

  customer$ = this.customerService.customer$;

  constructor(
    private fb: UntypedFormBuilder,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private customerService: CustomerService,
  ) {}

  async ngOnInit(): Promise<void> {
    this.voucherCodeForm = this.fb.group({
      code: ['', Validators.required],
    });
  }

  async addNewVoucher() {
    const loader = await this.loadingController.create({});
    loader.present();
    const codeControl = this.voucherCodeForm.get('code');
    try {
      const success = await this.validationService.addVoucher(
        codeControl.value,
      );
      if (!success) {
        codeControl.setErrors({ invalid_or_notunique: true });
        return;
      }
      this.newVoucherCode.emit(codeControl.value);
      this.voucherCodeForm.get('code').reset();
    } catch (error) {
    } finally {
      loader.dismiss();
    }
  }

  toggleContentView() {
    this.showContent = !this.showContent;
  }

  async presentVoucherModal() {
    const loader = await this.loadingController.create({});
    loader.present();
    await this.customerService.refreshVouchers();
    const vouchers = (await this.customerService.getAllVouchers(true)).filter(
      voucher => !this.blockedVoucherTypes.includes(voucher.type),
    );
    let currentVouchers = [];
    if (this.validationService instanceof BookingService) {
      currentVouchers = this.validationService.bookingData.vouchers.map(
        voucher => voucher.voucher_code,
      );
    } else if (this.validationService instanceof ExtensionService) {
      currentVouchers = this.validationService.vouchers.map(
        voucher => voucher.voucher_code,
      );
    } else if (this.validationService instanceof PreconfigurationService) {
      currentVouchers = this.validationService.vouchers.map(
        voucher => voucher.code,
      );
    }
    const modal = await this.modalController.create({
      cssClass: 'myw-vouchers-modal auto-height',
      component: VouchersModalComponent,
      componentProps: {
        vouchers,
        currentVouchers,
        totalPrice: 38,
        validationService: this.validationService,
      },
    });
    loader.dismiss();
    await modal.present();
    modal.onDidDismiss().then(() => this.modalClosed.emit(true));
  }
}
