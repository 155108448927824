import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { BookingReservationService } from 'src/app/core/shared/booking-reservation.service';
import { environment } from 'src/environments/environment';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  hasReservation: boolean;
  isInBooking: boolean;

  constructor(
    private platform: Platform,
    private router: Router,
    private reservationService: BookingReservationService,
  ) {
    this.init();

    if (environment.isDev) {
      console.debug('DEV MODE');
    }

    this.platform.backButton.subscribeWithPriority(100, () => {
      const el: HTMLElement = document.querySelector(
        'ion-router-outlet .ion-page:not(.ion-page-hidden) ion-back-button',
      ) as HTMLElement;

      if (el) {
        el.click();
      }
    });

    this.reservationService.hasReservation$.subscribe(
      v => (this.hasReservation = v),
    );

    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd))
      .subscribe((e: NavigationEnd) => {
        this.isInBooking = e.urlAfterRedirects.includes('booking');
      });
  }

  private async init(): Promise<void> {
    this.reservationService.init();
  }
}
