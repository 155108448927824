import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, retry } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Question, QuestionAdapter } from '../model/question.model';
import {
  FeedbackAnswersResponse,
  GoogleReviewRequest,
} from '../model/feedback.model';

@Injectable({
  providedIn: 'root',
})
export class FeedbackController {
  private baseUrl = environment.apiUrl;

  constructor(private http: HttpClient, private adapter: QuestionAdapter) {}

  public getFeedbackQuestions(token: string): Promise<Array<Question>> {
    const url = `${this.baseUrl}/feedback-questions/${token}`;
    return new Promise((resolve, reject) => {
      this.http
        .get(url)
        .pipe(map((data: any[]) => data.map(item => this.adapter.adapt(item))))
        .subscribe(resolve, reject);
    });
  }

  public submitFeedback(
    token: string,
    data: any,
  ): Promise<FeedbackAnswersResponse> {
    const url = `${this.baseUrl}/feedback-answers/${token}`;
    return new Promise((resolve, reject) => {
      this.http.post(url, data).subscribe(resolve, reject);
    });
  }

  public sendFeedback(feedbackBody) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.baseUrl}/customer-app-feedback`, feedbackBody)
        .subscribe(resolve, reject);
    });
  }

  public googleReview(data: GoogleReviewRequest) {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.baseUrl}/google-review`, data)
        .subscribe(resolve, reject);
    });
  }
}
