import { Injectable } from '@angular/core';
import { AlertController } from '@ionic/angular';
import { BookingController } from '../api/controller/booking.controller';
import { ExtensionInfo } from '../api/model/extension-info.model';
import { ExtensionPaymentData } from '../api/model/extension-payment-data.model';
import { PaymentMethod } from '../api/model/payment-method.model';
@Injectable({
  providedIn: 'root'
})
export class ExtensionService {
  incrementId: string;
  extensionToken: string;
  extensionInfo: ExtensionInfo;

  hours_extended_before_booking: number;
  hours_extended_after_booking: number;
  minutes_extended_before_booking: number;
  minutes_extended_after_booking: number;

  newStartTime: string;
  newEndTime: string;

  discount: number;

  vouchers: Array<any> = [];

  get totalHoursExtended(): number {
    return (
      this.hours_extended_before_booking + this.hours_extended_after_booking
    );
  }

  get totalMinutesExtended(): number {
    return (
      this.minutes_extended_before_booking + this.minutes_extended_after_booking
    );
  }

  get newTotalHours(): number {
    return this.extensionInfo.hours_booked + this.totalHoursExtended;
  }

  get newTotalMinutes(): number {
    return this.extensionInfo.minutes_booked + this.totalMinutesExtended;
  }

  get extensionPrice(): number {
    return (
      this.extensionInfo.extensionUnitPrice *
      (this.totalHoursExtended + this.totalMinutesExtended / 60) *
      this.extensionInfo.personsBooked
    );
  }

  get discountAmount(): number {
    return this.extensionPrice * this.discount;
  }

  get total(): number {
    return this.extensionPrice - this.discountAmount;
  }

  get totalAfterVouchers(): number {
    const deductionTotal: number = this.vouchers.reduce(
      (previousValue, currentValue) =>
        previousValue + currentValue.deduction_amount,
      0
    );
    return this.total - deductionTotal;
  }

  constructor(
    private bookingController: BookingController,
    private alertController: AlertController
  ) {}

  async getExtensionInfo(extensionToken: string): Promise<boolean> {
    try {
      this.extensionToken = extensionToken;
      this.extensionInfo = await this.bookingController.getExtensionInfo(
        extensionToken
      );
      return true;
    } catch (error) {
      return false;
    }
  }

  async getExtensionDiscount() {
    this.discount = await this.bookingController.getExtensionDiscount({
      bookingDate: this.extensionInfo.bookingDate,
      bookingTime: this.extensionInfo.startTime,
      bookingEndTime: this.extensionInfo.endTime,
      minutesBookedBefore:
        (this.minutes_extended_before_booking +
          this.hours_extended_before_booking * 60) |
        0,
      minutesBookedAfter:
        (this.minutes_extended_after_booking +
          this.hours_extended_after_booking * 60) |
        0,
      totalMinutesExtendableBefore: this.extensionInfo
        .totalMinutesExtendableBefore,
      totalMinutesExtendableAfter: this.extensionInfo
        .totalMinutesExtendableAfter
    });
  }

  async getExtensionPaymentMethods(data: {
    hours_extended_before_booking: number;
    hours_extended_after_booking: number;
    minutes_extended_before_booking: number;
    minutes_extended_after_booking: number;
  }): Promise<PaymentMethod[]> {
    return await this.bookingController.getExtensionPaymentMethods(
      this.extensionToken,
      data
    );
  }

  public resetVouchers(): void {
    this.vouchers = [];
  }

  public async revalidateVouchers() {
    let newTotal = this.total;
    let newVoucherArray = [];

    for (const vc of this.vouchers) {
      const response = await this.bookingController.validateVouchersWithBalance(
        vc.voucher_code,
        newTotal
      );

      newTotal = response.messageDueAmount;

      newVoucherArray.push({
        voucher_code: vc.voucher_code,
        deduction_amount: response.messageBookedAmount
      });

      if (newTotal <= 0) {
        this.vouchers = newVoucherArray;
        return;
      }
    }
  }

  async addVoucher(newVoucherCode: string): Promise<boolean> {
    if (this.totalAfterVouchers <= 0) {
      return false;
    }

    if (this.vouchers.find((v) => v.voucher_code === newVoucherCode)) {
      return false;
    }

    try {
      const response = await this.bookingController.validateVouchersWithBalance(
        newVoucherCode,
        this.totalAfterVouchers
      );
      this.vouchers.push({
        voucher_code: newVoucherCode,
        deduction_amount: response.messageBookedAmount
      });
      return true;
    } catch (error) {
      return false;
    }
  }

  async addVouchers(voucherCodes: Array<string>) {
    let allSuccessful = true;
    for (const vc of voucherCodes) {
      if (!(await this.addVoucher(vc))) {allSuccessful = false;}
    }

    if (!allSuccessful) {
      const alert = await this.alertController.create({
        cssClass: 'myw-alert',
        header: ' Achtung',
        message:
          'Ein oder mehrere Gutscheine konnten nicht hinzugefügt werden.',
        buttons: ['OK']
      });

      alert.present();
    }
  }

  removeVoucher(voucherCode: string) {
    this.vouchers = this.vouchers.filter((v) => v.voucher_code !== voucherCode);
    this.revalidateVouchers();
  }

  removeVouchers(voucherCodes: Array<string>) {
    this.vouchers = this.vouchers.filter(
      (v) => !voucherCodes.includes(v.voucher_code)
    );
    this.revalidateVouchers();
  }

  async initiateExtensionPayment(
    return_url: string,
    selected_payment_method: string
  ) {
    const voucher_codes = this.vouchers.map((v) => v.voucher_code);
    const paymentData: ExtensionPaymentData = {
      hours_extended_before_booking: this.hours_extended_before_booking,
      hours_extended_after_booking: this.hours_extended_after_booking,
      minutes_extended_before_booking: this.minutes_extended_before_booking,
      minutes_extended_after_booking: this.minutes_extended_after_booking,
      selected_payment_method,
      return_url,
      voucher_codes
    };
    return this.bookingController.initiateExtensionPayment(
      this.extensionToken,
      paymentData
    );
  }
}
