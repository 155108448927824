import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import { CustomerService } from '../../core/shared/customer.service';
import { map, take } from 'rxjs/operators';
import { Customer } from '../../core/api/model/customer.model';
import { Observable } from 'rxjs';

/**
 * Only user with a active suite session have access to the suite dashboard
 */
@Injectable()
export class LoginGuard  {
  constructor(
    private router: Router,
    private customerService: CustomerService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.customerService.customer$.pipe(
      take(1),
      map((customer: Customer) => {
        return customer
          ? true
          : this.router.parseUrl(
              `user/login?target=${encodeURIComponent(state.url)}`,
            );
      }),
    );
  }
}

@Injectable()
export class NoLoginGuard  {
  constructor(
    private router: Router,
    private customerService: CustomerService,
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> {
    return this.customerService.customer$.pipe(
      take(1),
      map((customer: Customer) => {
        return customer ? this.router.parseUrl('/suite') : true;
      }),
    );
  }
}
