import { Injectable } from '@angular/core';
import { Adapter } from '../common/adapter.interface';

export class QuestionOption {
  constructor(
    public id: number,
    public value: string,
    public label: string,
    public isDefault: boolean
  ) {}
}

export class Question {
  constructor(
    public id: number,
    public question: string,
    public additionalInfo: any,
    public imagePath: string,
    public type: number,
    public isRequired: boolean,
    public dependentOnQuestionId: number,
    public showWhenValue: string,
    public sortOrder: number,
    public options: Array<QuestionOption>,
    public dependentQuestions: Array<Question>
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class QuestionAdapter implements Adapter<Question> {
  constructor() {}

  adapt(item: any): Question {
    let array_of_options = item.feedback_question_options.map((option) => {
      return new QuestionOption(
        option.option_id,
        option.value,
        option.label,
        !!option.is_default
      );
    });

    return new Question(
      item.question_id,
      item.question,
      item.additional_info,
      item.image_path,
      item.type,
      !!item.is_required,
      item.dependent_on_question_id,
      item.show_when_value,
      item.sort_order,
      array_of_options,
      item.dependent_questions.map((q) => {
        return this.adapt(q);
      })
    );
  }
}
