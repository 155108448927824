import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { LINKS } from 'src/app/core/constants/links';
import { BookingService } from 'src/app/core/shared/booking.service';
import { CustomerService } from 'src/app/core/shared/customer.service';
import { ExtensionService } from 'src/app/core/shared/extension.service';

@Component({
  templateUrl: './vouchers-modal.component.html',
  styleUrls: ['./vouchers-modal.component.scss'],
})
export class VouchersModalComponent implements OnInit {
  @Input() vouchers: Array<any> = [];
  @Input() currentVouchers: Array<string> = [];
  @Input() totalPrice: number;
  @Input() validationService: BookingService | ExtensionService;

  LINKS = LINKS;

  selectedVouchers: Array<any>;

  valueMap = {};
  values: any[] = [];
  selectionDirty: boolean = false;
  costsCovered: boolean = false;
  maxVoucherAmount: number = 4;

  removedVoucherCodes: string[] = [];

  voucherForm: UntypedFormGroup;
  constructor(
    private fb: UntypedFormBuilder,
    private modalController: ModalController,
    private loadingController: LoadingController,
    private alertController: AlertController,
  ) {
    this.voucherForm = this.fb.group({
      voucherCode: ['', Validators.required],
    });
  }

  ngOnInit(): void {
    if (this.currentVouchers) {
      this.currentVouchers.forEach(
        voucherCode => (this.valueMap[voucherCode] = true),
      );
      Object.keys(this.valueMap).forEach(voucherCode => {
        const voucher = this.vouchers.find(
          voucher => voucher.code === voucherCode,
        );
        if (voucher !== undefined) {
          this.values.push(voucher);
        } else {
          this.maxVoucherAmount--;
        }
      });
    }
  }

  dismiss(): void {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  updateValue(value) {
    this.selectionDirty = true;
    if (this.values.includes(value)) {
      this.removedVoucherCodes.push(value.code);
      this.values = this.values.filter(f => f.code != value.code);
    } else {
      this.removedVoucherCodes = this.removedVoucherCodes.filter(
        code => code != value.code,
      );
      this.values.push(value);
    }
    this.updateValueMap();
    const voucherSum = this.values.reduce(
      (prev, cur) => prev + parseFloat(cur.balance),
      0,
    );
    if (voucherSum > this.totalPrice) {
      this.costsCovered = true;
    } else {
      this.costsCovered = false;
    }
  }

  updateValueMap() {
    this.valueMap = this.values.reduce((map, val) => {
      map[val.code] = true;
      return map;
    }, {});
  }

  async confirmSelection() {
    const loading = await this.loadingController.create({});
    await loading.present();
    const voucherCodes = this.values.map(v => v.code);
    await this.validationService.removeVouchers(this.removedVoucherCodes);
    await this.validationService.addVouchers(voucherCodes);
    await loading.dismiss();
    this.dismiss();
  }

  async addVoucher() {
    const loading = await this.loadingController.create({});
    await loading.present();

    const voucherCode = this.voucherForm.get('voucherCode').value;
    const voucherValid = await this.validationService.addVoucher(voucherCode);
    await loading.dismiss();

    if (voucherValid) {
      this.dismiss();
      return;
    }
    const alert = await this.alertController.create({
      cssClass: 'myw-alert',
      header: 'Achtung',
      message: 'Der eingegebene Gutscheincode ist leider nicht gültig.',
      buttons: ['OK'],
    });

    await alert.present();
  }
}
