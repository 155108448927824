import { ErrorHandler, Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController } from '@ionic/angular';
import { BookingError } from './Errors';
import { BookingService } from './shared/booking.service';

@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  constructor(
    private alertController: AlertController,
    private bookingService: BookingService,
    private router: Router,
    private zone: NgZone
  ) {
    super();
  }

  async handleError(error: any): Promise<void> {
    //unwrap rejection errors
    if (error.rejection) {
      error = error.rejection;
    }
    try {
      const message = [
        `Name: ${error.name || ''}`,
        `Line: ${error.line || ''}`,
        `FileName: ${error.fileName || ''}`,
        `LineNumber: ${error.lineNumber || ''}`,
        `ColumnNumber: ${error.columnNumber || ''}`,
        '',
        `Message: ${error.message || ''}`,
        '',
        `Stack: ${error.stack || ''}`
      ].join('\n');

    } catch (e) {
      // ignore
    } finally {
      if (error instanceof BookingError) {
        this.bookingService.destroy();
      }

      const alert = await this.alertController.create({
        cssClass: 'myw-alert',
        backdropDismiss: false,
        header: 'Fehler',
        message: `Ein Fehler ist aufgetreten: ${error.name}`,
        buttons: [
          {
            text: 'OK',
            handler: () =>
              alert.dismiss().then(() => {
                this.zone.run(() => {
                  this.router.navigate(['/start']);
                });
              })
          }
        ]
      });

      alert.present();
    }

    super.handleError(error);
  }
}
