import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, inject } from '@angular/core';
import { AbstractControl, FormBuilder, Validators } from '@angular/forms';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { Utils } from 'src/app/common/utils';
import { Config } from 'src/app/config/config';
import { Customer } from 'src/app/core/api/model/customer.model';
import { ServerResponse } from 'src/app/core/api/model/response.model';
import { CustomerService } from 'src/app/core/shared/customer.service';
import { LoyaltySuccessSignupModalComponent } from '../loyalty-success-signup-modal/loyalty-success-signup-modal.component';
import { LINKS } from 'src/app/core/constants/links';

const BACKEND_EMAIL_ERROR_KEYS: any = {
  INVALID: 'isInvalid',
  EXISTING: 'isExisting',
};

const MESSAGES: any = {
  SUCCESS:
    'Dein Kundenkonto wurde erfolgreich angelegt. Jetzt musst Du nur noch auf den \n' +
    'Bestätigungslink in unserer Aktivierungsmail klicken und schon kannst Du dich bei uns anmelden.',
  EMAIL_EXISTS:
    'Leider wird diese E-Mail-Adresse bereits von einem anderen Kundenkonto\n' +
    'verwendet.',
  EMAIL_INVALID: 'Deine eingegebene E-Mail-Adresse hat ein falsches Format.',
};

@Component({
  selector: 'app-loyalty-request-modal',
  templateUrl: './loyalty-request-modal.component.html',
  styleUrls: ['./loyalty-request-modal.component.scss'],
})
export class LoyaltyRequestModalComponent implements OnInit {
  isLoyaltyMember: boolean = false;
  customer: Customer;
  public salutationOptions = [
    { value: 'Herr', label: 'Herr' },
    { value: 'Frau', label: 'Frau' },
    { value: 'Divers', label: 'Divers' },
  ];
  readonly maxDate = new Date();

  LINKS = LINKS;

  private date = inject(DatePipe);

  constructor(
    private customerService: CustomerService,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
  ) {
    customerService.customer$.subscribe(customer => (this.customer = customer));
  }

  private fb = inject(FormBuilder);

  readonly form = this.fb.group({
    salutation: ['', Validators.required],
    firstname: ['', Validators.required],
    lastname: ['', Validators.required],
    telephone: ['', Validators.required],
    email: [
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(Config.REGEX_EMAIL),
      ]),
    ],
    date_of_birth: [
      '',
      [Validators.required, Validators.pattern(/^\d{2}\.\d{2}\.\d{4}$/)],
    ],
    street: ['', Validators.required],
    house_number: ['', Validators.required],
    postcode: ['', Validators.required],
    city: ['', Validators.required],
    is_loyalty_member: [true],
  });

  public toggleLoyalty() {
    this.isLoyaltyMember = !this.isLoyaltyMember;
  }

  dismiss() {
    return this.modalCtrl.dismiss();
  }

  ngOnInit(): void {
    this.form.patchValue({
      salutation: this.customer.salutation,
      firstname: this.customer.firstname,
      lastname: this.customer.lastname,
      telephone: this.customer.telephone,
      email: this.customer.email,
      date_of_birth: this.date.transform(
        this.customer.date_of_birth,
        'dd.MM.yyyy',
      ),
      street: this.customer.street,
      house_number: this.customer.house_number,
      postcode: this.customer.postcode,
      city: this.customer.city,
    });
  }

  private async requestLoyalty(data: Partial<Customer>) {
    const loading = await this.loadingCtrl.create();
    await loading.present();
    this.customerService.updateCustomer(data).subscribe({
      next: async value => {
        await this.dismiss();
        const modalRC = await this.modalCtrl.create({
          backdropDismiss: false,
          component: LoyaltySuccessSignupModalComponent,
          cssClass: 'auto-height myw-request-loyalty-modal',
        });
        loading.dismiss();
        await modalRC.present();
      },
      error: async error => {
        loading.dismiss();
        let message: string;
        const emailControl: AbstractControl = this.form.get('email');
        const emailErrors: any = emailControl.errors || {};
        const response: HttpErrorResponse = error as HttpErrorResponse;

        if (response.status === 400) {
          const serverResponse: ServerResponse = response.error;
          if (Utils.exists(serverResponse.email)) {
            message = MESSAGES.EMAIL_INVALID;

            // Give email error to form
            (emailErrors[BACKEND_EMAIL_ERROR_KEYS.INVALID] = true),
              emailControl.setErrors(emailErrors);
          } else {
            message = MESSAGES.EMAIL_EXISTS;

            // Give email error to form
            (emailErrors[BACKEND_EMAIL_ERROR_KEYS.EXISTING] = true),
              emailControl.setErrors(emailErrors);
          }
        } else {
          message = Config.SERVER_UNREACHABLE;
        }

        const alert = await this.alertCtrl.create({
          cssClass: 'myw-alert',
          backdropDismiss: false,
          header: 'Fehler',
          message,
          buttons: ['OK'],
        });

        await alert.present();
      },
    });
  }

  onSubmit() {
    const formatedDate = this.form.value.date_of_birth
      .split('.')
      .reverse()
      .join('-');

    const userData: Partial<Customer> = {
      salutation: this.form.value.salutation,
      firstname: this.form.value.firstname,
      lastname: this.form.value.lastname,
      date_of_birth: formatedDate,
      email: this.form.value.email,
      telephone: this.form.value.telephone,
      street: this.form.value.street,
      house_number: this.form.value.house_number,
      postcode: this.form.value.postcode,
      city: this.form.value.city,
      is_loyalty_member: this.form.value.is_loyalty_member,
    };

    this.requestLoyalty(userData);
  }
}
