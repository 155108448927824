// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  padding: 32px 16px 16px;
}
@media screen and (min-width: 768px) {
  ion-card {
    padding: 64px 32px 32px;
  }
}

.title {
  font-family: "Cera Pro Medium";
  font-size: 22px;
  font-weight: bold;
  letter-spacing: 0.2em;
  line-height: 30px;
}

p {
  margin: 16px 0;
}

app-star-rating {
  font-size: 32px;
}
@media screen and (min-width: 768px) {
  app-star-rating {
    font-size: 48px;
  }
}

ion-textarea {
  --background: var(--myw-color-sand);
  --padding-start: 16px;
  --padding-end: 16px;
}

.button-container {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-width: 100%;
  gap: 16px;
}
@media screen and (min-width: 768px) {
  .button-container {
    flex-direction: row;
  }
}
.button-container ion-button {
  min-width: calc(50% - 8px);
}`, "",{"version":3,"sources":["webpack://./src/app/components/booking-feedback/booking-feedback.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;AACF;AAAE;EAFF;IAGI,uBAAA;EAGF;AACF;;AAAA;EACE,8BAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EACA,iBAAA;AAGF;;AAAA;EACE,cAAA;AAGF;;AAAA;EACE,eAAA;AAGF;AAFE;EAFF;IAGI,eAAA;EAKF;AACF;;AAFA;EACE,mCAAA;EACA,qBAAA;EACA,mBAAA;AAKF;;AAFA;EACE,gBAAA;EACA,aAAA;EACA,sBAAA;EACA,8BAAA;EACA,eAAA;EACA,SAAA;AAKF;AAHE;EARF;IASI,mBAAA;EAMF;AACF;AAJE;EACE,0BAAA;AAMJ","sourcesContent":["ion-card {\n  padding: 32px 16px 16px;\n  @media screen and (min-width: 768px) {\n    padding: 64px 32px 32px;\n  }\n}\n\n.title {\n  font-family: 'Cera Pro Medium';\n  font-size: 22px;\n  font-weight: bold;\n  letter-spacing: 0.2em;\n  line-height: 30px;\n}\n\np {\n  margin: 16px 0;\n}\n\napp-star-rating {\n  font-size: 32px;\n  @media screen and (min-width: 768px) {\n    font-size: 48px;\n  }\n}\n\nion-textarea {\n  --background: var(--myw-color-sand);\n  --padding-start: 16px;\n  --padding-end: 16px;\n}\n\n.button-container {\n  margin-top: 16px;\n  display: flex;\n  flex-direction: column;\n  justify-content: space-between;\n  min-width: 100%;\n  gap: 16px;\n\n  @media screen and (min-width: 768px) {\n    flex-direction: row;\n  }\n\n  ion-button {\n    min-width: calc(50% - 8px);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
