import { Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { take } from 'rxjs/operators';
import { Utils } from 'src/app/common/utils';
import { Customer } from 'src/app/core/api/model/customer.model';
import { LINKS } from 'src/app/core/constants/links';
import { CustomerService } from 'src/app/core/shared/customer.service';

@Component({
  selector: 'app-user-menu-popover',
  templateUrl: './user-menu-popover.component.html',
  styleUrls: ['./user-menu-popover.component.scss'],
})
export class UserMenuPopoverComponent {
  public profile: Customer;

  LINKS = LINKS;

  constructor(
    private modalController: ModalController,
    private navCtrl: NavController,
    private customerService: CustomerService,
  ) {
    this.customerService.customer$
      .pipe(take(1))
      .subscribe((profile: Customer) => {
        if (Utils.exists(profile)) {
          this.profile = profile;
        }
      });
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  async logout() {
    this.dismiss();
    await this.navCtrl.navigateRoot('booking');
    this.customerService.logout();
  }

  // public async navigateTo(route: string) {
  //   this.dismiss();
  //   await this.navCtrl.navigateForward(route);
  // }
}
