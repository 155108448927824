// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-item {
  margin: 2px 0;
}

ion-list {
  background: transparent;
}

ion-label {
  white-space: normal !important;
}

ion-card {
  padding: 35px;
  overflow: visible;
}
ion-card.myw-bm .myw-bm-wrapper .myw-bm-btn {
  margin-bottom: 30px;
}
ion-card.myw-bm .myw-bm-image {
  width: 100%;
  height: 32vh;
  background: rgba(0, 0, 0, 0.05);
  background-size: cover;
}
ion-card.myw-bm .myw-mb-buttons {
  margin-bottom: 30px;
}
ion-card ion-fab {
  top: -15px;
  right: -15px;
}

.myw-register-divider {
  margin: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/register/forgotpw/forgotpw.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;AACF;;AAEA;EACE,uBAAA;AACF;;AAEA;EACE,8BAAA;AACF;;AAKE;EACE,aAAA;EACA,iBAAA;AAFJ;AASQ;EACE,mBAAA;AAPV;AAUM;EACE,WAAA;EACA,YAAA;EACA,+BAAA;EACA,sBAAA;AARR;AAUM;EACE,mBAAA;AARR;AAYI;EACE,UAAA;EACA,YAAA;AAVN;;AAgBA;EACE,YAAA;AAbF","sourcesContent":["ion-item {\n  margin: 2px 0;\n}\n\nion-list {\n  background: transparent;\n}\n\nion-label {\n  white-space: normal !important;\n}\n\n// ion-content {\n//   --background: transparent;\n//   background: none center center / cover no-repeat;\n  ion-card {\n    padding: 35px;\n    overflow: visible;\n    // height: auto;\n    // min-height: 0;\n    // background: rgb(255 255 255 / 0.85) !important;\n    // margin: calc(var(--ion-safe-area-top, 0) + 32px) 32px 32px;\n    &.myw-bm {\n      .myw-bm-wrapper {\n        .myw-bm-btn {\n          margin-bottom: 30px;\n        }\n      }\n      .myw-bm-image {\n        width: 100%;\n        height: 32vh;\n        background: rgba($color: #000000, $alpha: 0.05);\n        background-size: cover;\n      }\n      .myw-mb-buttons {\n        margin-bottom: 30px;\n      }\n    }\n\n    ion-fab {\n      top: -15px;\n      right: -15px;\n    }\n\n  }\n// }\n\n.myw-register-divider {\n  margin: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
