// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.close-button {
  position: fixed;
  height: 35px;
  width: 35px;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

app-close-button {
  background-color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/components/close-button/close-button.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,YAAA;EACA,WAAA;EAEA,SAAA;EACA,WAAA;EAEA,eAAA;AADF;;AAIA;EACE,qBAAA;AADF","sourcesContent":[".close-button {\n  position: fixed;\n  height: 35px;\n  width: 35px;\n\n  top: 30px;\n  right: 30px;\n\n  cursor: pointer;\n}\n\napp-close-button {\n  background-color: red;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
