import { Injectable } from '@angular/core';
import { Adapter } from '../common/adapter.interface';

export class PaymentMethod {
  constructor(
    public name: string,
    public type: string,
    public iconUrl: string,
    public isDefault: boolean
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class PaymentMethodAdapter implements Adapter<PaymentMethod> {
  adapt(item: any): PaymentMethod {
    return new PaymentMethod(item.name, item.type, item.url, item.is_default);
  }
}
