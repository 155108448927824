import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { PreferenceService } from 'src/app/common/provider/preference.service';

@Injectable({
  providedIn: 'root'
})
export class BookingProgressService {

  private progressSource = new BehaviorSubject<boolean[]>([false, false, false, false]);
  currentProgress = this.progressSource.asObservable();

  constructor(private preferences: PreferenceService) {
    this.init();
  }
  
  async init() {
    this.progressSource.next(await this.preferences.getProgress());
   }

  changeProgress(message: number) {
    let progress = [false,false,false,false];
    for(let i = 0; i < progress.length; i++) {
      i < message ? progress[i] = true : progress[i] = false
    }
    this.preferences.setProgress(progress);
    this.progressSource.next(progress);
  }
}
