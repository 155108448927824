import { createAnimation } from '@ionic/angular';

const getIonPageElement = (element: HTMLElement) =>
  element.classList.contains('ion-page')
    ? element
    : element.querySelector(
        ':scope > .ion-page, :scope > ion-nav, :scope > ion-tabs'
      ) || element;

export default function pageTransition(
  _: HTMLElement,
  options: {
    duration: number;
    direction: 'forward' | 'backward';
    enteringEl: HTMLElement;
    leavingEl: HTMLElement;
  }
) {
  const DURATION = 300;

  const rootTransition = createAnimation()
    .duration(options.duration || DURATION)
    .easing('cubic-bezier(0.3,0,0.66,1)');

  const enteringPage = createAnimation()
    .addElement(getIonPageElement(options.enteringEl))
    .beforeRemoveClass('ion-page-invisible');

  const leavingPage = createAnimation().addElement(
    getIonPageElement(options.leavingEl)
  );

  if (options.direction === 'forward') {
    enteringPage.fromTo('transform', 'translateX(100%)', 'translateX(0)');
    leavingPage.fromTo('opacity', '1', '0.25');
  } else {
    leavingPage.fromTo('transform', 'translateX(0)', 'translateX(100%)');
    enteringPage.fromTo('opacity', '0.25', '1');
  }

  rootTransition.addAnimation(enteringPage);
  rootTransition.addAnimation(leavingPage);
  return rootTransition;
}
