import { Adapter } from '../common/adapter.interface';
import { Injectable } from '@angular/core';

export class ServerResponse {
  constructor(
    public messageStatus: string,
    public messageCode: string,
    public messageDescription: string,
    /**
     * TODO:
     * This property is returned by a POST to /customer when e-mail has wrong format.
     * See: https://mcwellness.api-docs.io/v1/customers/new-customer-registration
     * It can be removed, if the API is updated to use the other 3 properties instead.
     */
    public email: string[]
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class ServerResponseAdapter implements Adapter<ServerResponse> {
  adapt(item: any): ServerResponse {
    return new ServerResponse(
      item.messageStatus,
      item.messageCode,
      item.messageDescription,
      item.email
    );
  }
}
