import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LINKS } from 'src/app/core/constants/links';

@Component({
  selector: 'app-loyalty-info-modal',
  templateUrl: './loyalty-info-modal.component.html',
  styleUrls: ['./loyalty-info-modal.component.scss'],
})
export class LoyaltyInfoModalComponent {
  @Input() pointValue: number = 0;

  LINKS = LINKS;

  constructor(private modalController: ModalController) {}

  dismiss() {
    this.modalController.dismiss();
  }
}
