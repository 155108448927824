import { Injectable } from '@angular/core';
import { Adapter } from '../common/adapter.interface';

interface Inclusion {
  name: string;
}

interface Option {
  value: number;
  label: string;
  quantity: number;
}

export class SpaPackage {
  constructor(
    public package_id: number,
    public name: string,
    public description: string,
    public image: string,
    public info_headline: string,
    public info_text: string,
    public info_image: string,
    public price: number,
    public save_amount: number,
    public options: Array<Option>,
    public inclusions: Array<Inclusion>,
    public article_value?: number,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class SpaPackageAdapter implements Adapter<SpaPackage> {
  adapt(item: any): SpaPackage {
    return new SpaPackage(
      item.package_id,
      item.name,
      item.description,
      item.image,
      item.info_headline,
      item.info_description
        ? item.info_description.replace(/\\r\\n/, '<br><br>')
        : '',
      item.info_image,
      +item.price,
      +item.save_amount,
      item.options.map(option => ({
        value: option.value,
        label: option.label,
        quantity: option.quantity,
      })),
      item.inclusions.map(inclusion => ({
        name: inclusion.name,
      })),
      item.item_value,
    );
  }
}
