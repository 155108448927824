export const LINKS = {
  home: 'https://www.mywellness.de/',
  shop: 'https://www.mywellness.de/shop/',
  loyalty: 'https://www.mywellness.de/friend/',
  loyalty_faq: 'https://mywellness.de/friend#faq-friend',
  loyalty_conditions: 'https://mywellness.de/friend-teilnahmebedingungen/',
  imprint: 'https://www.mywellness.de/impressum/',
  privacy_policy: 'https://mywellness.de/datenschutzerklaerung/',
  terms_and_conditions: 'https://mywellness.de/agb/',
  contact: 'https://www.mywellness.de/kontakt/',
  shop_orders: 'https://mywellness.de/mein-konto/orders/',
  shop_my_address: 'https://mywellness.de/mein-konto/edit-address/',
  hygene: 'https://mywellness.de/covid-19-faq/',
  help: 'https://mywellness.de/hilfe/',
  help_cancel: 'https://mywellness.de/hilfe/#stornieren',
  help_extend: 'https://mywellness.de/hilfe/#verlaengern',
} as const;
