// const LOYALTY_STATUS = {
//   NO_MEMBER: 'NO_MEMBER',
//   REQUESTED: 'REQUESTED',
//   MEMBER: 'MEMBER',
// } as const;

// type LoyaltyStatus = keyof typeof LOYALTY_STATUS;

export enum LoyaltyStatus {
  NotMember = 'NOT_MEMBER',
  Pending = 'PENDING',
  Member = 'MEMBER',
}
