import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../../environments/environment';
import { PreferenceService } from '../../../common/provider/preference.service';

@Injectable({
  providedIn: 'root'
})
export class ConfigController {
  private baseUrl = `${environment.apiUrl}`;

  constructor(
    private http: HttpClient,
    private preferences: PreferenceService
  ) {
    this.preferences.getTestAccount().then((testAccount) => {
      if (testAccount === true) {
        // eslint-disable-next-line no-console
        console.log("ConfigController: IS TEST");
        this.useTestingEnv(true);
      } else {
        // eslint-disable-next-line no-console
        console.log("ConfigController: IS NO TEST");
        this.useTestingEnv(false);
      }  
    });   
  }

  getConfig(): any {
    const url = `${this.baseUrl}/get-config`;
    return this.http.get(url);
  }

  useTestingEnv(useTesting) {
    this.baseUrl = `${
      useTesting ? environment.approvalApiUrl : environment.apiUrl
    }`;
  }
}
