// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  --padding-inline: 32px;
  --padding-block: 32px;
}

.accordion {
  margin-bottom: 20px;
  background-color: #F6F4F3;
}
.accordion.accordion-expanded .accordion__header .accordion__header__icon ion-icon, .accordion.accordion-expanding .accordion__header .accordion__header__icon ion-icon {
  transform: rotate(180deg);
}
.accordion__header {
  background-color: #edeae7;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.accordion__header:hover {
  cursor: pointer;
}
.accordion__header__title {
  margin-left: 30px;
  font-family: "Cera Pro Medium";
  font-size: 15px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
}
.accordion__header__icon {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 60px;
  background-color: #dfd2c9;
}
.accordion__header__icon ion-icon {
  font-size: 15px;
  color: #f1edea;
  transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);
}
.accordion .content {
  padding: var(--padding-block) var(--padding-inline);
}`, "",{"version":3,"sources":["webpack://./src/app/components/accordion/accordion.component.scss"],"names":[],"mappings":"AAGA;EACE,sBAAA;EACA,qBAAA;AAFF;;AAKA;EACE,mBAAA;EACA,yBAAA;AAFF;AAQc;EACI,yBAAA;AANlB;AAYI;EACE,yBAAA;EACF,aAAA;EACA,8BAAA;EACA,mBAAA;AAVJ;AAYI;EACE,eAAA;AAVN;AAaI;EACE,iBAAA;EACA,8BAAA;EACA,eAAA;EACA,qBAAA;EACA,yBAAA;AAXN;AAcI;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;EAEA,YAAA;EACA,WAAA;EACA,yBAAA;AAbN;AAeM;EACE,eAAA;EACA,cAAA;EACA,0DAAA;AAbR;AAkBE;EACE,mDAAA;AAhBJ","sourcesContent":["@import 'src/theme/app/core/mixins.scss';\n\n\n:host {\n  --padding-inline: 32px;\n  --padding-block: 32px;\n}\n\n.accordion {\n  margin-bottom: 20px;\n  background-color: #F6F4F3;\n  \n  &.accordion-expanded,\n  &.accordion-expanding {\n      .accordion__header {\n          .accordion__header__icon {\n              ion-icon {\n                  transform: rotate(180deg);\n                }\n            }\n        }\n    }\n    \n    &__header {\n      background-color: #edeae7;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n\n    &:hover {\n      cursor: pointer;\n    }\n\n    &__title {\n      margin-left: 30px;\n      font-family: 'Cera Pro Medium';\n      font-size: 15px;\n      letter-spacing: 1.5px;\n      text-transform: uppercase;\n    }\n\n    &__icon {\n      display: flex;\n      justify-content: center;\n      align-items: center;\n\n      height: 60px;\n      width: 60px;\n      background-color: #dfd2c9;\n\n      ion-icon {\n        font-size: 15px;\n        color: #f1edea;\n        transition: transform 0.3s cubic-bezier(0.25, 0.8, 0.5, 1);\n      }\n    }\n  }\n\n  .content {\n    padding: var(--padding-block) var(--padding-inline);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
