// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `app-accordion {
  --padding-block: 32px;
  --padding-inline: 32px;
}
@media (min-width: 768px) {
  app-accordion {
    --padding-block: 32px;
    --padding-inline: 50px;
  }
}

.quick-info-item {
  display: flex;
  text-align: start;
  border-bottom: 1px dashed #baaa9f;
}
.quick-info-item span {
  font-size: 14px;
  letter-spacing: 0.024em;
  margin-block: 10px;
}
.quick-info-item span.bold {
  font-weight: bold;
}

.faq-links {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-top: 20px;
  font-weight: bold;
  gap: 16px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/loyalty/quick-info/quick-info.component.scss","webpack://./src/theme/app/core/mixins.scss"],"names":[],"mappings":"AAEA;EACE,qBAAA;EACA,sBAAA;AADF;ACaI;EDdJ;IAII,qBAAA;IACA,sBAAA;EACF;AACF;;AAIA;EACE,aAAA;EACA,iBAAA;EACA,iCAAA;AADF;AAGE;EACE,eAAA;EACA,uBAAA;EACA,kBAAA;AADJ;AAEI;EACE,iBAAA;AAAN;;AAKA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,gBAAA;EACA,iBAAA;EACA,SAAA;AAFF","sourcesContent":["@import 'src/theme/app/core/mixins.scss';\n\napp-accordion {\n  --padding-block: 32px;\n  --padding-inline: 32px;\n  @include media-breakpoint-up(md) {\n    --padding-block: 32px;\n    --padding-inline: 50px;\n  }\n}\n\n$border: #baaa9f;\n\n.quick-info-item {\n  display: flex;\n  text-align: start;\n  border-bottom: 1px dashed $border;\n\n  span {\n    font-size: 14px;\n    letter-spacing: 0.024em;\n    margin-block: 10px;\n    &.bold {\n      font-weight: bold;\n    }\n  }\n}\n\n.faq-links {\n  display: flex;\n  flex-direction: column;\n  align-items: flex-start;\n  margin-top: 20px;\n  font-weight: bold;\n  gap: 16px;\n}\n","@mixin checkCursor() {\n  @media (hover: hover) and (pointer: fine) {\n    @content;\n  }\n}\n\n$breakpoints: (\n  'default': '',\n  's': 576px,\n  'md': 768px,\n  'l': 992px,\n  'lg': 1200px,\n  'xl': 1400px,\n);\n@mixin media-breakpoint-up($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (min-width: #{map-get($breakpoints, $breakpoint)}) {\n      @content;\n    }\n  } @else if unitless($breakpoint) {\n    $breakpoint: $breakpoint + 0px;\n    @media (min-width: $breakpoint) {\n      @content;\n    }\n  }\n}\n@mixin media-breakpoint-down($breakpoint) {\n  @if map-has-key($breakpoints, $breakpoint) {\n    @media (max-width: #{map-get($breakpoints, $breakpoint)}) {\n      @content;\n    }\n  } @else if unitless($breakpoint) {\n    $breakpoint: $breakpoint + 0px;\n    @media (max-width: $breakpoint) {\n      @content;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
