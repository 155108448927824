import { HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { Config } from '../../../config/config';
import { CustomerService } from '../../../core/shared/customer.service';
import { LoginPopoverComponent } from 'src/app/features/user-menu/components/login-popover/login-popover.component';

const MESSAGES: any = {
  SUCCESS:
    'Du erhältst in Kürze eine E-Mail mit einem Link, um ein neues Passwort einzurichten.',
  EMAIL_NOT_EXISTS:
    'Leider konnten wir Dein Passwort nicht wiederherstellen. Bitte wende Dich an unseren Service.',
};

@Component({
  selector: 'app-register-forgotpw',
  templateUrl: './forgotpw.component.html',
  styleUrls: ['./forgotpw.component.scss'],
})
export class PasswordResetComponent {
  public emailControl: UntypedFormControl;
  constructor(
    private modalController: ModalController,
    formBuilder: UntypedFormBuilder,
    private customerService: CustomerService,
    private analytics: AngularFireAnalytics,
    private loadingCtrl: LoadingController,
    private alertController: AlertController,
  ) {
    this.emailControl = formBuilder.control(
      '',
      Validators.compose([
        Validators.required,
        Validators.pattern(Config.REGEX_EMAIL),
      ]),
    );
  }
  public dismiss() {
    this.modalController.dismiss({
      dismissed: true,
    });
  }

  public async resetPassword(): Promise<void> {
    this.dismiss();

    const loader = await this.loadingCtrl.create();
    await loader.present();

    try {
      this.analytics.logEvent('pwforgot_done');

      await this.customerService.resetPassword(this.emailControl.value);
      const alert = await this.alertController.create({
        cssClass: 'myw-alert',
        backdropDismiss: false,
        message: MESSAGES.SUCCESS,
        buttons: [
          {
            text: 'OK',
            // handler: () => alert.dismiss().then(() => this.showLogin())
          },
        ],
      });

      await alert.present();
    } catch (error) {
      let message: string = MESSAGES.EMAIL_NOT_EXISTS;
      const response: HttpErrorResponse = error as HttpErrorResponse;

      if (
        response.status === 400 &&
        response.error.messageCode === 'EMAIL_ADDRESS_DOES_NOT_EXISTS'
      ) {
        this.analytics.logEvent('pwforgot_error_mail_does_not_exists', {
          code: response.error.messageCode,
        });
      } else {
        this.analytics.logEvent('pwforgot_error', {
          status: response.status,
          statusText: response.statusText,
        });

        message = Config.SERVER_UNREACHABLE;
      }

      const alert = await this.alertController.create({
        cssClass: 'myw-alert',
        backdropDismiss: false,
        header: 'Fehler',
        message,
        buttons: ['OK'],
      });

      await alert.present();
    } finally {
      await loader.dismiss();
    }
  }
}
