import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { LINKS } from 'src/app/core/constants/links';

@Component({
  selector: 'app-redeem-points-modal',
  templateUrl: './redeem-points-modal.component.html',
  styleUrls: ['./redeem-points-modal.component.scss'],
})
export class RedeemPointsModalComponent implements OnInit {
  @Input() pointValue: number = 0;
  @Input() totalPoints: number = 0;
  @Input() maxPoints: number = 0;
  @Input() redeemedPoints: number = 0;

  LINKS = LINKS;

  pointsForm: FormGroup = this.fb.group({
    redeemedPoints: [
      this.redeemedPoints || 0,
      Validators.compose([Validators.required, Validators.min(0)]),
    ],
  });

  constructor(
    private modalController: ModalController,
    private fb: FormBuilder,
  ) {}

  ngOnInit() {
    this.pointsForm
      .get('redeemedPoints')
      .addValidators([Validators.max(this.maxPoints)]);
    this.pointsForm.get('redeemedPoints').setValue(this.redeemedPoints || null);

    const maxPoints =
      this.maxPoints > this.totalPoints ? this.totalPoints : this.maxPoints;

    this.pointsForm.get('redeemedPoints').valueChanges.subscribe(value => {
      if (value > maxPoints) {
        this.pointsForm.get('redeemedPoints').setValue(maxPoints);
      }

      if (value < 0) {
        this.pointsForm.get('redeemedPoints').setValue(0);
      }
    });
  }

  dismiss() {
    this.modalController.dismiss({}, 'cancel');
  }

  confirm() {
    this.modalController.dismiss(
      {
        pointsToRedeem: this.pointsForm.get('redeemedPoints').value,
      },
      'submit',
    );
  }
}
