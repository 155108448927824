// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-card {
  padding: 72px 24px 24px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
}

ion-icon {
  font-size: 42px;
}

h4 {
  font-family: "Cera Pro Medium";
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.2em;
}

p {
  font-family: "Cera Pro Regular";
  font-size: 15px;
  font-weight: normal;
  line-height: 22px;
  letter-spacing: 0.1em;
}`, "",{"version":3,"sources":["webpack://./src/app/components/alert-modal/alert-modal.component.scss"],"names":[],"mappings":"AAAA;EACI,uBAAA;EACA,2CAAA;AACJ;;AAEA;EACI,eAAA;AACJ;;AAEA;EACI,8BAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;AACJ;;AAEA;EACI,+BAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;EACA,qBAAA;AACJ","sourcesContent":["ion-card {\n    padding: 72px 24px 24px;\n    box-shadow: 0px 3px 6px rgba($color: #000000, $alpha: 0.16);\n}\n\nion-icon {\n    font-size: 42px;\n}\n\nh4 {\n    font-family: \"Cera Pro Medium\";\n    font-size: 22px;\n    font-weight: bold;\n    line-height: 30px;\n    letter-spacing: 0.2em;\n}\n\np {\n    font-family: \"Cera Pro Regular\";\n    font-size: 15px;\n    font-weight: normal;\n    line-height: 22px;\n    letter-spacing: 0.1em;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
