// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  padding: 64px 32px 32px;
  text-align: center;
}
.modal__icon {
  margin-bottom: 32px;
  font-size: 32px;
}
.modal__message {
  margin-bottom: 32px;
  font-family: "Cera Pro Medium";
  font-size: 18px;
  letter-spacing: 0.2em;
  line-height: 30px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/success-modal/success-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,uBAAA;EACA,kBAAA;AACF;AACE;EACE,mBAAA;EACA,eAAA;AACJ;AAEE;EACE,mBAAA;EACA,8BAAA;EACA,eAAA;EACA,qBAAA;EACA,iBAAA;AAAJ","sourcesContent":[".modal {\n  padding: 64px 32px 32px;\n  text-align: center;\n\n  &__icon {\n    margin-bottom: 32px;\n    font-size: 32px;\n  }\n\n  &__message {\n    margin-bottom: 32px;\n    font-family: 'Cera Pro Medium';\n    font-size: 18px;\n    letter-spacing: 0.2em;\n    line-height: 30px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
