import { DatePipe } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { CookieService } from 'ngx-cookie-service';
import { BehaviorSubject, Observable } from 'rxjs';
import { map, take, tap } from 'rxjs/operators';
import { Logger } from '../../common/provider/logger.service';
import { PreferenceService } from '../../common/provider/preference.service';
import { AuthService } from '../api/auth.service';
import { CustomerController } from '../api/controller/customer.controller';
import { Customer } from '../api/model/customer.model';
import { LoyaltyEventAdapter } from '../api/model/loyaltyEvent.model';
import { LoyaltyTransactionAdapter } from '../api/model/loyaltyTransaction.model';
import { ServerResponse } from '../api/model/response.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerService {
  private customerState = new BehaviorSubject<Customer>(null);
  private profile: Promise<Customer>;
  public customer$ = this.customerState.asObservable();

  private log: Logger;

  private domain: string = 'mywellnes.de';

  private authSerivce = inject(AuthService);

  constructor(
    private customerController: CustomerController,
    private preferences: PreferenceService,
    private logger: Logger,
    private datePipe: DatePipe,
    private cookieService: CookieService,
    private analytics: AngularFireAnalytics,
    private loyaltyTransactionAdapter: LoyaltyTransactionAdapter,
    private loyaltyEventAdapter: LoyaltyEventAdapter,
  ) {
    this.log = this.logger.createLogger('CustomerService');
  }

  /**
   * check jwt token and load customer profile on app start
   */
  public async init() {
    this.log.debug('init');

    const token = this.authSerivce.getToken();

    if (token) {
      await this.fetchProfile();
      this.authSerivce.setToken(token);
    }
  }

  /**
   * Registers a new customer with the given data.
   *
   * @param customerData is the registration data provided by the user.
   */
  public async register(customerData: any): Promise<Customer> {
    customerData.registered_from = 1; // Hinweis, dass die Registrierung aus der online Buchungsstrecke kommt!

    return new Promise((resolve, reject) => {
      this.customerController.register(customerData).subscribe(
        async ({ token, customer }) => {
          if (token) {
            this.authSerivce.setToken(token);

            try {
              await this.fetchProfile();
              resolve(customer);
            } catch (error) {
              this.authSerivce.deleteToken();
              reject(error?.error?.messageCode || error);
            }
          }
        },
        error => {
          reject(error);
        },
        // (response: Customer) => resolve(),
        // (error: HttpErrorResponse) => reject(error)
      );
    });
  }

  /**
   * Activate an user account.
   *
   * @param activationToken
   */
  public activateAccount(activationToken): Promise<void> {
    return new Promise((resolve, reject) => {
      this.customerController.activateAccount(activationToken).subscribe(
        (response: ServerResponse) => resolve(),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  /**
   * Sends a request with the given e-mail to the backend for the purpose of resetting a password.
   *
   * @param email is the e-mail provided by the user whose corresponding password should be reset.
   */
  public async resetPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.customerController.resetPassword(email).subscribe(
        (response: ServerResponse) => resolve(),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  /**
   * authenticate user and save jwt token
   *
   * @param email {string}
   * @param password {string}
   */
  public async authenticate(
    email: string,
    password: string,
  ): Promise<Customer> {
    return new Promise((resolve, reject) => {
      this.customerController.authenticate(email, password).subscribe(
        async ({ token, customer }) => {
          if (token) {
            this.authSerivce.setToken(token);

            try {
              await this.fetchProfile();
              resolve(customer);
            } catch (error) {
              this.authSerivce.deleteToken();
              reject(error?.error?.messageCode || error);
            }
          }
        },
        error => {
          reject(error?.error?.messageCode || error);
        },
      );
    });
  }

  /**
   * retrieve and save customer profile
   */
  public fetchProfile(): Promise<Customer> {
    this.log.debug('fetchProfile');
    this.profile = new Promise<Customer>((resolve, reject) => {
      this.customerController
        .details()
        .pipe(take(1))
        .subscribe(
          async (customer: Customer) => {
            this.log.debug('fetchProfile: response', customer);
            this.customerState.next(customer);
            resolve(customer);
          },
          error => {
            this.log.error('fetchProfile: error', error);
            reject(error?.error?.messageCode || error);
          },
        );
    });
    return this.profile;
  }

  public getProfile(): Promise<Customer> {
    return this.profile || this.fetchProfile();
  }

  /**
   * update customer profile, save to storage, publish changes
   *
   * @param updatedCustomer is the new customer data together with an optional new password.
   */
  public async update(updatedCustomer: any): Promise<Customer> {
    this.log.debug('update');

    // cleanup updatedCustomer
    if (updatedCustomer.date_of_birth == '1970-01-01') {
      updatedCustomer.date_of_birth = null;
    }

    updatedCustomer.date_of_birth = this.datePipe.transform(
      Date.parse(updatedCustomer.date_of_birth),
      'yyyy-MM-dd',
    );

    if (updatedCustomer.new_password === '') {
      delete updatedCustomer.new_password;
      delete updatedCustomer.old_password;
    }
    delete updatedCustomer.has_new_password;
    delete updatedCustomer.new_password_repeat;

    for (let key in updatedCustomer) {
      if (updatedCustomer[key] === '') {
        updatedCustomer[key] = null;
      }
    }

    const existingData = await this.getProfile();
    const mergedData = {
      ...existingData,
      ...updatedCustomer,
      change_password:
        updatedCustomer.old_password && updatedCustomer.new_password,
    };

    return new Promise<Customer>((resolve, reject) => {
      this.customerController
        .update(mergedData)
        .pipe(take(1))
        .subscribe(
          async (_customer: Customer) => {
            this.log.debug('update: success');
            const customer = _customer;
            _customer.loyalty_points = existingData.loyalty_points;
            _customer.loyalty_group = existingData.loyalty_group;
            this.customerState.next(customer);
            resolve(customer);
          },
          (error: HttpErrorResponse) => {
            this.log.error('update: error', error);
            reject(error);
          },
        );
    });
  }

  public async resendConfirmationMail(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      this.customerController.resentConfirmationMail(email).subscribe(
        (response: ServerResponse) => resolve(),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  public getMyBookings(
    page: number = undefined,
    page_size: number = undefined,
  ): Observable<any> {
    return this.customerController.getMyBookings(page, page_size);
  }

  public async getBookingDetails(id: number | string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerController.getBookingDetails(id).subscribe(
        (response: any) => resolve(response),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  public getBookingDetailsNew(id: number | string) {
    return this.customerController.getBookingDetails(id);
  }

  public async getMyVouchers(
    page: number = undefined,
    page_size: number = undefined,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerController.getMyVouchers(page, page_size).subscribe(
        (response: any) => resolve(response),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  public async getAllVouchers(withBalance?: boolean): Promise<Array<any>> {
    return new Promise((resolve, reject) => {
      this.customerController.getAllVouchers(withBalance).subscribe(
        (response: any) => resolve(response),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  public async refreshVouchers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerController.refreshVouchers().subscribe(
        (response: any) => resolve(response),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  public async addVoucher(code: string): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerController.addVoucher(code).subscribe(
        (response: any) => resolve(response),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  updateCustomer(data: Partial<Customer>): Observable<Customer> {
    return this.customerController.updateCustomer(data).pipe(
      tap(res => {
        // From the response we want to delete all properties have a value of null
        // so that we can update the customer state with the new values
        Object.keys(res).forEach(key => {
          if (res[key] === null) {
            delete res[key];
          }
        });

        this.customerState.next({ ...this.customerState.value, ...res });
      }),
    );
  }

  updatePassword(
    old_password: string,
    new_password: string,
  ): Observable<Customer> {
    const currentCustomer = this.customerState.getValue();
    const requestData: {
      old_password: string;
      new_password: string;
    } & Partial<Customer> = {
      old_password,
      new_password,
      email: currentCustomer.email,
      firstname: currentCustomer.firstname,
      lastname: currentCustomer.lastname,
      date_of_birth: currentCustomer.date_of_birth,
      salutation: currentCustomer.salutation,
      street: currentCustomer.street,
      city: currentCustomer.city,
      postcode: currentCustomer.postcode,
      telephone: currentCustomer.telephone,
    };
    return this.customerController.updatePassword(requestData).pipe(
      tap(res => {
        if (res.upcomingBooking === null) {
          delete res.upcomingBooking;
        }
        this.customerState.next({ ...this.customerState.value, ...res });
      }),
    );
  }

  updateNewsletterSubscription(data: {
    is_subscribed?: boolean;
    is_loyalty_member?: boolean;
  }): Observable<Customer> {
    const currentCustomer = this.customerState.getValue();

    let requestData: {
      is_subscribed?: 0 | 1;
      is_loyalty_member?: 0 | 1;
    } = {};

    if (
      currentCustomer.is_subscribed === data.is_subscribed &&
      currentCustomer.is_loyalty_member === data.is_loyalty_member
    ) {
      return;
    }

    if (
      data.is_subscribed !== undefined &&
      currentCustomer.is_subscribed !== data.is_subscribed
    ) {
      requestData.is_subscribed = +data.is_subscribed ? 1 : 0;
    }

    if (
      data.is_loyalty_member !== undefined &&
      currentCustomer.is_loyalty_member !== data.is_loyalty_member
    ) {
      requestData.is_loyalty_member = data.is_loyalty_member ? 1 : 0;
    }

    return this.customerController
      .updateNewsletterSubscription(requestData)
      .pipe(
        tap(res => {
          if (res.upcomingBooking === null) {
            delete res.upcomingBooking;
          }
          this.customerState.next({ ...this.customerState.value, ...res });
        }),
      );
  }

  public async validatePasswordResetToken(
    email: string,
    token: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerController
        .validatePasswordResetToken(email, token)
        .subscribe(
          (response: any) => resolve(response),
          (error: HttpErrorResponse) => reject(error),
        );
    });
  }

  public async resetPasswordTo(
    email: string,
    token: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerController
        .resetPasswordTo(email, token, newPassword, newPasswordConfirmation)
        .subscribe(
          (response: any) => resolve(response),
          (error: HttpErrorResponse) => reject(error),
        );
    });
  }

  public async getBonusVouchers(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerController.getBonusVouchers().subscribe(
        (response: any) => resolve(response),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  public async getBookingConfirmation(bookingId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerController.getBookingConfirmation(bookingId).subscribe(
        (response: any) => resolve(response),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  public async getBookingInvoice(bookingId: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerController.getBookingInvoice(bookingId).subscribe(
        (response: any) => resolve(response),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  public async getOrderReceipt(bookingOd: number): Promise<any> {
    return new Promise((resolve, reject) => {
      this.customerController.getOrderReceipt(bookingOd).subscribe(
        (response: any) => resolve(response),
        (error: HttpErrorResponse) => reject(error),
      );
    });
  }

  deleteAccount() {
    return new Promise((resolve, reject) => {
      this.customerController.deleteAccount().subscribe(resolve, reject);
    });
  }

  public logout() {
    this.analytics.logEvent('logout');
    this.authSerivce.deleteToken();
    this.preferences.setTestAccount(null);
    this.customerState.next(null);
  }

  getLoyaltyHistory(page: number = undefined) {
    return this.customerController.getLoyaltyHistory(page).pipe(
      map((response: any) => ({
        paging: response.paging,
        transactions: response.transactions
          ? response.transactions.map((transaction: any) =>
              this.loyaltyTransactionAdapter.adapt({
                ...transaction,
              }),
            )
          : [],
      })),
    );
  }

  getLoyaltyEvents(page: number = undefined) {
    return this.customerController.getLoyaltyEvents(page).pipe(
      map((response: any) => ({
        paging: response.paging,
        events: response.programs.map((event: any) =>
          this.loyaltyEventAdapter.adapt({
            ...event,
          }),
        ),
      })),
    );
  }

  resendLoyaltyMail() {
    return this.customerController.resendLoyaltyMail();
  }

  confirmLoyaltySubscription(token: string) {
    return this.customerController.confirmLoyaltySubscription(token);
  }
}
