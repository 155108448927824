import { LogLevel } from '../app/common/enum/loglevel.enum';
import { Environment } from './environment.type';

export const environment: Environment = {
  production: false,
  apiUrl: 'https://buchen.dev.mywellness.de/api/customer-app/v1/',
  paymentApiUrl: 'https://buchen.dev.mywellness.de/api/',
  approvalApiUrl: 'https://buchen.dev.mywellness.de/api/customer-app/v1/',
  approvalPaymentApiUrl: 'https://buchen.dev.mywellness.de/api/',
  universalLoginCookieSecure: false,
  isDev: true,

  showNpsDialog: true,

  logLevel: LogLevel.all,
  tracking: {
    enabled: false,
  },
  approvalAccounts: ['st@simtec-system.de'],

  firebase: {
    apiKey: 'AIzaSyCZv8fTIekKFjTTkepb6bd6LLA0OkBXYUw',
    authDomain: 'myw-app-692ac.firebaseapp.com',
    databaseURL: 'https://myw-app-692ac.firebaseio.com',
    projectId: 'myw-app-692ac',
    storageBucket: 'myw-app-692ac.appspot.com',
    messagingSenderId: '341309132479',
    appId: '1:341309132479:web:151d337339705b25db800a',
    measurementId: 'G-F28R7HYSS3',
  },
};
