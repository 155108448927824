import { Component, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-cancellation-preconfirmation-modal',
  templateUrl: './cancellation-preconfirmation-modal.component.html',
  styleUrls: ['./cancellation-preconfirmation-modal.component.scss'],
})
export class CancellationPreconfirmationModalComponent {
  @Input() isLate: boolean = false;

  constructor(private modalCtrl: ModalController) {}

  public cancel() {
    this.modalCtrl.dismiss(undefined, 'cancel');
  }

  public confirm() {
    this.modalCtrl.dismiss(undefined, 'confirm');
  }
}
