import {
  ChangeDetectionStrategy,
  Component,
  Input,
  inject,
} from '@angular/core';
import { ModalController } from '@ionic/angular';
import { LoyaltyStatus } from '../../../shared/types/LoyaltyStatus';
import { LoyaltyRequestModalComponent } from '../loyalty-request-modal/loyalty-request-modal.component';
import { LoyaltyRequestResendComponent } from '../loyalty-request-resend/loyalty-request-resend.component';

@Component({
  selector: 'app-loyalty-status',
  templateUrl: './loyalty-status.component.html',
  styleUrls: ['./loyalty-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LoyaltyStatusComponent {
  @Input() loyaltyStatus: LoyaltyStatus = LoyaltyStatus.NotMember;
  LoyaltyStatus = LoyaltyStatus;
  @Input() loyaltyPoints: number = 0;
  @Input() loyaltyRank: string = 'Kein Rang';

  private modalCtrl = inject(ModalController);

  constructor() {}

  async openLoyaltyModal() {
    let modal: HTMLIonModalElement;
    if (this.loyaltyStatus === LoyaltyStatus.NotMember) {
      modal = await this.modalCtrl.create({
        backdropDismiss: true,
        component: LoyaltyRequestModalComponent,
        cssClass: 'myw-request-loyalty-modal custom-modal',
      });
    } else if (this.loyaltyStatus === LoyaltyStatus.Pending) {
      modal = await this.modalCtrl.create({
        backdropDismiss: true,
        component: LoyaltyRequestResendComponent,
        cssClass: 'myw-resend-loyalty-modal custom-modal',
      });
    }
    modal.present();
  }
}
