import { Component, EventEmitter, Input, Output, inject } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormBuilder, Validators } from '@angular/forms';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { Logger } from 'src/app/common/provider/logger.service';
import { PasswordResetComponent } from 'src/app/components/register/forgotpw/forgotpw.component';
import { Config } from 'src/app/config/config';
import { Customer } from 'src/app/core/api/model/customer.model';
import { CustomerService } from 'src/app/core/shared/customer.service';

const ERROR_MESSAGES = {
  INVALID_CREDENTIALS:
    'Leider stimmen E-Mail-Adresse und Passwort nicht überein.',
  CUSTOMER_INACTIVE:
    'Leider hast Du noch nicht auf den Bestätigungslink in unserer Aktivierungsmail geklickt. \n' +
    'Daher kannst Du dich leider nocht nicht anmelden.',
};

@Component({
  selector: 'app-login-form',
  templateUrl: './login-form.component.html',
  styleUrls: ['./login-form.component.scss'],
})
export class LoginFormComponent {
  @Input() onForgotPasswordPressed: () => void | Promise<void>;
  @Output() loginSuccess = new EventEmitter<Customer>();

  private fb = inject(FormBuilder);
  private analytics = inject(AngularFireAnalytics);
  private modalCtrl = inject(ModalController);
  private alertCtrl = inject(AlertController);
  private loadingCtrl = inject(LoadingController);
  private logger = inject(Logger);
  private customerService = inject(CustomerService);

  private log = this.logger.createLogger('LoginOverlay');
  private currentError: string;

  form = this.fb.group({
    email: ['', Validators.required],
    password: [
      '',
      Validators.compose([Validators.required, Validators.minLength(6)]),
    ],
  });

  constructor() {}

  async submitLogin() {
    this.log.debug('login');
    const loader = await this.loadingCtrl.create();
    await loader.present();

    const email = this.form.get('email').value;
    const password = this.form.get('password').value;

    try {
      const customer = await this.customerService.authenticate(email, password);
      this.analytics.logEvent('login_done');
      this.loginSuccess.emit(customer);
    } catch (error) {
      this.currentError = ERROR_MESSAGES[error] || Config.SERVER_UNREACHABLE;

      const alert = await this.alertCtrl.create({
        cssClass: 'myw-alert',
        header: 'Login fehler',
        message: this.currentError,
        buttons: ['OK'],
      });

      await alert.present();

      this.analytics.logEvent('login_error', error);
      this.log.error('login: error', error);
    } finally {
      await loader.dismiss();
    }
  }

  async forgotPw() {
    await this.onForgotPasswordPressed();

    const modal = await this.modalCtrl.create({
      cssClass: 'modal-forgot-pw auto-height',
      component: PasswordResetComponent,
    });
    modal.present();
  }
}
