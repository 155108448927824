import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginFormComponent } from './login-form.component';
import { ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';

@NgModule({
  declarations: [LoginFormComponent],
  imports: [CommonModule, IonicModule, ReactiveFormsModule],
  exports: [LoginFormComponent],
})
export class LoginFormModule {}
