import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-close-button',
  templateUrl: './close-button.component.html',
  styleUrls: ['./close-button.component.scss'],
})
export class CloseButtonComponent {

  @Input() top: string;
  @Input() right: string;

  constructor() {}
}
