import { Component, Input, OnInit, inject } from '@angular/core';
import { FormArray, FormGroup, FormGroupDirective } from '@angular/forms';
import { MaskitoElementPredicateAsync, MaskitoOptions } from '@maskito/core';
import {
  maskitoDateOptionsGenerator,
  maskitoWithPlaceholder,
} from '@maskito/kit';

const PLACEHOLDER = 'TT.MM.JJJJ';

const {
  plugins, // plugins keeps caret inside actual value and remove placeholder on blur
  ...placeholderOptions
  // pass 'true' as second argument to add plugin to hide placeholder when input is not focused
} = maskitoWithPlaceholder(PLACEHOLDER, true);

@Component({
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: ['./date-input.component.scss'],
})
export class DateInputComponent implements OnInit {
  @Input() isRequired = false;
  @Input() controlName: string;
  @Input() label: string;
  @Input() readonly = false;
  @Input() disabled = false;
  @Input() min: Date = new Date(1900, 0, 1);
  @Input() max: Date;
  @Input() groupName?: number;

  mask: MaskitoOptions;

  readonly maskPredicate: MaskitoElementPredicateAsync = async el =>
    (el as HTMLIonInputElement).getInputElement();

  form: FormGroup;

  private rootFormGroup = inject(FormGroupDirective);

  constructor() {}

  ngOnInit(): void {
    this.form = (
      this.groupName !== undefined
        ? (this.rootFormGroup.form.get('companions') as FormArray).at(
            this.groupName,
          )
        : this.rootFormGroup.form
    ) as FormGroup;

    this.configuerMaskito();
  }

  configuerMaskito() {
    const dateOptions = maskitoDateOptionsGenerator({
      mode: 'dd/mm/yyyy',
      separator: '.',
      min: this.min,
      max: this.max,
    });

    this.mask = {
      ...dateOptions,
      plugins: plugins.concat(dateOptions.plugins || []),
      preprocessors: [
        ...placeholderOptions.preprocessors,
        ...dateOptions.preprocessors,
      ],
      postprocessors: [
        ...dateOptions.postprocessors,
        ...placeholderOptions.postprocessors,
      ],
    };
  }
}
