import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage-angular';
import { RebookingData } from 'src/app/core/shared/rebooking.service';

@Injectable({
  providedIn: 'root',
})
export class PreferenceService {
  private _storage: Storage | null = null;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    const storage = await this.storage.create();
    this._storage = storage;

    storage.forEach((val, key) => {
      if (typeof val == 'object' && val.hasOwnProperty('expiresAt')) {
        const d = new Date();
        if (val.expiresAt < d) {
          storage.remove(key);
        }
      }
    });
  }

  set(key: string, value: any): Promise<void> {
    return this._storage.set(key, value);
  }

  get(key: string): Promise<any> {
    return this._storage.get(key);
  }

  remove(key: string): Promise<void> {
    return this._storage.remove(key);
  }

  getJwtToken(): Promise<string> {
    return this.storage.get('mw-jwt-token');
  }

  setJwtToken(jwtToken: string): Promise<void> {
    return this.storage.set('mw-jwt-token', jwtToken);
  }

  getBookingData(): Promise<object> {
    return this.storage.get('mw-booking-data');
  }

  setBookingData(bookingData: object): Promise<void> {
    return this.storage.set('mw-booking-data', bookingData);
  }

  getTestAccount(): Promise<boolean> {
    return this.storage.get('mw-is-test-account');
  }

  setTestAccount(flag: boolean): Promise<void> {
    return this.storage.set('mw-is-test-account', flag);
  }

  getLocations(): Promise<Array<object>> {
    return this.storage.get('mw-locations');
  }

  setLocations(locations: Array<object>): Promise<void> {
    return this.storage.set('mw-locations', locations);
  }

  getProgress(): Promise<boolean[]> {
    return this.storage.get('mw-booking-progress');
  }

  setProgress(progress: boolean[]): Promise<void> {
    return this.storage.set('mw-booking-progress', progress);
  }

  async getNoticeCookie(base64: string): Promise<boolean> {
    const d = new Date();
    const value = await this.storage.get(base64);
    if (!value) {
      return true;
    }
    return (await this.storage.get(base64)).expiresAt < d;
  }

  setNoticeCookie(base64: string): Promise<void> {
    const d = new Date();
    d.setTime(d.getTime() + 12 * 60 * 60 * 1000);
    return this.storage.set(base64, { expiresAt: d });
  }

  getReservationData(): Promise<{
    reservation_id: number;
    reserved_until: string;
    times_extended: number;
    max_times_extended: number;
    minutes_reserved: number;
    minutes_extended: number;
    outlet_id: number;
    suite_type_id: number;
    hasInitiatedPayment: boolean;
  }> {
    return this.storage.get('mw-reservation-data');
  }

  setReservationData(reservationData: object): Promise<void> {
    return this.storage.set('mw-reservation-data', reservationData);
  }

  deleteReservationData(): Promise<void> {
    return this.storage.remove('mw-reservation-data');
  }

  getReservationHasBeenShown(): Promise<boolean> {
    return this.storage.get('mw-reservation-shown');
  }

  setReservationHasBeenShown(shown: boolean): Promise<void> {
    return this.storage.set('mw-reservation-shown', shown);
  }

  getAdvertiserFeedbackHasBeenShown(): Promise<boolean> {
    return this.storage.get('mw-advertiser-feedback-shown');
  }

  setAdvertiserFeedbackHasBeenShown(shown: boolean): Promise<void> {
    return this.storage.set('mw-advertiser-feedback-shown', shown);
  }

  setLoyaltyPointsRewardedForLastBooking(value: number): Promise<void> {
    return this.storage.set(
      'myw-loyalty-points-rewarded-for-last-booking',
      value,
    );
  }

  getLoyaltyPointsRewardedForLastBooking(): Promise<number> {
    return this.storage.get('myw-loyalty-points-rewarded-for-last-booking');
  }

  setNpsDialogHasBeenShown(value: boolean): Promise<void> {
    return this.storage.set('myw-nps-dialog-has-been-shown', value);
  }

  getNpsDialogHasBeenShown(): Promise<boolean> {
    return this.storage.get('myw-nps-dialog-has-been-shown');
  }

  setRebookingData(value: RebookingData): Promise<void> {
    return this.storage.set('myw-rebooking-data', value);
  }

  getRebookingData(): Promise<RebookingData> {
    return this.storage.get('myw-rebooking-data');
  }

  clearRebookingData(): Promise<void> {
    return this.storage.remove('myw-rebooking-data');
  }
}
