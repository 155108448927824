import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AnimationOptions } from 'ngx-lottie';

@Component({
  selector: 'app-cancellation-confirmation-modal',
  templateUrl: './cancellation-confirmation-modal.component.html',
  styleUrls: ['./cancellation-confirmation-modal.component.scss'],
})
export class CancellationConfirmationModalComponent {
  @Input() incrementId: string;

  confirmationInput: string;

  lottieOptions: AnimationOptions = {
    path: '/assets/lotties/info.json',
  };

  constructor(private modalController: ModalController) {}

  cancel() {
    this.modalController.dismiss(undefined, 'cancel');
  }

  confirm() {
    this.modalController.dismiss(
      {
        userInput: this.confirmationInput,
      },
      'confirm',
    );
  }
}
