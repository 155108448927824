import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { FormValidators } from 'src/app/common/form-validators';

@Component({
  selector: 'app-change-password-form',
  templateUrl: './change-password-form.component.html',
  styleUrls: ['./change-password-form.component.scss'],
})
export class ChangePasswordFormComponent implements OnInit, OnChanges {
  @Input() username: string;
  @Input() isModifingPassword: boolean;

  @Output() passwordChanged = new EventEmitter<{
    old_password: string;
    new_password: string;
  }>();

  private fb = inject(FormBuilder);
  readonly passwordForm = this.fb.group({
    old_password: ['', [Validators.required, Validators.minLength(6)]],
    password: ['', [Validators.required, Validators.minLength(6)]],
    password_confirm: ['', [Validators.required, Validators.minLength(6)]],
  });

  constructor() {}

  ngOnInit(): void {
    const oldPassword = this.passwordForm.get('old_password');
    const password = this.passwordForm.get('password');
    const passwordConfirm = this.passwordForm.get('password_confirm');

    password.addValidators(
      Validators.compose([
        FormValidators.getEqualToValidatorFn(passwordConfirm),
        FormValidators.getNotEqualToValidatorFn(oldPassword),
      ]),
    );
    passwordConfirm.addValidators(
      Validators.compose([
        FormValidators.getEqualToValidatorFn(password),
        FormValidators.getNotEqualToValidatorFn(oldPassword),
      ]),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isModifingPassword && !changes.isModifingPassword.firstChange) {
      this.passwordForm.reset();
      this.passwordForm.markAsUntouched();
      this.passwordForm.markAsPristine();
      this.passwordForm.updateValueAndValidity();
    }
  }

  onSubmit(): void {
    if (this.passwordForm.invalid) return;

    const { old_password, password } = this.passwordForm.value;

    this.passwordChanged.emit({
      old_password,
      new_password: password,
    });
  }
}
