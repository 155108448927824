import { Component, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  templateUrl: './success-modal.component.html',
  styleUrls: ['./success-modal.component.scss'],
})
export class SuccessModalComponent {
  iconName: string = '';
  iconSrc: string = '';
  iconColor: string = 'black';
  message: string = '';
  buttonLabel: string = 'Ok';

  private modalCtrl = inject(ModalController);

  constructor() {}

  dismiss() {
    this.modalCtrl.dismiss(null, 'cancel');
  }
}
