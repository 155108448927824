import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { RedeemPointsModalComponent } from '../redeem-points-modal/redeem-points-modal.component';
import { LoyaltyInfoModalComponent } from '../loyalty-info-modal/loyalty-info-modal.component';

@Component({
  selector: 'app-redeem-points',
  templateUrl: './redeem-points.component.html',
  styleUrls: ['./redeem-points.component.scss'],
})
export class RedeemPointsComponent {
  @Input() pointValue: number = 0;
  @Input() totalPoints: number = 0;
  @Input() maxPoints: number = 0;
  @Input() redeemedPoints: number = 0;

  @Output() redeemedPointsChange = new EventEmitter<number>();

  constructor(private modalController: ModalController) {}

  async openModal() {
    const modal = await this.modalController.create({
      component: RedeemPointsModalComponent,
      cssClass: 'redeem-points-modal auto-height',
      componentProps: {
        pointValue: this.pointValue,
        totalPoints: this.totalPoints,
        maxPoints: this.maxPoints,
        redeemedPoints: this.redeemedPoints,
      },
    });

    await modal.present();

    modal.onWillDismiss().then(({ data, role }) => {
      if (role === 'submit') {
        this.redeemedPointsChange.emit(data.pointsToRedeem);
      }
    });
  }

  async openInfoModal() {
    const modal = await this.modalController.create({
      component: LoyaltyInfoModalComponent,
      cssClass: 'loyalty-info-modal auto-height',
      componentProps: {
        pointValue: this.pointValue,
      },
    });

    await modal.present();
  }
}
