import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { UserMenuPopoverComponent } from './components/user-menu-popover/user-menu-popover.component';
import { LoginPopoverComponent } from './components/login-popover/login-popover.component';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { LoginFormModule } from 'src/app/shared/components/login-form/login-form.module';

@NgModule({
  declarations: [UserMenuPopoverComponent, LoginPopoverComponent],
  imports: [
    CommonModule,
    IonicModule,
    RouterModule,
    ReactiveFormsModule,
    LoginFormModule,
  ],
  exports: [UserMenuPopoverComponent, LoginPopoverComponent],
})
export class UserMenuModule {}
