import { animate, style, transition, trigger } from '@angular/animations';
import { DatePipe } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  inject,
} from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { AlertController } from '@ionic/angular';
import { Config } from 'src/app/config/config';
import { Customer } from 'src/app/core/api/model/customer.model';

const salutationOptions = [
  { value: 'Herr', label: 'Herr' },
  { value: 'Frau', label: 'Frau' },
  { value: 'Divers', label: 'Keine Angabe' },
];

@Component({
  selector: 'app-my-data-form',
  templateUrl: './my-data-form.component.html',
  styleUrls: ['./my-data-form.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition('void => *', animate(0)),
      transition(':enter', [
        style({ opacity: 0 }),
        animate('0.2s 200ms ease-out', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        style({
          opacity: 1,
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          'z-index': 1,
        }),
        animate('0.2s ease-in', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class MyDataFormComponent implements OnInit, OnChanges {
  @Input() readonly = false;
  @Input() submitButtonText = 'Daten speichern';
  @Input() customer: Customer;
  @Input() submitablePristine = false;
  @Output() dataChanged = new EventEmitter<Partial<Customer>>();

  salutationOptions = salutationOptions;

  private fb = inject(FormBuilder);
  private date = inject(DatePipe);
  private alertCtrl = inject(AlertController);

  readonly maxDate = new Date();
  readonly minDate = new Date(1900, 0, 1);

  readonly form = this.fb.group({
    salutation: ['', [Validators.required]],
    firstname: ['', [Validators.required]],
    lastname: ['', [Validators.required]],
    date_of_birth: [
      '',
      [Validators.required, Validators.pattern(/^\d{2}\.\d{2}\.\d{4}$/)],
    ],
    email: ['', [Validators.required, Validators.pattern(Config.REGEX_EMAIL)]],
    telephone: ['', [Validators.required]],
    street: ['', [Validators.required]],
    house_number: ['', [Validators.required]],
    postcode: ['', [Validators.required]],
    city: ['', [Validators.required]],
  });

  constructor() {}

  ngOnInit(): void {
    this.fillForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.isInputView && !changes.isInputView.firstChange) {
      this.form.reset();
      this.fillForm();
    }
  }

  fillForm() {
    this.form.patchValue({
      salutation: this.customer.salutation,
      firstname: this.customer.firstname,
      lastname: this.customer.lastname,
      date_of_birth: this.date.transform(
        this.customer.date_of_birth,
        'dd.MM.yyyy',
      ),
      email: this.customer.email,
      telephone: this.customer.telephone,
      street: this.customer.street,
      house_number: this.customer.house_number,
      postcode: this.customer.postcode,
      city: this.customer.city,
    });
    this.form.markAsPristine();
    this.form.markAsUntouched();
    this.form.updateValueAndValidity();
  }

  onSubmit() {
    if (this.form.invalid) return;

    const formatedDate = this.form.value.date_of_birth
      .split('.')
      .reverse()
      .join('-');

    const userData: Partial<Customer> = {
      salutation: this.form.value.salutation,
      firstname: this.form.value.firstname,
      lastname: this.form.value.lastname,
      date_of_birth: formatedDate,
      email: this.form.value.email,
      telephone: this.form.value.telephone,
      street: this.form.value.street,
      house_number: this.form.value.house_number,
      postcode: this.form.value.postcode,
      city: this.form.value.city,
    };

    this.dataChanged.emit(userData);
  }

  async presentDateAlert() {
    if (this.customer.can_change_date_of_birth) {
      return;
    }

    const message =
      'Da Du bereits mit Deinen aktuellen Kundendaten eine Buchung bei uns getätigt hast, kannst Du Dein Geburtsdatum nachträglich nicht mehr selbstständig bearbeiten. Sollte Dein Geburtsdatum dennoch falsch sein, dann würden wir Dich bitten dich mit unserem Kundendienst in Verbindung zu setzen.';

    const alert = await this.alertCtrl.create({
      cssClass: 'myw-alert',
      header: 'Hinweis',
      message,
      buttons: ['OK'],
    });

    alert.present();
  }

  captureClick(ev) {
    ev.stopPropagation();
    ev.preventDefault();

    this.presentDateAlert();
  }
}
