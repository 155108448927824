import { Component, OnDestroy, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ActivatedRoute, Router } from '@angular/router';
import {
  AlertController,
  LoadingController,
  ModalController,
} from '@ionic/angular';
import { parseISO, sub } from 'date-fns';
import {
  BehaviorSubject,
  ReplaySubject,
  take,
  takeUntil,
  tap,
  timer,
} from 'rxjs';
import { CancellationConfirmationModalComponent } from 'src/app/components/cancellation-confirmation-modal/cancellation-confirmation-modal.component';
import { CancellationPreconfirmationModalComponent } from 'src/app/components/cancellation-preconfirmation-modal/cancellation-preconfirmation-modal.component';
import { CancellationService } from 'src/app/core/shared/cancellation.service';
import { CustomerService } from 'src/app/core/shared/customer.service';

@Component({
  selector: 'app-cancellation',
  templateUrl: './cancellation.component.html',
  styleUrls: ['./cancellation.component.scss'],
})
export class CancellationComponent implements OnInit, OnDestroy {
  private cancellationToken: string = '';
  isLate: boolean = false;
  pending: boolean = false;

  booking: any;

  private destroyed$: ReplaySubject<boolean> = new ReplaySubject(1);
  cutOff$ = new BehaviorSubject<{ isLate: boolean }>({ isLate: false });

  isInProfile = this.router.url.includes('user');

  customer$ = this.customerService.customer$;

  constructor(
    public route: ActivatedRoute,
    private router: Router,
    private analytics: AngularFireAnalytics,
    private modalCtrl: ModalController,
    private alertCtrl: AlertController,
    private loadingCtrl: LoadingController,
    private customerService: CustomerService,
    private cancellationService: CancellationService,
  ) {}

  ngOnInit() {
    this.analytics.logEvent('cancellation_open');

    this.cancellationToken = this.route.snapshot.queryParams.token;

    const data = this.route.snapshot.data.cancellationResolver.messageData;
    this.booking = {
      id: data.booking_id,
      incrementId: data.increment_id,
      date: data.booking_date,
      startTime: parseISO(data.booking_date_time),
      endTime: parseISO(data.booking_end_date_time),
      grandTotal: data.grand_total,
      status: data.status,
      cutOffDateTime:
        +data.flex_total > 0
          ? parseISO(`${data.booking_date}T${data.booking_time}`)
          : sub(parseISO(`${data.booking_date}T${data.booking_time}`), {
              hours: 48,
            }),
      isFlex: +data.flex_total > 0,
    };

    if (this.booking.cutOffDateTime < new Date()) {
      this.cutOff$.next({ isLate: true });
    } else if (this.isInNext24Hours(this.booking.cutOffDateTime)) {
      // only set the timer if the booking is in the next 24 hours
      // as otherwise the timer would potentially be negative due to an integer overflow
      timer(this.booking.cutOffDateTime)
        .pipe(
          takeUntil(this.destroyed$),
          take(1),
          tap(async () => {
            this.cutOff$.next({ isLate: true });

            if (await this.modalCtrl.getTop()) {
              this.modalCtrl.dismiss();
            }
            const alert = await this.alertCtrl.create({
              cssClass: 'myw-alert',
              header: 'Hinweis',
              message:
                'Leider ist die Stornierungsfrist für deine Buchung soeben abgelaufen.',
              buttons: ['OK'],
            });
            await alert.present();
          }),
        )
        .subscribe();
    }
  }

  ngOnDestroy() {
    this.destroyed$.next(true);
    this.destroyed$.complete();
  }

  async startCancellation(isLate: boolean = false) {
    const preconfirmationModal = await this.presentPreconfirmationModal(isLate);
    const preconfirmationModalResult =
      await preconfirmationModal.onDidDismiss();
    if (preconfirmationModalResult.role === 'confirm') {
      this.confirmCancellation();
    }
  }

  async confirmCancellation() {
    const confirmationModal = await this.presentConfirmationModal();
    const confirmationModalResult = await confirmationModal.onDidDismiss();
    if (confirmationModalResult.role === 'confirm') {
      this.cancelBooking(confirmationModalResult.data.userInput);
    }
  }

  async presentPreconfirmationModal(isLate: boolean = false) {
    const modal = await this.modalCtrl.create({
      cssClass: 'myw-modal--thin auto-height',
      component: CancellationPreconfirmationModalComponent,
      componentProps: { isLate },
    });

    await modal.present();

    return modal;
  }

  async presentConfirmationModal() {
    const modal = await this.modalCtrl.create({
      cssClass: 'myw-modal--thin auto-height',
      component: CancellationConfirmationModalComponent,
      componentProps: { incrementId: this.booking.incrementId },
    });

    await modal.present();
    return modal;
  }

  private async cancelBooking(userInput: string) {
    this.pending = true;
    const loading = await this.loadingCtrl.create();
    await loading.present();
    this.cancellationService
      .cancelBooking(this.cancellationToken, userInput)
      .subscribe({
        next: async () => {
          this.analytics.logEvent('cancellation_success');
          loading.dismiss();
          this.router.navigate(['success'], {
            queryParams: { il: this.cutOff$.value.isLate ? 1 : null },
            relativeTo: this.route.parent,
            replaceUrl: true,
          });
        },
        error: async err => {
          const alert = await this.alertCtrl.create({
            cssClass: 'myw-alert',
            header: 'Hinweis',
            message: 'Leider konnten wir deine Buchung nicht stornieren.',
            buttons: ['OK'],
          });
          loading.dismiss();
          this.pending = false;
          await alert.present();
        },
      });
  }

  isInNext24Hours(date: Date) {
    return (
      date.getTime() > new Date().getTime() &&
      date.getTime() < sub(new Date(), { hours: -24 }).getTime()
    );
  }
}
