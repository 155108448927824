// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `ion-list {
  display: flex;
  flex-direction: column;
  gap: 2px;
  background-color: #fff;
}

.forgot-pw {
  text-decoration: underline;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/shared/components/login-form/login-form.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,QAAA;EAEA,sBAAA;AAAF;;AAGA;EACE,0BAAA;EAEA,eAAA;AADF","sourcesContent":["ion-list {\n  display: flex;\n  flex-direction: column;\n  gap: 2px;\n\n  background-color: #fff;\n}\n\n.forgot-pw {\n  text-decoration: underline;\n\n  cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
