import { Component, Input, OnInit } from '@angular/core';
import { ModalController, Platform } from '@ionic/angular';

interface btnContent {
  text: string
  handler: () => void;
}

@Component({
  selector: 'app-alert-modal',
  templateUrl: './alert-modal.component.html',
  styleUrls: ['./alert-modal.component.scss']
})
export class AlertModalComponent {
  @Input() public iconSrc: string;
  @Input() public title: string;
  @Input() public message: string;
  @Input() public btnPrimary: btnContent;
  @Input() public btnSecondary: btnContent;

  constructor(
    public modalController: ModalController,
    private platform: Platform
  ) {

    this.platform.backButton.subscribeWithPriority(101, () => {
      this.dismiss();
    });
  }

  dismiss() {
    this.modalController.dismiss({
      dismissed: true
    });
  }
}
