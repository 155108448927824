import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AnimationOptions } from 'ngx-lottie';
import { take } from 'rxjs/operators';
import { CustomerService } from 'src/app/core/shared/customer.service';

@Component({
  selector: 'app-cancellation-success',
  templateUrl: './cancellation-success.component.html',
  styleUrls: ['./cancellation-success.component.scss']
})
export class CancellationSuccessComponent {
  public inProfile: boolean;
  public isLate: boolean = true;

  lottieOptions: AnimationOptions = {
    path: '/assets/lotties/approve.json'
  };

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private customerService: CustomerService
  ) {
    this.customerService.customer$.pipe(take(1)).subscribe((customer) => {
      if (customer) {
        this.inProfile = true;
      } else {
        this.inProfile = false;
      }
    });

    this.isLate = !!this.route.snapshot.queryParamMap.get('il');
  }

  navigateBooking() {
    this.router.navigate(['booking']);
  }
}
