import { HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";

@Injectable()
export class AppInfoInterceptor implements HttpInterceptor {
    
    intercept(req: HttpRequest<any>, next: HttpHandler) {
        if(req.url.includes(environment.apiUrl) || req.url.includes(environment.paymentApiUrl)) {
            
            const API_SOURCE = 'web';
            
            const appInfoReq = req.clone({
                setHeaders: {
                    'Api-Source': API_SOURCE
                }
            });
            
            return next.handle(appInfoReq);
        }
        
        return next.handle(req);
    }
}