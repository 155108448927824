import { Component, OnInit, inject } from '@angular/core';
import {
  AlertController,
  IonicSafeString,
  ModalController,
} from '@ionic/angular';
import { CustomerService } from 'src/app/core/shared/customer.service';

@Component({
  selector: 'app-loyalty-success-signup-modal',
  templateUrl: './loyalty-success-signup-modal.component.html',
  styleUrls: ['./loyalty-success-signup-modal.component.scss'],
})
export class LoyaltySuccessSignupModalComponent {
  private resentLoyaltyMail = false;
  private pending = false;

  private modalCtrl = inject(ModalController);
  private alertCtrl = inject(AlertController);
  private customerService = inject(CustomerService);

  constructor() {}

  dismiss() {
    this.modalCtrl.dismiss();
  }

  resendMail() {
    if (this.resentLoyaltyMail || this.pending) {
      return;
    }

    this.pending = true;
    this.customerService.resendLoyaltyMail().subscribe({
      next: async () => {
        this.resentLoyaltyMail = true;
        const alert = await this.alertCtrl.create({
          cssClass: 'myw-alert',
          header: 'Hinweis',
          message:
            'Wir haben Dir so eben einen neuen Link an Deine E-Mail-Adresse gesendet.',
          buttons: ['Alles klar!'],
        });

        this.dismiss();
        await alert.present();
      },
      error: async () => {
        this.pending = false;
        const alert = await this.alertCtrl.create({
          cssClass: 'myw-alert',
          header: 'Fehler',
          message:
            'Leider ist ein technischer Fehler aufgetreten. Versuch es bitte erneut oder melde Dich bei unserem Kundenservice.',
          buttons: [
            { text: 'Ok', role: 'destructive' },
            {
              text: 'Erneut versuchen',
              role: 'resend',
              handler: () => {
                this.resendMail();
                alert.dismiss();
              },
            },
          ],
        });
        await alert.present();
      },
    });
  }
}
