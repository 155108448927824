export class Utils {
  private static readonly ENTER_KEY_CODE: number = 13;

  public static exists(value: any): boolean {
    return !(value === null || value === undefined);
  }

  public static isEnterKey(event: KeyboardEvent): boolean {
    return event.keyCode === Utils.ENTER_KEY_CODE || event.key === 'Enter';
  }

  public static objectToQueryString(obj: object): string {
    const queryString = Object.keys(obj)
      .flatMap((key) => {
        const value = obj[key];
        if (Array.isArray(value)) {
          return value.map((v) => `${key}[]=${encodeURIComponent(v)}`);
        }
        return `${key}=${encodeURIComponent(value)}`;
      })
      .join('&');
    return queryString ? `?${queryString}` : '';
  }
}
