import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { PaginationInstance } from 'ngx-pagination';
import { CustomerService } from 'src/app/core/shared/customer.service';

type Transaction = {
  description: string;
  type: 'rewarded' | 'redeemed';
  points: string;
};

@Component({
  selector: 'app-point-history',
  templateUrl: './point-history.component.html',
  styleUrls: ['./point-history.component.scss'],
})
export class PointHistoryComponent implements OnInit {
  @ViewChild('p', { read: ElementRef, static: false }) p: ElementRef;
  @Input() currentPoints = 0;
  @Input() transactions: Transaction[] = [];
  lastPage: number = 1;

  loading: boolean = false;

  paginationConfig: PaginationInstance = {
    id: 'point-history',
    itemsPerPage: 10,
    currentPage: 1,
    totalItems: 10,
  };

  constructor(private customerService: CustomerService) {}

  ngOnInit() {
    this.getLoyaltyHistory(this.paginationConfig.currentPage);
  }

  pageChanged(event: any) {
    this.getLoyaltyHistory(event);
  }

  getLoyaltyHistory(page: number) {
    this.loading = true;
    this.customerService
      .getLoyaltyHistory(page)
      .subscribe(({ paging, transactions }) => {
        this.transactions = transactions;

        this.paginationConfig.currentPage = paging.currentPage;
        this.paginationConfig.totalItems = paging.total;

        this.lastPage = paging.lastPage;

        this.loading = false;
      });
  }
}
