import { Component, Input, inject } from '@angular/core';
import { ModalController } from '@ionic/angular';

import { LoyaltyEvent } from 'src/app/core/api/model/loyaltyEvent.model';
import SwiperCore, { Pagination, SwiperOptions } from 'swiper';
import { LoyaltyDealDetailsComponent } from '../loyalty-deal-details/loyalty-deal-details.component';

SwiperCore.use([Pagination]);

const BIRTHDAY: LoyaltyEvent = {
  id: '-1',
  name: 'Happy Birthday',
  iconUrl: '/assets/icons/glueckwunsch.svg',
  rewardText: 'DOPPELTE PUNKTE',
  text1:
    'Für Deinen besonderen Tag haben wir noch ein kleines Extra-Geschenk für Dich vorbereitet. Du bekommst:',
  text2:
    'Wenn Du noch heute Deinen neuen Aufenthalt buchst! Wir wünschen Dir noch einen entspannen Geburtstag. Deine MyWellness Crew.',
};

@Component({
  selector: 'app-loyalty-deals',
  templateUrl: './loyalty-deals.component.html',
  styleUrls: ['./loyalty-deals.component.scss'],
})
export class LoyaltyDealsComponent {
  @Input() loyaltyEvents: LoyaltyEvent[];

  swiperConfig: SwiperOptions = {
    spaceBetween: 8,
    slidesPerView: 'auto',
    centeredSlides: true,
    centerInsufficientSlides: true,

    pagination: {
      clickable: true,
      el: '.swiper-controls>.package-swiper-pagination',
    },
  };

  swiperRef: any;
  customerBirthday: Date;

  private modalCtrl = inject(ModalController);

  onSwiper(swiper) {
    this.swiperRef = swiper;
  }

  async openEventDetails(event: LoyaltyEvent) {
    const modal = await this.modalCtrl.create({
      cssClass: 'loyalty-deal-details-modal auto-height',
      component: LoyaltyDealDetailsComponent,
      componentProps: {
        event,
      },
    });
    await modal.present();
  }
}
