import { Adapter } from '../common/adapter.interface';
import { Injectable } from '@angular/core';

export class ExtensionInfo {
  constructor(
    public incrementId: string,
    public suiteType: string,
    public isExtendable: boolean,
    public isEditable: boolean,
    public customerName: string,
    public outlet: string,
    public bookingDate: string,
    public personsBooked: number,
    public startTime: string,
    public endTime: string,
    public grandTotal: number,
    public hoursExtendableBefore: number,
    public hoursExtendableAfter: number,
    public minutesExtendableBefore: number,
    public minutesExtedableAfter: number,
    public totalMinutesExtendableBefore: number,
    public totalMinutesExtendableAfter: number,
    public extensionUnitPrice: number,
    public hours_booked: number,
    public minutes_booked: number
  ) {}
}

@Injectable({
  providedIn: 'root'
})
export class ExtensionInfoAdapter implements Adapter<ExtensionInfo> {
  adapt(item: any): ExtensionInfo {
    return new ExtensionInfo(
      item.increment_id,
      item.suite_type,
      !!item.is_booking_extendable,
      !!item.is_editable,
      item.customer_firstname,
      item.outlet_name,
      item.booking_date,
      item.persons_booked,
      item.booking_time,
      item.booking_end_time,
      parseFloat(item.grand_total),
      item.hours_extendable_before_booking,
      item.hours_extendable_after_booking,
      item.minutes_extendable_before_booking,
      item.minutes_extendable_after_booking,
      item.total_minutes_extendable_before,
      item.total_minutes_extendable_after,
      parseFloat(item.booking_extension_unit_price),
      item.hours_booked,
      item.minutes_booked
    );
  }
}
