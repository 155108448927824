import { Injectable } from '@angular/core';
import { Adapter } from '../common/adapter.interface';

export class UpgradeOption {
  constructor(
    public id: number,
    public title: string,
    public options: Array<{ value: number; label: string; quantity: number }>
  ) {}
}

export class Upgrade {
  constructor(
    public upgradeId: number,
    public name: string,
    public description: string,
    public price: number,
    public upgradeOptions: Array<UpgradeOption>,
    public inclusions: Array<{ name: string }>,
    public image: string,
    public infoHeadline: string,
    public infoText: string,
    public infoImageUrl: string
  ) {}
}
@Injectable({
  providedIn: 'root'
})
export class UpgradeAdapter implements Adapter<Upgrade> {
  adapt(item: any): Upgrade {
    return new Upgrade(
      item.upgrade_id,
      item.name,
      item.description,
      +item.price,
      item.options.map((option) => {
        return new UpgradeOption(
          option.upgrade_option,
          option.name,
          option.inclusions.map((inclusion) => ({
            value: inclusion.entity_id,
            label: inclusion.name,
            quantity: inclusion.qty
          }))
        );
      }),
      item.inclusions,
      item.images,
      item.info_headline,
      item.info_description
        ? item.info_description.replace(/\\r\\n/, '<br><br>')
        : '',
      item.info_image
    );
  }
}
