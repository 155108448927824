import { Component, OnInit } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { filter } from 'rxjs/operators';
import { LINKS } from 'src/app/core/constants/links';
import { BookingProgressService } from 'src/app/core/shared/booking-progress.service';
import { BookingReservationService } from 'src/app/core/shared/booking-reservation.service';
import { BookingService } from 'src/app/core/shared/booking.service';
import { CustomerService } from 'src/app/core/shared/customer.service';
import { LoginPopoverComponent } from 'src/app/features/user-menu/components/login-popover/login-popover.component';
import { UserMenuPopoverComponent } from 'src/app/features/user-menu/components/user-menu-popover/user-menu-popover.component';

@Component({
  selector: 'app-booking-header',
  templateUrl: './booking-header.component.html',
  styleUrls: ['./booking-header.component.scss'],
})
export class BookingHeaderComponent implements OnInit {
  public currentStep = 1;
  public completedArray = [false, false, false, false];

  public isLoggedIn = false;
  public isBooking = false;
  public isStart = true;

  LINKS = LINKS;

  constructor(
    private router: Router,
    private modalController: ModalController,
    private progressService: BookingProgressService,
    private customerService: CustomerService,
    private reservationService: BookingReservationService,
    private bookingService: BookingService,
  ) {}

  ngOnInit() {
    this.progressService.currentProgress.subscribe(progress => {
      if (progress) {
        this.completedArray = progress;
      }
      this.setCurrentStep();
    });

    this.customerService.customer$.subscribe(customer => {
      this.isLoggedIn = customer ? true : false;
    });

    this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        const fragments = event.urlAfterRedirects.split('/')[1].split('?');
        this.isBooking = fragments[0] === 'booking';

        this.isStart =
          event.urlAfterRedirects === '/booking' ||
          event.urlAfterRedirects.includes('/user/') ||
          event.urlAfterRedirects.includes('/feedback/');
      });
  }

  goToStep(step: number) {
    if (!this.completedArray[step - 2] && step !== 1) {
      return;
    }
    // this.setCurrentStep(step);
    switch (step) {
      case 1:
        this.reservationService.resetReservation(false);
        this.router.navigate(['/booking']);
        break;
      case 2:
        this.reservationService.resetReservation(false);
        this.router.navigate(['/booking/location']);
        break;
      case 3:
        this.router.navigate(['/booking/package']);
        break;
      case 4:
        this.router.navigate(['/booking/overview']);
        break;
      default: {
        break;
      }
    }
  }

  setCurrentStep() {
    this.currentStep = this.completedArray.lastIndexOf(true) + 2;
  }

  completeStep(step: number) {
    this.completedArray[step - 1] = true;
    this.resetNextSteps(step);
  }

  resetSteps() {
    this.completedArray = [false, false, false, false];
  }

  resetNextSteps(step: number) {
    for (let i = step; i < 4; i++) {
      this.completedArray[i] = false;
    }
  }

  async showLogIn() {
    const modal = await this.modalController.create({
      showBackdrop: false,
      component: LoginPopoverComponent,
      cssClass: 'modal-login auto-height',
    });

    modal.present();
  }

  async showUserMenu() {
    const modal = await this.modalController.create({
      showBackdrop: false,
      component: UserMenuPopoverComponent,
      cssClass: 'modal-account auto-height',
    });

    modal.present();
  }
}
