import { Injectable, inject } from '@angular/core';
import { BookingController } from '../api/controller/booking.controller';

@Injectable({
  providedIn: 'root'
})
export class CancellationService {
  private bookingCtrl = inject(BookingController);

  validateCancellation(cancelToken: string) {
    return this.bookingCtrl.validateCancellation(cancelToken);
  }

  cancelBooking(cancelToken: string, userInput: string) {
    return this.bookingCtrl.cancelBooking(cancelToken, userInput);
  }
}
