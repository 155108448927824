import {
  Component,
  OnDestroy,
  OnInit,
  Pipe,
  PipeTransform
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { PreferenceService } from 'src/app/common/provider/preference.service';
import { BookingReservationService } from 'src/app/core/shared/booking-reservation.service';

@Component({
  selector: 'app-reservation-floater',
  templateUrl: './reservation-floater.component.html',
  styleUrls: ['./reservation-floater.component.scss']
})
export class ReservationFloaterComponent implements OnInit, OnDestroy {
  counter: number;
  countdownSubscription: Subscription;
  isClosed = false;

  constructor(private reservationService: BookingReservationService,
    private preferences: PreferenceService) {}

  async ngOnInit() {
    this.isClosed = await this.preferences.getReservationHasBeenShown();
    this.countdownSubscription = this.reservationService.countDown$.subscribe(
      (c) => {
        this.counter = c;
      }
    );

    setTimeout(() => {
      this.isClosed = true;
      this.preferences.setReservationHasBeenShown(true);
    }, 5000);
  }

  ngOnDestroy() {
    this.countdownSubscription.unsubscribe();
  }

  onClick() {
    this.isClosed = true;
    this.preferences.setReservationHasBeenShown(true);
  }
}

@Pipe({
  name: 'formatTime'
})
export class FormatTimePipe implements PipeTransform {
  transform(value: number): string {
    const minutes: number = Math.floor(value / 60);
    return (
      ('00' + minutes).slice(-2) +
      ':' +
      ('00' + Math.floor(value - minutes * 60)).slice(-2)
    );
  }
}
