import { HttpClient, HttpContext } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, lastValueFrom } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PreferenceService } from '../../../common/provider/preference.service';
import { Utils } from '../../../common/utils';
// import { HttpAuthClientService } from '../http-auth-client.service';
import {
  ExtensionInfo,
  ExtensionInfoAdapter,
} from '../model/extension-info.model';
import { ExtensionPaymentData } from '../model/extension-payment-data.model';
import {
  PaymentMethod,
  PaymentMethodAdapter,
} from '../model/payment-method.model';
import { Upgrade, UpgradeAdapter } from '../model/upgrade.model';
import { ADD_AUTHORIZATION_HEADER } from '../../interceptors/jwt.interceptor';
import { SuiteAvailabilityDaysRequest } from '../model/suite-availability-days-request';
import { SuiteAvailabilityDaysResponse } from '../model/suite-availability-days-response';
import { SuiteAvailabilityTimesRequest } from '../model/suite-availability-times-request';
import { SuiteAvailabilityTimesResponse } from '../model/suite-availability-times-response';
import { SuiteAvailabilityRequest } from '../model/suite-availability-request';
import { SuiteAvailabilityResponse } from '../model/suite-availability-response';
import { SpaPackage, SpaPackageAdapter } from '../model/spa-package.model';

@Injectable({
  providedIn: 'root',
})
export class BookingController {
  private baseUrl = environment.apiUrl;
  private paymentBaseUrl = environment.paymentApiUrl;

  constructor(
    private http: HttpClient,
    // private httpAuth: HttpAuthClientService,
    private preferences: PreferenceService,
    private paymentMethodAdapter: PaymentMethodAdapter,
    private extensionInfoAdapter: ExtensionInfoAdapter,
    private upgradeAdapter: UpgradeAdapter,
    private SpaPackageAdapter: SpaPackageAdapter,
  ) {
    this.preferences.getTestAccount().then(testAccount => {
      if (testAccount === true) {
        // eslint-disable-next-line no-console
        console.log('BookingController: IS TEST');
        this.useTestingEnv(true);
      } else {
        // eslint-disable-next-line no-console
        console.log('BookingController: IS NO TEST');
        this.useTestingEnv(false);
      }
    });
  }

  useTestingEnv(useTesting) {
    this.baseUrl = useTesting ? environment.approvalApiUrl : environment.apiUrl;
    this.paymentBaseUrl = useTesting
      ? environment.approvalPaymentApiUrl
      : environment.paymentApiUrl;
  }

  private queryUrl(path, params = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .get(`${this.baseUrl}/${path}${Utils.objectToQueryString(params)}`)
        .subscribe(resolve, reject);
    });
  }

  objectToQueryParams(obj) {
    return Object.keys(obj).reduce((acc, key) => {
      if (Array.isArray(obj[key])) {
        obj[key].forEach((value, index) => {
          acc[`${key}[${index}]`] = value;
        });
      } else {
        acc[key] = obj[key];
      }
      return acc;
    }, {});
  }

  public validateVouchers(voucher_codes: string[], config): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.baseUrl}/vouchers/validate`, {
          ...config,
          voucher_codes: voucher_codes,
        })
        .subscribe(resolve, reject);
    });
  }

  public validateVouchersWithBalance(
    voucher_code: string,
    balance: number,
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .put(`${this.paymentBaseUrl}/vouchers/${voucher_code}/validate`, {
          balance: balance,
        })
        .subscribe(resolve, reject);
    });
  }

  public checkPayment(payment_id): Promise<{ status: string }> {
    return new Promise((resolve, reject) => {
      this.http
        .post(
          `${this.baseUrl}/booking/checkPayment/${payment_id}`,
          {},
          {
            context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
          },
        )
        .subscribe(resolve, reject);
    });
  }

  public getPaypalPayment(bookingData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.paymentBaseUrl}/paypal-plus/payments`, bookingData)
        .subscribe(resolve, reject);
    });
  }

  public confirmBooking(bookingData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.paymentBaseUrl}/sales-bookings`, bookingData, {
          context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
        })
        .subscribe(resolve, reject);
    });
  }

  public getAvailabilityFilters(): Promise<object> {
    return this.queryUrl('suite-availability-filters', {
      with_suite_type: null,
    });
  }

  getSuiteAvailabilityDays(
    params: SuiteAvailabilityDaysRequest,
  ): Observable<SuiteAvailabilityDaysResponse> {
    const url = `${this.baseUrl}/suite-availability-days`;
    const options = {
      params: this.objectToQueryParams(params),
    };
    return this.http.get<SuiteAvailabilityDaysResponse>(url, options);
  }

  getSuiteAvailabilityTimes(
    params: SuiteAvailabilityTimesRequest,
  ): Observable<SuiteAvailabilityTimesResponse> {
    const url = `${this.baseUrl}/suite-availability-time-slots`;
    const options = {
      params: this.objectToQueryParams(params),
    };
    return this.http.get<SuiteAvailabilityTimesResponse>(url, options);
  }

  getOutletAvailability(params: SuiteAvailabilityRequest) {
    const url = `${this.baseUrl}/suite-availabilities`;
    const options = {
      params: this.objectToQueryParams(params),
    };

    return this.http.get<SuiteAvailabilityResponse>(url, options);
  }

  public getAvailabilityDays(params): Promise<{}> {
    return this.queryUrl('suite-availability-days', params);
  }

  public getAvailabilityTimes(
    params,
  ): Promise<{ availability_time_slot_legends; availability_time_slots }> {
    return this.queryUrl('suite-availability-time-slots', params);
  }

  public getSuiteAvailability(params): Promise<{
    availability_date: string;
    availabilities;
  }> {
    return this.queryUrl('sorted-suite-availabilities', params);
  }

  moveBooking(data: {
    booking_id: number;
    new_time: string;
    new_date: string;
  }) {
    const url = `${this.baseUrl}/moveSalesBooking`;
    const body = {
      booking_id: data.booking_id,
      new_time: data.new_time,
      new_date: data.new_date,
    };
    return this.http.post(url, body, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  public initiatePayment(bookingData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.baseUrl}/booking/initiatePayment`, bookingData, {
          context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
        })
        .subscribe(resolve, reject);
    });
  }

  public getPackages(): Promise<Array<SpaPackage>> {
    return lastValueFrom(
      this.http
        .get(`${this.baseUrl}/price-configuration-periods/current/packages`, {
          params: { 'with[]': 'empty_package' },
        })
        .pipe(
          map((items: Array<any>) =>
            items.map(item => this.SpaPackageAdapter.adapt(item)),
          ),
        ),
    );
  }

  public getUpgrades(date?: any): Promise<Array<Upgrade>> {
    const url = `${this.baseUrl}/price-configuration-periods/current/upgrades${
      date ? '?date=' + date : ''
    }`;
    return this.http
      .get(url)
      .pipe(
        map((items: Array<any>) =>
          items.map(item => this.upgradeAdapter.adapt(item)),
        ),
      )
      .toPromise();
  }

  public getFlex(): Promise<any> {
    return this.queryUrl('price-configuration-periods/current/flex');
  }

  public getPaymentTypes(bookingData): Promise<any> {
    return new Promise((resolve, reject) => {
      this.http
        .post(`${this.baseUrl}/booking/paymentMethods`, bookingData, {
          context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
        })
        .subscribe(resolve, reject);
    });
  }

  // Cancellation

  validateCancellation(cancelToken: string): Observable<any> {
    const url = `${this.baseUrl}/sales-bookings/${cancelToken}/validate`;
    return this.http.put(url, {});
  }

  cancelBooking(cancelToken: string, userInput: string): Observable<any> {
    const url = `${this.baseUrl}/sales-bookings/${cancelToken}/cancel`;
    return this.http.put(url, { increment_id: userInput });
  }

  // #region  Blocking

  blockSuite(data: object): Promise<any> {
    const url = `${this.baseUrl}/reservation`;
    return new Promise<any>((resolve, reject) => {
      this.http.post(url, data).subscribe(resolve, reject);
    });
  }

  releaseSuite(reservationId: number) {
    const url = `${this.baseUrl}/reservation/cancel/${reservationId}`;
    return new Promise<any>((resolve, reject) => {
      this.http.get(url).subscribe({ next: resolve, error: reject });
    });
  }

  checkSuiteBlocking(reservationId: number): Promise<any> {
    const url = `${this.baseUrl}/reservation/${reservationId}/check`;
    return new Promise<any>((resolve, reject) => {
      this.http.get(url).subscribe(resolve, reject);
    });
  }

  extendSuiteBlocking(reservationId: number): Promise<any> {
    const url = `${this.baseUrl}/reservation/${reservationId}/extend`;
    return new Promise<any>((resolve, reject) => {
      this.http.get(url).subscribe(resolve, reject);
    });
  }

  // #endregion Blocking

  getExtensionInfo(extensionToken: string): Promise<ExtensionInfo> {
    const url = `${this.baseUrl}/sales-bookings/${extensionToken}/extension-info`;
    return this.http
      .get(url)
      .pipe(map((data: any) => this.extensionInfoAdapter.adapt(data)))
      .toPromise();
  }

  getExtensionDiscount(data: any): Promise<any> {
    const url = `${this.baseUrl}/getExtensionDiscount`;
    return this.http.post(url, data).toPromise();
  }

  getExtensionPaymentMethods(
    extensionToken: string,
    data: any,
  ): Promise<PaymentMethod[]> {
    const url = `${this.baseUrl}/extension/${extensionToken}/paymentMethods`;
    return this.http
      .post(url, data)
      .pipe(
        map((data: any[]) =>
          data.map(item => this.paymentMethodAdapter.adapt(item)),
        ),
      )
      .toPromise();
  }

  initiateExtensionPayment(
    extensionToken: string,
    data: ExtensionPaymentData,
  ): Promise<any> {
    const url = `${this.baseUrl}/extension/${extensionToken}/initiatePayment`;
    return this.http.post(url, data).toPromise();
  }

  postAdvertisingFeedback(answer: string, booking_id: number): Observable<any> {
    const url = `${this.baseUrl}/advertising-feedback-question/answer`;
    const body = {
      answer,
      booking_id,
    };
    return this.http.post(url, body);
  }

  getLoyaltyPointReward(data): Observable<any> {
    const url = `${this.baseUrl}/loyalty/points/calculate`;
    return this.http.post(url, data, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  flagBookingAsBelated(bookingId: number): Observable<any> {
    const url = `${this.baseUrl}/flag-late-checkin`;
    const body = {
      booking_id: bookingId,
    };
    return this.http.post(url, body, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }
}
