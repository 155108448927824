import { Component, Input } from '@angular/core';
import { LINKS } from 'src/app/core/constants/links';

@Component({
  selector: 'app-quick-info',
  templateUrl: './quick-info.component.html',
  styleUrls: ['./quick-info.component.scss'],
})
export class QuickInfoComponent {
  @Input() pointValueInEuro: number = 0;

  LINKS = LINKS;

  constructor() {}
}
