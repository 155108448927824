import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  HostListener,
  Input,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

interface RadioGroupItem {
  value: any;
  label: string;
  disabled?: boolean;
}

@Component({
  selector: 'app-upgrade-radio-group',
  templateUrl: './upgrade-radio-group.component.html',
  styleUrls: ['./upgrade-radio-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: UpgradeRadioGroupComponent,
    },
  ],
})
export class UpgradeRadioGroupComponent implements ControlValueAccessor {
  @Input() isOpen: boolean = false;
  @Input() items: Array<RadioGroupItem> = [];
  @Input() title: string = '';
  @Input() value: any;
  @Input() openUpwards: boolean = false;

  displayTitle = '';

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: PointerEvent) {
    const nativeElement: any = this.elementRef.nativeElement;

    const clickedInside: boolean = nativeElement.contains(event.target);

    if (!clickedInside) {
      this.isOpen = false;
    }
  }

  touched = false;
  disabled = false;

  onChange = value => {};
  onTouched = () => {};

  constructor(private elementRef: ElementRef) {}

  valueChanged(value: any) {
    this.markAsTouched();
    if (!this.disabled) {
      this.value = value;
      this.displayTitle = this.items.find(item => item.value === value)?.label;
      this.isOpen = false;
      this.onChange(this.value);
    }
  }

  writeValue(value: any): void {
    this.value = value;
    this.displayTitle = this.items.find(item => {
      if (typeof item.value === 'string') {
        return item.value === value;
      }
      if (typeof item.value === 'object') {
        return JSON.stringify(item.value) === JSON.stringify(value);
      }
    })?.label;
  }

  registerOnChange(onChange: any): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any): void {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = this.touched;
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  toggleContent(event) {
    event.stopPropagation();
    this.isOpen = !this.isOpen;
  }
}
