import { Injectable } from '@angular/core';
import { Adapter } from '../../../../core/api/common/adapter.interface';

export interface PreconfigSliderOption {
  id: number;
  name: string;
  imageUrl: string;
  selected?: boolean;
}

interface Inclusions {
  package_id: number;
  name: string;
  qty: number;
}
export interface SpaPackage {
  package_id: number;
  name: string;
  price: number;
  image: string;
  inclusions: Array<Inclusions>;
}

export class PreconfigurationOptions {
  constructor(
    // public currentSelection: CurrentSelection,
    public isSteambath: boolean,
    public ambience: Array<PreconfigSliderOption>,
    public configurableUpgrades: Array<any>,
    public upgrades?: Array<any>,
    public saunaInfusions?: Array<PreconfigSliderOption>,
    public packages?: Array<SpaPackage>,
    public saunaTemperature?: number,
    public fillPool?: boolean,
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class PreconfigurationOptionsAdapter
  implements Adapter<PreconfigurationOptions>
{
  adapt(item: any): PreconfigurationOptions {
    return new PreconfigurationOptions(
      item.steam_bath,
      this.adaptAmbience(item.ambience),
      item.options.map(option => this.adaptConfigurableUpgrades(option)),
      undefined,
      this.adaptSaunaInfusions(item.sauna_infusion),
      item.packages?.data || [],
      item.sauna_temperature || 55,
      item.pool_filling_by_customer || false,
    );
  }

  adaptAmbience(ambience: any): PreconfigSliderOption[] {
    if (!ambience) {
      return null;
    }
    return ambience.map(ambience => ({
      id: ambience.tutorial_id,
      name: ambience.title,
      imageUrl: ambience.teaser_image,
      selected: ambience.selected,
    }));
  }

  adaptConfigurableUpgrades(upgrade) {
    return {
      upgrade_id: upgrade.upgrade_id,
      name: upgrade.name,
      info_image: upgrade.info_image,
      description: upgrade.description,
      options_header: upgrade.options_header + ':',
      upgrade_options: upgrade.options.map(o => ({
        upgrade_option: o.upgrade_option,
        name: o.name,
        options: o.inclusions.map((i, index) => ({
          value: i,
          label: i.name,
          qty: i.qty,
          selected: i.selected,
        })),
      })),
    };
  }

  adaptUpgrades(upgrade: any) {
    if (!upgrade) {
      return [];
    }

    return {
      upgradeId: upgrade.upgrade_id,
      name: upgrade.name,
      image: upgrade.image,
      upgradeOptions: upgrade.options.map(option => ({
        id: option.upgrade_option,
        title: option.name,
        options: option.inclusions.map((o, i) => ({
          value: o.entity_id,
          label: o.name,
          quantity: o.qty,
        })),
      })),
    };
  }

  adaptSaunaInfusions(sauna_infusion: any): PreconfigSliderOption[] {
    if (!sauna_infusion) {
      return null;
    }
    return sauna_infusion.map(infusion => ({
      id: infusion.product_id,
      name: infusion.name,
      imageUrl: infusion.teaser_image,
      selected: infusion.selected,
    }));
  }
}
