import { HttpClient, HttpContext, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from '../../../../environments/environment';
import { PreferenceService } from '../../../common/provider/preference.service';
import { ADD_AUTHORIZATION_HEADER } from '../../interceptors/jwt.interceptor';
import { Customer, CustomerAdapter } from '../model/customer.model';
import { ServerResponse, ServerResponseAdapter } from '../model/response.model';

@Injectable({
  providedIn: 'root',
})
export class CustomerController {
  private baseUrl = `${environment.apiUrl}/customers`;

  constructor(
    private http: HttpClient,
    private customerAdapter: CustomerAdapter,
    private serverAdapter: ServerResponseAdapter,
    private preferences: PreferenceService,
  ) {
    this.preferences.getTestAccount().then(testAccount => {
      if (testAccount === true) {
        // eslint-disable-next-line no-console
        console.log('CustomerController: IS TEST');
        this.useTestingEnv(true);
      } else {
        // eslint-disable-next-line no-console
        console.log('CustomerController: IS NO TEST');
        this.useTestingEnv(false);
      }
    });
  }

  useTestingEnv(useTesting) {
    this.baseUrl = `${
      useTesting ? environment.approvalApiUrl : environment.apiUrl
    }/customers`;
  }

  /**
   * check if customer with email address is already registered
   *
   * @returns {Observable<void>}
   */
  exists(email: string): Observable<any> {
    const url = `${this.baseUrl}/${email}/exists`;
    return this.http.head(url);
  }

  /**
   * used to generate a customer token using McWellness customer login credentials.
   * The API will return customer_token in response. All API endpoints require a customer token,
   * so each time you access an endpoint, your request must include one. Customer token is included
   * in the request header as below: X-Authorization: bearer customer_token
   *
   * @returns {Observable<string>} - jwt token
   */
  authenticate(
    email: string,
    password: string,
  ): Observable<{ token: string; customer: Customer }> {
    if (environment.approvalAccounts.includes(email)) {
      // eslint-disable-next-line no-console
      console.log('CustomerController.authenticate: IS TEST');
      this.useTestingEnv(true);
      this.preferences.setTestAccount(true);
    } else {
      // eslint-disable-next-line no-console
      console.log('CustomerController.authenticate: IS NO TEST');
      this.useTestingEnv(false);
      this.preferences.setTestAccount(false);
    }

    const url = `${this.baseUrl}/authenticate`;
    return this.http
      .post(url, {
        email,
        password,
      })
      .pipe(
        map((data: any) => ({
          token: data.customer_token,
          customer: this.customerAdapter.adapt(data.customer_info),
        })),
      );
  }

  /**
   * register a new McWellness customer
   *
   * @returns {Observable<Customer>}
   */
  register(
    customerData: any,
  ): Observable<{ token: string; customer: Customer }> {
    const url = `${this.baseUrl}`;
    return this.http.post(url, customerData).pipe(
      map((data: any) => ({
        token: data.customer_token,
        customer: this.customerAdapter.adapt(data.customer_info),
      })),
    );
  }

  /**
   * reset customer password
   *
   * @returns {Observable<ServerResponse>}
   */
  resetPassword(email: string): Observable<ServerResponse> {
    const url = `${this.baseUrl}/${email}/password-tokens/generate`;
    return this.http
      .post(url, null)
      .pipe(map((data: any) => this.serverAdapter.adapt(data)));
  }

  /**
   * query the customer data of the authenticated customer
   *
   * @returns {Observable<Customer>}
   */
  details(): Observable<Customer> {
    const url = `${this.baseUrl}/me`;
    return this.http
      .get(url, {
        context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
      })
      .pipe(map((data: any) => this.customerAdapter.adapt(data)));
  }

  /**
   * update customer profile / password
   *
   * @returns {Observable<Customer>}
   * @param data
   */
  update(data: any): Observable<Customer> {
    const url = `${this.baseUrl}/me`;
    return this.http
      .put(url, data, {
        context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
      })
      .pipe(map((item: any) => this.customerAdapter.adapt(item)));
  }

  /**
   * resends confirmation email
   *
   * @returns {Observable<ServerResponse>}
   * @param email
   */
  resentConfirmationMail(email: string): Observable<ServerResponse> {
    const url = `${this.baseUrl}/${email}/emails/resend`;
    return this.http
      .put(url, null)
      .pipe(map((data: any) => this.serverAdapter.adapt(data)));
  }

  /**
   * resends confirmation email
   *
   * @returns {Observable<ServerResponse>}
   * @param activationToken
   */
  activateAccount(activationToken: string): Observable<ServerResponse> {
    const url = `${this.baseUrl}/${activationToken}/activate`;
    return this.http
      .put(url, null)
      .pipe(map((data: any) => this.serverAdapter.adapt(data)));
  }

  /**
   * Fetches the customers bookings.
   *
   * @returns {Observable<any>}
   * @param page
   * @param page_size
   */
  getMyBookings(page: number, page_size: number): Observable<any> {
    const params =
      page && page_size ? `?page=${page}&page_size=${page_size}` : '';
    const url = `${this.baseUrl}/me/sales-bookings${params}`;
    return this.http.get(url, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  getBookingDetails(id: number | string): Observable<any> {
    const url = `${this.baseUrl}/me/sales-bookings/${id}`;
    return this.http.get(url, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  getMyVouchers(page: number, page_size: number): Observable<any> {
    const params =
      page && page_size
        ? `?page=${page}&page_size=${page_size}&with_balance=1`
        : '?with_balance=1';
    const url = `${this.baseUrl}/me/vouchers${params}`;
    return this.http.get(url, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  getAllVouchers(withBalance: boolean = true): Observable<any> {
    const params = withBalance ? '?with_balance=1' : '';
    const url = `${this.baseUrl}/me/get-all-vouchers-and-coupons${params}`;
    return this.http.get(url, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  refreshVouchers(): Observable<any> {
    const url = `${this.baseUrl}/me/vouchers/refresh`;
    return this.http.put(
      url,
      {},
      {
        context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
      },
    );
  }

  updateCustomer(data: Partial<Customer>): Observable<Customer> {
    const url = `${this.baseUrl}/me`;
    const body = data;
    return this.http
      .put(url, body, {
        context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
      })
      .pipe(map((data: any) => this.customerAdapter.adapt(data)));
  }

  updatePassword(
    data: {
      old_password: string;
      new_password: string;
    } & Partial<Customer>,
  ): Observable<Customer> {
    const url = `${this.baseUrl}/me`;
    const body = { ...data, change_password: true };
    return this.http
      .put(url, body, {
        context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
      })
      .pipe(map((data: any) => this.customerAdapter.adapt(data)));
  }

  updateNewsletterSubscription(data: {
    is_subscribed?: 0 | 1;
    is_loyalty_member?: 0 | 1;
  }): Observable<Customer> {
    const url = `${this.baseUrl}/me`;
    const body = data;
    return this.http
      .put(url, body, {
        context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
      })
      .pipe(map((data: any) => this.customerAdapter.adapt(data)));
  }

  addVoucher(code: string) {
    const url = `${this.baseUrl}/me/vouchers`;
    const body = {
      code: code,
    };
    return this.http.post(url, body, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  validatePasswordResetToken(email: string, token: string): Observable<any> {
    const url = `${this.baseUrl}/${email}/passwords/reset/validate`;
    const body = {
      token: token,
    };
    return this.http.put(url, body);
  }

  getBookingConfirmation(bookingId: number): Observable<any> {
    const url = `${this.baseUrl}/me/sales-bookings/${bookingId}/documents/confirmation/download`;

    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };

    return this.http.get(url, {
      headers: new HttpHeaders(headers),
      responseType: 'blob',
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  getBookingInvoice(bookingId: number): Observable<any> {
    const url = `${this.baseUrl}/me/sales-invoices/${bookingId}/download`;
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };

    return this.http.get(url, {
      headers: new HttpHeaders(headers),
      responseType: 'blob',
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  getOrderReceipt(bookingId: number): Observable<any> {
    const url = `${this.baseUrl}/me/sales-receipts/${bookingId}/download`;
    const headers: { [key: string]: string } = {
      'Content-Type': 'application/json',
    };

    return this.http.get(url, {
      headers: new HttpHeaders(headers),
      responseType: 'blob',
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  resetPasswordTo(
    email: string,
    token: string,
    newPassword: string,
    newPasswordConfirmation: string,
  ): Observable<any> {
    const url = `${this.baseUrl}/${email}/passwords/reset`;
    const body = {
      token: token,
      password: newPassword,
      password_confirmation: newPasswordConfirmation,
    };
    return this.http.put(url, body);
  }

  getBonusVouchers(): Observable<any> {
    const url = `${this.baseUrl}/me/bonus-points`;
    return this.http.get(url, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  deleteAccount() {
    const url = `${this.baseUrl}/me/account/close`;
    return this.http.post(
      url,
      {},
      {
        context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
      },
    );
  }

  getLoyaltyHistory(page: number = 1): Observable<any> {
    const url = `${this.baseUrl}/me/loyalty/transactions/${page}`;
    return this.http.get(url, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  getLoyaltyEvents(page: number = 1): Observable<any> {
    const url = `${environment.apiUrl}/loyalty/programs/${page}`;
    return this.http.get(url, {
      context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
    });
  }

  resendLoyaltyMail() {
    const url = `${this.baseUrl}/me/loyalty-confirmation/resend`;
    return this.http.post(
      url,
      {},
      {
        context: new HttpContext().set(ADD_AUTHORIZATION_HEADER, true),
      },
    );
  }

  confirmLoyaltySubscription(token: string) {
    const url = `${environment.apiUrl}/loyalty/confirmation/${token}`;
    return this.http.post(url, {});
  }
}
