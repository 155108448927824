import { AbstractControl, ValidatorFn } from '@angular/forms';

export class FormValidators {
  public static getEqualToValidatorFn(control2: AbstractControl): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const hasValue: boolean = control.value?.length > 0;

      if (hasValue) {
        const hasEqualValues: boolean = control.value === control2.value;

        const control2Errors: any = control2.errors || {};
        if (hasEqualValues) {
          delete control2Errors.equalTo;
        } else {
          control2Errors.equalTo = false;
        }

        // Setting an empty object as errors is not the same as setting null.
        Object.keys(control2Errors).length > 0
          ? control2.setErrors(control2Errors)
          : control2.setErrors(null);

        return hasEqualValues ? null : { equalTo: false };
      }

      return control.errors;
    };
  }

  public static getNotEqualToValidatorFn(
    control2: AbstractControl,
  ): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } => {
      const hasValue: boolean = control.value?.length > 0;

      if (hasValue) {
        const hasEqualValues: boolean = control.value === control2.value;

        const control2Errors: any = control2.errors || {};
        if (!hasEqualValues) {
          delete control2Errors.notEqualTo;
        } else {
          control2Errors.notEqualTo = false;
        }

        // Setting an empty object as errors is not the same as setting null.
        Object.keys(control2Errors).length > 0
          ? control2.setErrors(control2Errors)
          : control2.setErrors(null);

        return !hasEqualValues ? null : { notEqualTo: false };
      }

      return control.errors;
    };
  }
}
