// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ion-item ion-input {
  font-family: "Cera Pro Medium";
  font-size: 15px;
  font-weight: normal;
  letter-spacing: 0.1em;
}
:host ion-item ion-button {
  --background: transparent;
  --background-hover: transparent;
  font-family: "Cera Pro Regular";
  font-size: 12px;
  font-weight: normal;
  letter-spacing: 0;
  text-transform: capitalize;
  text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/app/components/password-input/password-input.component.scss"],"names":[],"mappings":"AAEI;EACE,8BAAA;EACA,eAAA;EACA,mBAAA;EACA,qBAAA;AADN;AAGI;EACE,yBAAA;EACA,+BAAA;EAEA,+BAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;EAEA,0BAAA;EACA,0BAAA;AAHN","sourcesContent":[":host {\n  ion-item {\n    ion-input {\n      font-family: 'Cera Pro Medium';\n      font-size: 15px;\n      font-weight: normal;\n      letter-spacing: 0.1em;\n    }\n    ion-button {\n      --background: transparent;\n      --background-hover: transparent;\n\n      font-family: 'Cera Pro Regular';\n      font-size: 12px;\n      font-weight: normal;\n      letter-spacing: 0;\n\n      text-transform: capitalize;\n      text-decoration: underline;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
