import { Injectable } from '@angular/core';
import { LogLevel } from '../enum/loglevel.enum';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class Logger {
  private static MODULE_MAX_LENGTH = 20;
  private _module = '';
  private logLevel: LogLevel;

  constructor() {
    this.initLogLevel();
  }

  public createLogger(module: string): Logger {
    const retVal = new Logger();
    retVal.module = module;
    return retVal;
  }

  public set module(module: string) {
    while (module.length < Logger.MODULE_MAX_LENGTH) {
      module += ' ';
    }
    this._module = module;
  }

  private initLogLevel() {
    this.logLevel = environment.logLevel;
    this.module = 'DEFAULT';
  }

  private addLogPrefix(prefix: string, msg: string): string {
    return `${this._module} | ${prefix} | ${msg}`;
  }

  public log(message: any) {
    if (this.logLevel >= LogLevel.info) {
      // eslint-disable-next-line no-console
      console.log(message);
    }
  }

  public debug(message, ...obj: any[]) {
    if (this.logLevel >= LogLevel.debug) {
      const msg = this.addLogPrefix('DEBUG ', message);
      this.logOnConsole(console.debug, msg, ...obj);
    }
  }

  public info(message: any, ...obj: any[]) {
    if (this.logLevel >= LogLevel.info) {
      const msg = this.addLogPrefix('INFO ', message);
      // eslint-disable-next-line no-console
      this.logOnConsole(console.info, msg, ...obj);
    }
  }

  public warn(message: any, ...obj: any[]) {
    if (this.logLevel >= LogLevel.warning) {
      const msg = this.addLogPrefix('WARN ', message);
      this.logOnConsole(console.warn, msg, ...obj);
    }
  }

  public error(message: any, ...obj: any[]) {
    if (this.logLevel >= LogLevel.error) {
      const msg = this.addLogPrefix('ERROR', message);
      this.logOnConsole(console.error, msg, ...obj);
    }
  }

  private logOnConsole(method, message, ...obj: any[]) {
    if (obj && obj.length) {
      method(message, ...obj);
    } else {
      method(message);
    }
  }
}
