import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: 'booking',
    loadChildren: () =>
      import('./pages/booking/booking.module').then(m => m.BookingModule),
  },
  {
    path: 'rebooking',
    loadChildren: () =>
      import('./pages/rebooking/rebooking.module').then(m => m.RebookingModule),
  },
  {
    path: 'user',
    loadChildren: () =>
      import('./pages/user/user.module').then(m => m.UserModule),
  },
  {
    path: 'cancellation',
    loadChildren: () =>
      import('./pages/cancellation/cancellation.module').then(
        m => m.CancellationPageModule,
      ),
  },
  {
    path: 'feedback',
    loadChildren: () =>
      import('./pages/feedback/feedback.module').then(
        m => m.FeedbackPageModule,
      ),
  },
  {
    path: 'bookings',
    loadChildren: () =>
      import('./pages/extension/extension.module').then(
        m => m.ExtensionPageModule,
      ),
  },
  {
    path: 'loyalty-confirmation/:token',
    loadChildren: () =>
      import('./pages/loyalty-confirmation/loyalty-confirmation.module').then(
        m => m.LoyaltyConfirmationModule,
      ),
  },
  {
    path: '**',
    redirectTo: 'booking',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      canceledNavigationResolution: 'computed',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
