import { Component, OnInit, ChangeDetectionStrategy, Input } from '@angular/core';

@Component({
  selector: 'app-expandable',
  templateUrl: './expandable.component.html',
  styleUrls: ['./expandable.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ExpandableComponent {
  @Input() title: string = '';
  @Input() isOpen: boolean = false;

  constructor() { }

  toggleContent() {
    this.isOpen = !this.isOpen;
  }
}
