import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { BookingReservationService } from 'src/app/core/shared/booking-reservation.service';

@Injectable({
  providedIn: 'root'
})
export class ReservationGuard  {
  constructor(
    private router: Router,
    private reservationService: BookingReservationService
  ) {}
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean | UrlTree> {
    const hasReservation = !!this.reservationService.reservationData;
    return hasReservation ? true : this.router.createUrlTree(['booking']);
  }
}
