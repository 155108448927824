import { DatePipe, registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import {
  APP_INITIALIZER,
  ErrorHandler,
  LOCALE_ID,
  NgModule,
} from '@angular/core';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy, provideRouter } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import player from 'lottie-web/build/player/lottie_svg';
import { CookieService } from 'ngx-cookie-service';
import { LottieModule } from 'ngx-lottie';
import { environment } from 'src/environments/environment';
import { SwiperModule } from 'swiper/angular';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginGuard, NoLoginGuard } from './common/guard/login.guard';
import { ReservationGuard } from './common/guard/reservation.guard';
import pageTransition from './common/pageTransition';
import { MyComponentsModule } from './components/mycomponents.module';
import { GlobalErrorHandler } from './core/ErrorHandler';
import { AppInfoInterceptor } from './core/api/appinfo-interceptor';
import { BookingService } from './core/shared/booking.service';
import { ConfigService } from './core/shared/config.service';
import { CustomerService } from './core/shared/customer.service';

import localeDe from '@angular/common/locales/de';
import localeDeExtra from '@angular/common/locales/extra/de';
import { MaskitoModule } from '@maskito/angular';
import { UserMenuModule } from './features/user-menu/user-menu.module';
import { JwtInterceptor } from './core/interceptors/jwt.interceptor';

registerLocaleData(localeDe, 'de-DE', localeDeExtra);

export function playerFactory() {
  return player;
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireAnalyticsModule,
    IonicModule.forRoot({
      mode: 'md',
      rippleEffect: false,
      backButtonIcon: 'assets/icons/arrow_back.svg',
      innerHTMLTemplatesEnabled: true,
      navAnimation: pageTransition,
    }),
    LottieModule.forRoot({ player: playerFactory }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    HttpClientModule,
    MyComponentsModule,
    SwiperModule,
    MaskitoModule,
    UserMenuModule,
  ],
  providers: [
    { provide: LOCALE_ID, useValue: 'de-DE' },
    NoLoginGuard,
    LoginGuard,
    ReservationGuard,
    DatePipe,
    CookieService,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: RouteReuseStrategy,
      useClass: IonicRouteStrategy,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (
        configService: ConfigService,
        bookingService: BookingService,
        customerService: CustomerService,
      ) => {
        return () =>
          Promise.all([
            configService.init(),
            bookingService.init(),
            customerService.init(),
          ]);
      },
      multi: true,
      deps: [ConfigService, BookingService, CustomerService],
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInfoInterceptor,
      multi: true,
    },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
