// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  position: relative;
  padding: 64px 48px 48px;
  background-color: #fff;
  color: #000;
}
.container .modal__close-icon {
  position: absolute;
  top: 35px;
  right: 35px;
  font-size: 35px;
  cursor: pointer;
}
.container .content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}
.container .content ion-icon {
  font-size: 35px;
  margin-bottom: 24px;
}
.container .content h1 {
  font-size: 22px;
  font-weight: bold;
  line-height: 30px;
  letter-spacing: 0.2em;
  margin-bottom: 28px;
}
.container .content p {
  font-size: 17px;
  line-height: 1.5em;
  letter-spacing: 0.02em;
}
.container .content ion-button {
  align-self: stretch;
}`, "",{"version":3,"sources":["webpack://./src/app/components/loyalty/loyalty-info-modal/loyalty-info-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAEA,uBAAA;EAEA,sBAAA;EACA,WAAA;AADF;AAGE;EACE,kBAAA;EACA,SAAA;EACA,WAAA;EAEA,eAAA;EAEA,eAAA;AAHJ;AAME;EACE,aAAA;EACA,sBAAA;EACA,mBAAA;EAEA,kBAAA;AALJ;AAOI;EACE,eAAA;EACA,mBAAA;AALN;AAQI;EACE,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,qBAAA;EACA,mBAAA;AANN;AASI;EACE,eAAA;EACA,kBAAA;EACA,sBAAA;AAPN;AAUI;EACE,mBAAA;AARN","sourcesContent":[".container {\n  position: relative;\n\n  padding: 64px 48px 48px;\n\n  background-color: #fff;\n  color: #000;\n\n  .modal__close-icon {\n    position: absolute;\n    top: 35px;\n    right: 35px;\n\n    font-size: 35px;\n\n    cursor: pointer;\n  }\n\n  .content {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n\n    text-align: center;\n\n    ion-icon {\n      font-size: 35px;\n      margin-bottom: 24px;\n    }\n\n    h1 {\n      font-size: 22px;\n      font-weight: bold;\n      line-height: 30px;\n      letter-spacing: 0.2em;\n      margin-bottom: 28px;\n    }\n\n    p {\n      font-size: 17px;\n      line-height: 1.5em;\n      letter-spacing: 0.02em;\n    }\n\n    ion-button {\n      align-self: stretch;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
