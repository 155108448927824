import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { LINKS } from 'src/app/core/constants/links';

@Component({
  selector: 'app-no-friend-overview',
  templateUrl: './no-friend-overview.component.html',
  styleUrls: ['./no-friend-overview.component.scss'],
})
export class NoFriendOverviewComponent {
  @Input() wantsToBecomeLoyaltyMember: boolean;
  @Output() toggledWantsToBecomeLoyaltyMember = new EventEmitter<boolean>();

  LINKS = LINKS;

  constructor() {}

  toggleWantsToBecomeLoyaltyMember(checked: boolean) {
    this.toggledWantsToBecomeLoyaltyMember.emit(checked);
  }
}
