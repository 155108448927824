import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { Adapter } from '../common/adapter.interface';
import { LoyaltyStatus } from 'src/app/shared/types/LoyaltyStatus';

export interface UpcomingBooking {
  bookingId: number;
  bookingDate: string;
  bookingTime: string;
  isLateCheckin?: boolean;
}

export class Customer {
  constructor(
    public increment_id: string,
    public salutation: string,
    public firstname: string,
    public lastname: string,
    public email: string,
    public printed_rfid_tag_number: string,
    public street: string,
    public house_number: string,
    public postcode: string,
    public city: string,
    public telephone: string,
    public date_of_birth: string,
    public can_change_date_of_birth: boolean,
    public is_subscribed: boolean | number,
    public is_loyalty_member: boolean | number,
    public preferred_payment_method: string,
    public can_use_voucher_for_first_booking: boolean,
    public voucher_for_first_booking: string,
    public bonus_points: number,
    public upcomingBooking?: UpcomingBooking,
    public loyalty_group?: string,
    public loyalty_member_requested?: boolean | number,
    public loyaltyStatus?: LoyaltyStatus,
    public loyalty_points?: number,
  ) {}
}

export type UpdatedCustomer = Customer & {
  change_password?: boolean;
  old_password?: string;
  new_password?: string;
};

@Injectable({
  providedIn: 'root',
})
export class CustomerAdapter implements Adapter<Customer> {
  constructor(private datePipe: DatePipe) {}

  adapt(item: any): Customer {
    let loyaltyStatus: LoyaltyStatus;
    if (item.is_loyalty_member) {
      loyaltyStatus = LoyaltyStatus.Member;
    } else if (item.loyalty_member_requested) {
      loyaltyStatus = LoyaltyStatus.Pending;
    } else {
      loyaltyStatus = LoyaltyStatus.NotMember;
    }

    return new Customer(
      item.increment_id || '',
      item.salutation || 'Herr',
      item.firstname || '',
      item.lastname || '',
      item.email || '',
      item.printed_rfid_tag_number || '',
      item.street || '',
      item.house_number || '',
      item.postcode || '',
      item.city || '',
      item.telephone || '',
      this.datePipe.transform(Date.parse(item.date_of_birth), 'yyyy-MM-dd') ||
        null,
      item.can_change_date_of_birth || false,
      item.is_subscribed || false,
      item.is_loyalty_member || false,
      item.preferred_payment_method || '',
      item.can_use_voucher_for_first_booking || false,
      item.voucher_for_first_booking || '',
      item['bonus-rating']?.bonus_points_rating || 0,
      this.adaptNextBooking(item.nextSalesBooking),
      item.loyalty_group || 'Kein Rang',
      item.loyalty_member_requested || false,
      loyaltyStatus,
      item.loyalty_points !== null && item.loyalty_points !== undefined
        ? +item.loyalty_points
        : null,
    );
  }

  adaptNextBooking(nextSalesBooking): UpcomingBooking {
    if (!nextSalesBooking) {
      return null;
    }

    return {
      bookingId: nextSalesBooking?.booking_id,
      bookingDate: nextSalesBooking?.booking_date,
      bookingTime: nextSalesBooking?.booking_time,
      isLateCheckin: !!nextSalesBooking?.is_late_checkin,
    };
  }
}
