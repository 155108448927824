import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { IonRadioGroup } from '@ionic/angular';
import { PaymentMethod } from 'src/app/core/api/model/payment-method.model';

@Component({
  selector: 'app-payment-method-selector',
  templateUrl: './payment-method-selector.component.html',
  styleUrls: ['./payment-method-selector.component.scss'],
})
export class PaymentMethodSelectorComponent implements OnInit {
  @Input({ required: true }) paymentMethods: PaymentMethod[] = [];
  @Output() paymentMethodSelected = new EventEmitter<string>();
  @ViewChild('radioGroup', { static: false }) radioGroup: IonRadioGroup;

  openContent: boolean = false;
  defaultMethod: PaymentMethod;
  selectedMethod: PaymentMethod;

  isOpen: boolean = false;

  constructor() {}

  ngOnInit(): void {
    this.selectedMethod = this.paymentMethods.find(m => m.isDefault);
    this.setDefaultMethod();
  }

  toggleOpen() {
    this.isOpen = !this.isOpen;
  }

  setDefaultMethod() {
    this.defaultMethod = this.paymentMethods.find(m => m.isDefault);
  }

  setPaymentMethod(event) {
    this.selectedMethod = event.detail.value;
    this.paymentMethodSelected.emit(this.selectedMethod.type);
  }

  toggleMethodList() {
    this.openContent = !this.openContent;
  }

  onItemClick(event: PointerEvent, paymentMethod: PaymentMethod) {
    if (
      event.target instanceof HTMLElement &&
      event.target.classList.contains('radio-icon')
    ) {
      this.selectedMethod = paymentMethod;
      this.paymentMethodSelected.emit(this.selectedMethod.type);
    }
  }
}
