// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  display: block;
}

.event-card {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  padding: 20px 10px 10px;
  width: 100%;
  height: 100%;
  background-color: #f6f4f3;
}
.event-card__icon {
  display: grid;
  place-items: center;
  margin-inline: auto;
  border-radius: 50%;
  height: 60px;
  width: 60px;
  background-color: #fff;
}
.event-card__icon img {
  height: 50px;
  width: 50px;
}
.event-card__title {
  margin-bottom: auto;
  max-width: 100%;
  font-family: "Cera Pro Medium";
  font-size: 15px;
  font-weight: bold;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
}
.event-card__button {
  --background: var(--myw-color-boho-2);
  --color: #000;
  align-self: stretch;
  height: 35px;
}
.event-card__button__text {
  font-family: "Cera Pro Medium";
  font-size: 12px;
  letter-spacing: 0.2em;
}
.event-card__button__icon {
  font-size: 14px;
}

.swiper-controls {
  display: flex !important;
  justify-content: center;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/loyalty/loyalty-deals/loyalty-deals.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;AACF;;AAEA;EACE,kBAAA;EAEA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,SAAA;EAEA,uBAAA;EACA,WAAA;EACA,YAAA;EAEA,yBAAA;AAFF;AAIE;EACE,aAAA;EACA,mBAAA;EAEA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,WAAA;EACA,sBAAA;AAHJ;AAKI;EACE,YAAA;EACA,WAAA;AAHN;AAOE;EACE,mBAAA;EACA,eAAA;EAEA,8BAAA;EACA,eAAA;EACA,iBAAA;EACA,qBAAA;EAEA,kBAAA;EACA,yBAAA;AAPJ;AAUE;EACE,qCAAA;EACA,aAAA;EAEA,mBAAA;EACA,YAAA;AATJ;AAWI;EACE,8BAAA;EACA,eAAA;EACA,qBAAA;AATN;AAYI;EACE,eAAA;AAVN;;AAeA;EACE,wBAAA;EACA,uBAAA;EACA,mBAAA;AAZF","sourcesContent":[":host {\n  display: block;\n}\n\n.event-card {\n  position: relative;\n\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  gap: 16px;\n\n  padding: 20px 10px 10px;\n  width: 100%;\n  height: 100%;\n\n  background-color: #f6f4f3;\n\n  &__icon {\n    display: grid;\n    place-items: center;\n\n    margin-inline: auto;\n    border-radius: 50%;\n    height: 60px;\n    width: 60px;\n    background-color: #fff;\n\n    img {\n      height: 50px;\n      width: 50px;\n    }\n  }\n\n  &__title {\n    margin-bottom: auto;\n    max-width: 100%;\n\n    font-family: 'Cera Pro Medium';\n    font-size: 15px;\n    font-weight: bold;\n    letter-spacing: 0.2em;\n\n    text-align: center;\n    text-transform: uppercase;\n  }\n\n  &__button {\n    --background: var(--myw-color-boho-2);\n    --color: #000;\n\n    align-self: stretch;\n    height: 35px;\n\n    &__text {\n      font-family: 'Cera Pro Medium';\n      font-size: 12px;\n      letter-spacing: 0.2em;\n    }\n\n    &__icon {\n      font-size: 14px;\n    }\n  }\n}\n\n.swiper-controls {\n  display: flex !important;\n  justify-content: center;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
