import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';
import { Logger } from '../../common/provider/logger.service';
import { ConfigController } from '../api/controller/config.controller';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private configState = new BehaviorSubject<{ [key: string]: any }>(null);
  public config$ = this.configState.asObservable();
  private log: Logger;

  constructor(
    private logger: Logger,
    private configController: ConfigController
  ) {
    this.log = this.logger.createLogger('ConfigService');
  }

  public async init(): Promise<void> {
    this.log.debug('init');
    await this.update();
  }

  public async update(): Promise<void> {
    this.log.debug('getConfig');

    return new Promise<void>((resolve, reject) => {
      this.configController
        .getConfig()
        .pipe(take(1))
        .subscribe(
          async (config) => {
            this.log.debug('getConfig: response', config);
            this.mergeConfig(config);
            resolve();
          },
          (error) => {
            this.log.error('getConfig: error', error);
            reject(error);
          }
        );
    });
  }

  public mergeConfig(config) {
    this.configState.next({ ...(this.configState.value || {}), ...config });
  }

  public destroy() {
    this.log.debug('destroy');
    this.configState.next(null);
  }
}
